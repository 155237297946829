import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { getAllParams } from '@frontend/sports/common/base-utils';
import { toInteger } from 'lodash-es';

import { AdaptiveLayoutState } from '../../layout/adaptive-layout.service';
import { SubNavigationResolve } from '../../sub-navigation/sub-navigation-resolve.service';
import { SportSubNavigationService } from './sport-sub-navigation.service';

@Injectable({ providedIn: 'root' })
export class SportSubNavigationResolve extends SubNavigationResolve<void> {
    constructor(private sportSubNavService: SportSubNavigationService) {
        super();
    }

    buildSubNavigation(layout: AdaptiveLayoutState, routeSnapshot: ActivatedRouteSnapshot): void {
        const sport = toInteger(getAllParams(routeSnapshot).sport);
        this.sportSubNavService.resolveTabs(layout, routeSnapshot, sport).subscribe((items) => this.tabs$.next(items));
    }
}
