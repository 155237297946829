import { Injectable } from '@angular/core';

import { NgrxReportsConfiguration } from '@frontend/sports/common/client-config-data-access';
import { Actions, createEffect } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { filter, tap } from 'rxjs/operators';

import { NgrxReportsService } from '../services/ngrx-reports.service';

@Injectable()
export default class NgrxReportsEffect {
    constructor(
        private actions: Actions,
        private userActions: NgrxReportsService<Action>,
        private ngrxReportsConfig: NgrxReportsConfiguration,
    ) {}

    handleSaveStateEffect$ = createEffect(
        () => {
            return this.actions.pipe(
                filter((action) => {
                    return (
                        this.ngrxReportsConfig.isReportsEnabled &&
                        !this.ngrxReportsConfig.restrictedActions.some((restrictedAction) => action.type.startsWith(restrictedAction))
                    );
                }),
                tap((action) => {
                    if (action !== null) {
                        this.userActions.addAction(action);
                    }
                }),
            );
        },
        { dispatch: false },
    );
}
