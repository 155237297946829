import { Injectable, Injector, Type } from '@angular/core';
import { ActivatedRouteSnapshot, ActivationEnd } from '@angular/router';

import { LayoutNavigationConfig } from '@frontend/sports/common/client-config-data-access';
import { OnAppInit } from '@frontend/vanilla/core';
import { Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';

import { AdaptiveLayoutService, AdaptiveLayoutState } from '../layout/adaptive-layout.service';
import { LoggerFactory } from '../logging/logger-factory.service';
import { SportsRemoteLogger } from '../logging/sports-remote-logger.service';
import { TopNavigationVersion } from '../navigation-core/navigation-core.models';
import { RouterEventsService } from '../navigation-core/router-events.service';
import { getToken } from '../router/router.exports';
import { SubNavigationResolve } from './sub-navigation-resolve.service';
import { SubNavigationService } from './sub-navigation.service';

@Injectable({ providedIn: 'root' })
export class SubNavigationBootstrapService implements OnAppInit {
    private validSubscription$ = new Subject<void>();
    private resolver?: SubNavigationResolve<any>;
    private lastResolverType?: any;
    private layoutState?: AdaptiveLayoutState;
    private lastActivationEnd?: ActivationEnd;
    private readonly logger: SportsRemoteLogger;

    constructor(
        private injector: Injector,
        private routerEvents: RouterEventsService,
        loggerFactory: LoggerFactory,
        private adaptiveLayout: AdaptiveLayoutService,
        private subNavigation: SubNavigationService,
        private layoutNavConfig: LayoutNavigationConfig,
    ) {
        this.logger = loggerFactory.getLogger('SubNavigationBootstrapService');
    }

    onAppInit(): void {
        this.adaptiveLayout.stateChange$.subscribe((state) => {
            this.layoutState = state;
            this.activationEnded(this.lastActivationEnd);
        });

        this.routerEvents.currentActivationEnd.subscribe((event) => this.activationEnded(event));
    }

    private activationEnded(event: ActivationEnd | undefined): void {
        if (!event) {
            return;
        }

        this.lastActivationEnd = event;

        const resolverType = this.findFirstSubnavigationResolver(event.snapshot);
        if (!resolverType || !this.shouldShowSubNav(event.snapshot)) {
            this.renewSubscription();
            this.lastResolverType = null;
            this.subNavigation.setTabs([]);

            if (this.resolver) {
                this.resolver.cleanup();
            }

            return;
        }

        if (resolverType !== this.lastResolverType) {
            this.renewSubscription();
            this.resolver?.cleanup();

            this.resolver = getToken<SubNavigationResolve<any>>(resolverType, event.snapshot, this.injector);

            if (!this.resolver) {
                this.logger.error(`Could not resolve Subnavigation resolver: ${resolverType} - route: ${event}`);

                return;
            }

            this.resolver.subNavigation$.pipe(skip(1), takeUntil(this.validSubscription$)).subscribe((items) => this.subNavigation.setTabs(items));
            this.lastResolverType = resolverType;
        }

        const model = event.snapshot.data.model;

        if (this.resolver) {
            this.resolver.buildSubNavigation(this.layoutState || this.adaptiveLayout.current, event.snapshot, model);
        }
    }

    private renewSubscription(): void {
        this.validSubscription$.next();
        this.validSubscription$.complete();
        this.validSubscription$ = new Subject<void>();
    }

    private findFirstSubnavigationResolver(snapshot: ActivatedRouteSnapshot): Type<SubNavigationResolve<any>> {
        let crtSnapshot: ActivatedRouteSnapshot | null = snapshot;

        while (crtSnapshot && (!crtSnapshot.data || !crtSnapshot.data.subNavigation)) {
            crtSnapshot = crtSnapshot.parent;
        }

        return crtSnapshot && crtSnapshot.data.subNavigation;
    }

    private shouldShowSubNav(snapshot: ActivatedRouteSnapshot): boolean {
        const crtSnapshot: ActivatedRouteSnapshot | null = snapshot;

        return this.layoutNavConfig.topNavigationVersion !== TopNavigationVersion.V3 || crtSnapshot.data?.shouldKeepSubNav;
    }
}
