import { Injectable } from '@angular/core';

import { OddsAcceptanceMode } from '@bpos';
import { Nullable } from '@frontend/sports/common/base-utils';
import { OddsConfig } from '@frontend/sports/common/client-config-data-access';
import { OnAppInit } from '@frontend/vanilla/core';
import { Store } from '@ngrx/store';
import { IBetslipDigitalRootState } from 'packages/sports/common/betslip/digital/state';
import SettingsActions from 'packages/sports/common/betslip/modules/settings/actions';

import { OddsAcceptance } from '../betslip-base/models/odds-acceptance.enum';
import { ModalRef } from '../modal/common/modal-ref';
import { ModalDialogService } from '../modal/dialog/modal-dialog.service';
import { UserService } from '../user/services/user.service';
import { ModalPopUpComponent } from './modal-pop-up-info.component';

@Injectable({
    providedIn: 'root',
})
export class OddAcceptanceModeFilterService implements OnAppInit {
    private modalDialogRef: Nullable<ModalRef>;
    selectedOddAcceptance: OddsAcceptanceMode = OddsAcceptanceMode.Higher;

    constructor(
        private modalDialogService: ModalDialogService,
        private userService: UserService,
        private oddsConfig: OddsConfig,
        private store: Store<IBetslipDigitalRootState>,
    ) {}

    onAppInit(): void {
        this.userService.onUserLogin$.subscribe(() => {
            if (
                this.userService.bettingSettings.oddsAcceptanceMode === OddsAcceptance.No &&
                this.oddsConfig.showOddsAcceptanceSettings &&
                this.oddsConfig.hideDontAcceptOptionSetting
            ) {
                this.store.dispatch(SettingsActions.requestSetOddAcceptance({ acceptance: this.selectedOddAcceptance }));

                if (!this.modalDialogRef) {
                    this.modalDialogRef = this.modalDialogService.openDialog(ModalPopUpComponent, {
                        settings: {
                            isModalDialog: false,
                            hideCloseButton: true,
                            cssClass: 'betting-settings-update__modal',
                            backdropClass: 'betting-settings-update__modal-backdrop',
                        },
                    });
                }

                if (this.modalDialogRef) {
                    this.modalDialogRef.result.then(() => this.closeModal());
                }
            }
        });

        this.userService.onUserLogout$.subscribe(() => {
            this.closeModal();
        });
    }

    private closeModal(): void {
        if (this.modalDialogRef) {
            this.modalDialogRef.close();
            this.modalDialogRef = null;
        }
    }
}
