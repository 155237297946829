import { Injectable, inject } from '@angular/core';

import { DeviceService } from '@frontend/vanilla/core';
import { NgScrollbarOptions, ScrollbarTrack, ScrollbarVisibility } from 'ngx-scrollbar';

import { PerformanceService } from '../../main/performance';

@Injectable()
export class NgScrollbarOptionsProvider implements NgScrollbarOptions {
    visibility: ScrollbarVisibility;
    track: ScrollbarTrack;
    autoHeightDisabled: boolean;

    scrollAuditTime = inject(PerformanceService).isLowTierDevice() ? 20 : 0;

    constructor(private device: DeviceService) {
        this.visibility = this.device.isMobile ? 'native' : 'hover';
        this.track = 'vertical';
        this.autoHeightDisabled = false;
    }
}
