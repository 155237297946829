import { Injectable, Injector, inject } from '@angular/core';

import { WidgetType } from '@frontend/sports/types/components/widget';
import { OnAppInit } from '@frontend/vanilla/core';
import { RewardTokensDataService } from 'packages/sports/common/betslip/modules/reward-tokens/services/reward-tokens-data.service';

import { WidgetRegistryService } from '../widget/core/widget-registry.service';
import { RewardTokensContainerComponent } from './reward-tokens-container.component';

@Injectable({
    providedIn: 'root',
})
export class RewardTokenWidgetRegistration implements OnAppInit {
    private rewardTokensDataService = inject(RewardTokensDataService);

    constructor(
        private widgetRegistry: WidgetRegistryService,
        private injector: Injector,
    ) {}

    onAppInit(): void {
        // start fetching tokens
        this.rewardTokensDataService.init();
        this.widgetRegistry.registerLazy(WidgetType.PromoBanner, RewardTokensContainerComponent, this.injector);
    }
}
