import { Injectable } from '@angular/core';

import { Sitecore } from '@frontend/sports/common/client-config-data-access';

import { BreadcrumbResult, IBreadcrumbsResolve } from '../breadcrumbs/breadcrumbs.resolver';
import { UrlHelperService } from '../navigation-core/url-helper.service';

@Injectable({ providedIn: 'root' })
export class HomeBreadcrumbsResolver implements IBreadcrumbsResolve {
    constructor(
        private sitecore: Sitecore,
        private urlHelper: UrlHelperService,
    ) {}

    resolve(): BreadcrumbResult {
        const title = this.sitecore.seo;

        return [{ icon: 'theme-home', title: title.HomeBreadcrumbTitle, url: this.urlHelper.getHomeUrl(), ignoreBreadcrumb: true }];
    }
}
