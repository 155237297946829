import { Injectable } from '@angular/core';

import { calcDiff, noopAction } from '@frontend/sports/common/base-utils';
import { OnInitEffects, createEffect } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { filter, first, map, pairwise } from 'rxjs/operators';

import StoragePersister from '../store-persist/storage-persister';
import { UiManagerActions } from './ui-manager.actions';
import { IUiRootState, uiStateSelector } from './ui-manager.state';
import { UiStateLoader, isUiSavedState } from './ui-state-storage';

@Injectable()
export class UiManagerEffects implements OnInitEffects {
    constructor(
        private persister: StoragePersister,
        private uiStateLoader: UiStateLoader,
        private store: Store<IUiRootState>,
    ) {}

    handleStateChangedFirstEffect$ = createEffect(() => {
        return this.store.select(uiStateSelector).pipe(
            first(),
            map((state) => {
                return UiManagerActions.stateChanged({
                    payload: {
                        added: [...state.states],
                        removed: [],
                        current: [...state.states],
                    },
                });
            }),
            filter((action) => action.payload.added.length > 0),
        );
    });

    handleStateChangedNextEffect$ = createEffect(() => {
        return this.store.select(uiStateSelector).pipe(
            pairwise(),
            map(([prevState, currentState]) => {
                return UiManagerActions.stateChanged({
                    payload: {
                        ...calcDiff(prevState.states, currentState.states),
                        current: [...currentState.states],
                    },
                });
            }),
            filter((action) => action.payload.added.length > 0 || action.payload.removed.length > 0),
        );
    });

    ngrxOnInitEffects(): Action {
        const state = this.persister.load();
        if (isUiSavedState(state)) {
            const uiState = this.uiStateLoader.load(state.ui);

            return UiManagerActions.populateFromStorage({ state: uiState });
        }

        return noopAction;
    }
}
