import { BestOddsGuaranteedFactoryService } from './services/best-odds-guaranteed-factory.service';
import { BestOddsGuaranteedService } from './services/best-odds-guaranteed.service';

export const provideBestOdds = () => [
    {
        provide: BestOddsGuaranteedService,
        useFactory: (factory: BestOddsGuaranteedFactoryService) => factory.create(),
        deps: [BestOddsGuaranteedFactoryService],
    },
];
