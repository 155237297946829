import { Provider } from '@angular/core';

import { MarketSubTypeLogoHideStrategy, MarketSubTypeLogoStrategy } from './marketsubtype-logo-display.strategy';

export const provideMarketSubTypeLogoHideStrategy = (): Provider => [
    {
        provide: MarketSubTypeLogoStrategy,
        useClass: MarketSubTypeLogoHideStrategy,
    },
];
