import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Utils } from '@frontend/sports/common/base-utils';
import { BetslipConfig } from '@frontend/sports/common/client-config-data-access';
import { EMPTY, Observable, timer } from 'rxjs';
import { switchMapTo } from 'rxjs/operators';

import { LoggerFactory } from '../logging/logger-factory.service';
import { SportsRemoteLogger } from '../logging/sports-remote-logger.service';

@Injectable()
export class DuplicateBetplacementInterceptor implements HttpInterceptor {
    private logger: SportsRemoteLogger;
    private requestIds = new Set<string>();

    constructor(
        private utils: Utils,
        loggerFactory: LoggerFactory,
        private betslipConfig: BetslipConfig,
    ) {
        this.logger = loggerFactory.getLogger('DuplicateBetplacementInterceptor');
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.betslipConfig.preventDuplicateRequestOnFrontend) {
            return next.handle(req);
        }

        if (req.method === 'POST' && this.isBetplacementRequest(req)) {
            const request = req.clone({ url: req.url + '?nonce=' + this.utils.generateGuid() });
            const requestId = this.getRequestId(req);

            if (!requestId) {
                const errMessage = `Unable to find requestId field for endpoint ${request.url} - has the request format changed?`;
                this.logger.error(new Error(errMessage));

                console.error(errMessage);

                return next.handle(request); //since we couldn't find a request id - we should let the request go through
            }

            if (this.requestIds.has(requestId)) {
                this.logger.error(new Error(`Trying to place a bet to: ${request.url} with the same id: ${requestId}`));

                // switchmap to EMPTY will make the promise never resolve/reject
                return timer(1).pipe(switchMapTo(EMPTY));
            }

            this.requestIds.add(requestId);

            return next.handle(request);
        }

        return next.handle(req);
    }

    private isBetplacementRequest(req: HttpRequest<any>) {
        return req.url.endsWith('/api/placebet') || req.url.endsWith('/api/placebetv2');
    }

    private getRequestId(req: HttpRequest<any>): string {
        return req.body.requestId || req.body.betslip?.requestId;
    }
}
