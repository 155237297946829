import { Injectable } from '@angular/core';

import { isDefined } from '@frontend/sports/common/base-utils';
import { ConnectionConfig } from '@frontend/sports/common/client-config-data-access';
import { PageViewDataProvider, TrackingData } from '@frontend/vanilla/core';
import { meanBy } from 'lodash-es';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { RouterEventsService } from '../../navigation-core/router-events.service';

@Injectable()
export class NetworkPerformancePageViewDataProvider implements PageViewDataProvider {
    private firstLoad = true;
    private tracked = new Subject<TrackingData>();

    constructor(
        private connectionConfig: ConnectionConfig,
        private routerEventService: RouterEventsService,
    ) {
        this.routerEventService.currentActivationEnd.pipe(filter(isDefined)).subscribe(() => {
            this.measure();
        });
    }

    getData(): Observable<TrackingData> {
        return this.tracked.asObservable();
    }

    private measure(): void {
        // eslint-disable-next-line import/no-deprecated
        if (!performance || !performance.timing) {
            return;
        }

        const cdsEntries = performance.getEntriesByType('resource').filter((e) => new RegExp(`${this.connectionConfig.cdsUrlBase}`).test(e.name));

        if (!cdsEntries || !cdsEntries.length) {
            return;
        }

        // clear the resource timings in case we have navigated before (meaning this is not the first time on the homepage)
        // otherwise this would break the tracking from the homepage first load
        if (!this.firstLoad) {
            performance.clearResourceTimings();
        }

        this.firstLoad = false;
        setTimeout(() => {
            this.tracked.next({
                'performance.cds': this.round(meanBy(cdsEntries, (e) => e.duration)),
            });
        });
    }

    /**
     * Rounds the specified number or returns `defaultValue` if it's null, undefined or invalid.
     *
     * @experimental
     */
    private round(value: number | null | undefined, defaultValue: number = 0): number {
        const rounded = Math.round(value as number);

        return Number.isNaN(rounded) ? defaultValue : rounded;
    }
}
