import { Injectable } from '@angular/core';

import { BottomNavService, MenuActionsService, OnAppInit } from '@frontend/vanilla/core';

import { BottomNavActionsService } from '../bottom-nav/bottom-nav-actions.service';

@Injectable({
    providedIn: 'root',
})
export class BottomNavBootstrapService implements OnAppInit {
    constructor(
        private menuActionsService: MenuActionsService,
        private bottomNavService: BottomNavService,
        private bottomNavActionsService: BottomNavActionsService,
    ) {}

    onAppInit(): void {
        this.menuActionsService.register('toggleBetslip', this.bottomNavActionsService.betslipToggled.bind(this.bottomNavActionsService));
        this.menuActionsService.register('toggleMyBets', this.bottomNavActionsService.myBetsToggled.bind(this.bottomNavActionsService));
        this.menuActionsService.register('menu', this.bottomNavActionsService.menuToggled.bind(this.bottomNavActionsService));
        this.bottomNavService.whenReady.subscribe(() => this.bottomNavActionsService.init());
    }
}
