import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, ActivationEnd } from '@angular/router';

import { RouteTag, SportConstant } from '@frontend/sports/common/base-utils';
import { LayoutNavigationConfig, PrettyUrlsConfig } from '@frontend/sports/common/client-config-data-access';
import { MediaQueryService, OnAppInit } from '@frontend/vanilla/core';
import { isArray, isEqual } from 'lodash-es';
import { Observable, isObservable, of } from 'rxjs';

import { LoggerFactory } from '../logging/logger-factory.service';
import { SportsRemoteLogger } from '../logging/sports-remote-logger.service';
import { TopNavigationVersion } from '../navigation-core/navigation-core.models';
import { RouterEventsService } from '../navigation-core/router-events.service';
import { getToken } from '../router/router.exports';
import { SchemaMarkupType } from '../schema-markup/schema-markup-enum';
import { SchemaMarkupService } from '../schema-markup/schema-markup.service';
import { BreadcrumbModel } from './breadcrumb.models';
import { BreadcrumbsSlotService } from './breadcrumbs-slot.service';
import { BreadcrumbData, BreadcrumbSlotResolve, IBreadcrumbsResolve } from './breadcrumbs.resolver';
import { BreadcrumbsService } from './breadcrumbs.service';

@Injectable({ providedIn: 'root' })
export class BreadcrumbsBoostrapService implements OnAppInit {
    private lastActivationEnd?: ActivationEnd;
    private readonly logger: SportsRemoteLogger;

    constructor(
        private injector: Injector,
        private routerEventsService: RouterEventsService,
        loggerFactory: LoggerFactory,
        private breadcrumbService: BreadcrumbsService,
        private breadcrumbSlotService: BreadcrumbsSlotService,
        private media: MediaQueryService,
        private schemaMarkupService: SchemaMarkupService,
        private layoutNavConfig: LayoutNavigationConfig,
        private urlConfig: PrettyUrlsConfig,
    ) {
        this.logger = loggerFactory.getLogger('BreadcrumbsBoostrapService');
    }

    onAppInit(): void {
        this.media.observe().subscribe(this.rebuildBreadcrumbs);
        this.routerEventsService.currentActivationEnd.subscribe((event) => this.activationEnded(event));
    }

    private rebuildBreadcrumbs = () => this.activationEnded(this.lastActivationEnd);

    private activationEnded(event: ActivationEnd | undefined): void {
        if (!event) {
            return;
        }

        this.lastActivationEnd = event;

        const resolverType = this.findFirstBreadcrumbResolver(event.snapshot);
        if (!resolverType) {
            if (this.breadcrumbSlotService.registeredComponent) {
                this.clearSlot();
            }

            this.clearCrumbs();

            return;
        }

        const resolver: IBreadcrumbsResolve = getToken<IBreadcrumbsResolve>(resolverType, event.snapshot, this.injector);

        if (!resolver) {
            this.logger.error(`Could not resolve Breadcrumbs resolver: ${resolverType} - route: ${event}`);

            return;
        }

        this.schemaMarkupService.removeSchemaMarkupScriptFromBody(SchemaMarkupType.BreadcrumbList);
        const result = resolver.resolve(event.snapshot.data.model);

        let model: Observable<BreadcrumbData>;

        if (isObservable(result)) {
            model = result;
        } else {
            model = of(result);
        }

        model.subscribe((data) => this.setBreadcrumbs(data, this.lastActivationEnd?.snapshot));
    }

    private setBreadcrumbs(breadcrumbsModel: BreadcrumbModel[] | BreadcrumbSlotResolve, snapshot: ActivatedRouteSnapshot | undefined): void {
        let schemaBreadcrumbs: BreadcrumbModel[];
        const shouldSetBreadcrumbs = this.shouldSetBreadcrumbs(snapshot);
        if (this.isSlotModel(breadcrumbsModel)) {
            this.clearCrumbs();

            schemaBreadcrumbs = breadcrumbsModel.schemaBreadcrumbs || [];
            const changedInputs = !isEqual(this.breadcrumbSlotService.registeredComponent?.inputs, breadcrumbsModel.inputs);

            if ((!this.isAlreadyRegistered(breadcrumbsModel) || changedInputs) && shouldSetBreadcrumbs) {
                this.breadcrumbSlotService.setSlot({
                    ...breadcrumbsModel,
                    required: true,
                });
            }
        } else {
            this.clearSlot();

            schemaBreadcrumbs = breadcrumbsModel;

            if (shouldSetBreadcrumbs) {
                this.breadcrumbService.setBreadcrumbs(breadcrumbsModel);
            } else {
                this.clearCrumbs();
            }
        }

        this.schemaMarkupService.addBreadcrumbSchemaMarkup(schemaBreadcrumbs);
    }

    private isAlreadyRegistered(breadcrumbsModel: BreadcrumbSlotResolve): boolean {
        if (this.breadcrumbSlotService.registeredComponent === undefined) {
            return false;
        }

        const getConstructor = (o: any) => Object.getPrototypeOf(o).constructor;

        return getConstructor(breadcrumbsModel.component) === getConstructor(this.breadcrumbSlotService.registeredComponent.component);
    }

    private clearCrumbs(): void {
        this.breadcrumbService.setBreadcrumbs([]);
    }

    private clearSlot(): void {
        this.breadcrumbSlotService.clearSlot();
    }

    private isSlotModel(model: BreadcrumbModel[] | BreadcrumbSlotResolve): model is BreadcrumbSlotResolve {
        return !isArray(model);
    }

    private findFirstBreadcrumbResolver(snapshot: ActivatedRouteSnapshot): any {
        let crtSnapshot: ActivatedRouteSnapshot | null = snapshot;

        while (crtSnapshot && (!crtSnapshot.data || !crtSnapshot.data['breadcrumbs'])) {
            crtSnapshot = crtSnapshot.parent;
        }

        return crtSnapshot && crtSnapshot.data['breadcrumbs'];
    }

    private shouldSetBreadcrumbs(snapshot: ActivatedRouteSnapshot | undefined): boolean {
        if (!snapshot) {
            return true;
        }

        const calendarContext: (string | undefined)[] = [
            this.urlConfig.translations.in30minutes,
            this.urlConfig.translations.in60minutes,
            this.urlConfig.translations.in180minutes,
            this.urlConfig.translations.today,
            this.urlConfig.translations.tomorrow,
            this.urlConfig.translations.after2days,
            this.urlConfig.translations.after3days,
        ];

        const { sport, context, region } = snapshot.params;
        const isCalendarCompetition = snapshot.data?.tag === RouteTag.Competition && calendarContext.includes(context);
        const isTennis = SportConstant.Tennis === +sport && context === this.urlConfig.translations.betting && !!region;

        if (
            this.layoutNavConfig.topNavigationVersion === TopNavigationVersion.V3 &&
            snapshot.data &&
            (!snapshot.data.shouldKeepBreadcrumbs || isCalendarCompetition || isTennis)
        ) {
            return false;
        }

        return true;
    }
}
