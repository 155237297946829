import { Injectable } from '@angular/core';

import { OnAppInit } from '@frontend/vanilla/core';

import { InfoPagesService } from '../intro/info-pages.service';
import { IntroPagesService } from '../intro/intro-pages.service';

@Injectable({
    providedIn: 'root',
})
export class IntroPagesBootstrapService implements OnAppInit {
    constructor(
        private infoPagesService: InfoPagesService,
        private introPagesService: IntroPagesService,
    ) {}

    onAppInit(): void {
        if (this.infoPagesService.isOnInfoPage()) {
            this.infoPagesService.show();
        } else {
            this.introPagesService.show();
        }
    }
}
