import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';

import { MediaQueryService, Page } from '@frontend/vanilla/core';
import { isEqual } from 'lodash-es';
import { Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class PatchedMediaObserver extends MediaQueryService {
    private layout = new ReplaySubject<BreakpointState>(1);

    constructor(page: Page, breakpointObserver: BreakpointObserver) {
        super(page, breakpointObserver);

        super.observe().pipe(distinctUntilChanged(isEqual)).subscribe(this.layout);
    }

    override observe(): Observable<BreakpointState> {
        return this.layout.asObservable();
    }
}
