import { Injectable, Injector } from '@angular/core';

import { BaseLoader, LoadOptions, Loader } from '@frontend/loaders';

@Injectable()
export class PriceBoostLoader extends BaseLoader implements Loader {
    constructor(injector: Injector) {
        super('price-boost', injector);
    }

    async load(options: LoadOptions): Promise<void> {
        await this.loadFeature(import(/* webpackChunkName: "ms-price-boost" */ './price-boost.feature'), options);
    }
}
