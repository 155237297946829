import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AdaptiveLayoutFlag, AdaptiveLayoutProvider } from '../layout/adaptive-layout.service';
import { SubNavigationItem } from './sub-navigation.models';
import { SubNavigationService } from './sub-navigation.service';

@Injectable({ providedIn: 'root' })
export class SubNavigationLayoutProvider implements AdaptiveLayoutProvider {
    constructor(private service: SubNavigationService) {}

    asObservable(): Observable<AdaptiveLayoutFlag> {
        return this.service.tabs$.pipe(map((value) => this.getState(value)));
    }

    private getState(value: SubNavigationItem[]): AdaptiveLayoutFlag {
        return {
            subNavigation: value && value.length > 0,
        };
    }
}
