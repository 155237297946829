import { Injectable } from '@angular/core';

import { HtmlNode, OnAppInit } from '@frontend/vanilla/core';

import { MobileHelper } from '../common/mobile-helper.service';

@Injectable({
    providedIn: 'root',
})
export class SetFeatureClassesService implements OnAppInit {
    constructor(
        private htmlNode: HtmlNode,
        private mobileHelper: MobileHelper,
    ) {}

    onAppInit(): void {
        this.htmlNode.setCssClass('breadcrumbs-visible', true);
        this.htmlNode.setCssClass('is-android-native', this.mobileHelper.isAndroidNative);
    }
}
