import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';

import { Fixture } from '@cds/betting-offer';
import { MarqueeIndexedFixtureView } from '@cds/betting-offer/domain-specific';
import { HooksWireup, OnRouteResolve } from '@frontend/sports/common/base-utils';
import { PersonalizedMarqueeConfig } from '@frontend/sports/common/client-config-data-access';
import { DispatcherService } from '@frontend/sports/common/dispatcher-utils';
import { MarqueeBackgroundContainerConfig, MarqueeTile, MarqueeType } from '@frontend/sports/types/components/content';
import { WidgetLayoutResponse, WidgetType } from '@frontend/sports/types/components/widget';
import { MarqueeLink } from '@frontend/sports/types/components/widget/types';
import { BetBuilderPickId, V1PickId, V2OptionMarketPickId } from 'packages/sports/common/betslip/core/picks/pick-id';

import { BaseBetslipEvents } from '../betslip-base/events/events';
import { CookieProvider } from '../cookie-provider/cookie-provider.service';
import { ComponentProxy } from '../deferred/component-loader.service';
import { ComponentProxyDirective } from '../deferred/component-proxy.directive';
import { HighLightsMarqueeBinding, HighlightsMarqueeComponentLoaderService } from '../deferred/highlights-marquee-component-loader.service';
import { MarqueeResponseMapperService } from '../highlights-marquee/marquee-response-mapper.service';
import { Tile } from '../highlights-marquee/marquee-tile.model';
import { UrlHelperService } from '../navigation-core/url-helper.service';

export interface MarqueePayload {
    backgroundContainerConfig: MarqueeBackgroundContainerConfig;
    fixtures: MarqueeIndexedFixtureView[];
    globalLink: MarqueeLink;
    isPersonalizedMarquee: boolean;
    tiles: MarqueeTile[];
    tileCollection: Tile[];
}
@HooksWireup()
@Component({
    selector: 'ms-home-page-marquee',
    templateUrl: 'home-page-marquee.component.html',
    imports: [NgIf, ComponentProxyDirective],
    standalone: true,
})
export class HomePageMarqueeComponent extends OnRouteResolve<WidgetLayoutResponse> {
    marqueePayloadList: MarqueePayload[] = [];
    backgroundContainerConfig: MarqueeBackgroundContainerConfig;
    globalLinkConfig: MarqueeLink;
    private personalizedMarqueeCookieName = 'ShowPersonalizedMarquee';
    hidden = false;
    fixtures: MarqueeIndexedFixtureView[];
    eventUrl: string;
    eventId: string;
    pickData: string;
    marqueeComponent: ComponentProxy<HighLightsMarqueeBinding>;
    constructor(
        route: ActivatedRoute,
        private marqueeMapper: MarqueeResponseMapperService,
        private cookieProvider: CookieProvider,
        private personalizedMarqueeConfig: PersonalizedMarqueeConfig,
        private dispatcher: DispatcherService,
        private urlHelper: UrlHelperService,
        private marqueeLoader: HighlightsMarqueeComponentLoaderService,
    ) {
        super(route);
        this.marqueeComponent = this.marqueeLoader.getHighlightsMarqueeComponent();
        this.dispatcher
            .on(BaseBetslipEvents.HomeMarqueAddPick)
            .pipe(takeUntilDestroyed())
            .subscribe((selectedPick) => {
                this.eventId = '';
                this.pickData = '';
                this.eventUrl = '';
                if (V1PickId.isId(selectedPick)) {
                    this.pickData = selectedPick.eventId + '-' + selectedPick.marketId + '-' + selectedPick.optionId;
                    this.eventId = selectedPick.eventId;
                }
                if (V2OptionMarketPickId.isId(selectedPick)) {
                    this.pickData =
                        selectedPick.fixtureId + '-' + selectedPick.optionMarketId + '-' + selectedPick.optionId + '-' + selectedPick.priceId;
                    this.eventId = selectedPick.fixtureId;
                }
                if (BetBuilderPickId.isId(selectedPick)) {
                    this.pickData =
                        selectedPick.eventId + '-' + selectedPick.fixtureId + '-' + selectedPick.optionMarketId + '-' + selectedPick.optionId;
                    this.eventId = selectedPick.fixtureId;
                }
                const fixture = this.fixtures.find((p) => p.fixture.id === this.eventId)?.fixture;
                if (fixture) {
                    this.eventUrl = this.urlHelper.getEventUrl({ id: fixture.id, name: fixture.name.value });
                }
                if (this.pickData && this.eventId && this.eventUrl) {
                    const marqueeType = this.marqueePayloadList
                        .find((x) => x.tileCollection.find((y) => y.fixtureId === fixture?.id))
                        ?.tileCollection.find((y) => y.fixtureId === fixture?.id)?.marqueeType;
                    const pickUrl = this.eventUrl + '?options=' + this.pickData + '&type=Single' + '&source=generichomelobby/marquee/' + marqueeType;
                    window.parent.postMessage({ eventUrl: pickUrl }, '*');
                }
            });
    }

    onRouteResolved(model: WidgetLayoutResponse): void {
        if (model.widgets?.length) {
            this.mapTiles(model);
        }
    }

    private mapTiles(response: WidgetLayoutResponse) {
        this.marqueePayloadList = [];
        const widgets = response.widgets;
        widgets.forEach((widget) => {
            const payload = widget.payload as MarqueePayload;

            if (widget.type === WidgetType.Marquee) {
                if (this.isNotPersonalizedMarquee(payload)) {
                    this.hidden = true;

                    return;
                }
                const tileCollection = this.marqueeMapper.mapFixtures(payload.fixtures, payload.tiles);
                this.fixtures = payload.fixtures;
                if (payload) {
                    this.backgroundContainerConfig = {
                        showTitle: payload.backgroundContainerConfig?.showTitle,
                        title: payload.backgroundContainerConfig?.title,
                        titleIconDisplay: payload.backgroundContainerConfig?.titleIconDisplay,
                        backgroundImage: '',
                        textColor: '',
                        regularFixture: this.populateHeaderData(tileCollection, payload.fixtures),
                    };
                    this.globalLinkConfig = {
                        linkOverride: payload.globalLink?.linkOverride,
                        title: payload.globalLink?.title,
                        visible: false,
                        target: payload.globalLink?.target,
                    };
                    tileCollection.forEach((tile) => {
                        tile.isFromPortal = true;
                        if (tile.trackingDetail) {
                            tile.trackingDetail.pageLayout = 'layout_europe_uk/GenericHomeLobby/default';
                        }
                    });
                    this.marqueePayloadList.push({
                        tileCollection,
                        backgroundContainerConfig: this.backgroundContainerConfig,
                        fixtures: [],
                        globalLink: this.globalLinkConfig,
                        isPersonalizedMarquee: payload.isPersonalizedMarquee,
                        tiles: [],
                    });
                }
            }
        });
        if (this.marqueePayloadList.length) {
            this.marqueeComponent.update({
                tiles: this.marqueePayloadList[0].tileCollection,
                globalLinkConfig: this.marqueePayloadList[0].globalLink,
                backgroundContainerConfig: this.marqueePayloadList[0].backgroundContainerConfig,
            });
        }
    }

    private populateHeaderData(tilesList: Tile[], fixturesData: MarqueeIndexedFixtureView[]): Fixture | undefined {
        const filteredTile = tilesList?.find((tile) => tile.marqueeType === MarqueeType.Regular);
        if (filteredTile) {
            const filteredFixtures = fixturesData?.find((fixture) => fixture.fixture.id === filteredTile.fixtureId);
            if (filteredFixtures) {
                return filteredFixtures.fixture;
            }
        }

        return undefined;
    }

    private isNotPersonalizedMarquee(data: MarqueePayload): boolean {
        return (
            data.isPersonalizedMarquee &&
            (!this.cookieProvider.getCookie(this.personalizedMarqueeCookieName) ||
                !(data.tiles?.length >= this.personalizedMarqueeConfig.minEventsToShow))
        );
    }
}
