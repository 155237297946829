import { Injectable, inject } from '@angular/core';

import { RouteTag } from '@frontend/sports/common/base-utils';
import { OnAppInit } from '@frontend/vanilla/core';

import { WidgetRefreshService } from '../widget/core/widget-refresh.service';
import { EsportsRefreshTrigger } from './esports-refresh-trigger';

@Injectable({ providedIn: 'root' })
export class EsportsRefreshRegistrationService implements OnAppInit {
    private refreshRegistry = inject(WidgetRefreshService);
    private refresh = inject(EsportsRefreshTrigger);

    onAppInit(): void {
        this.refreshRegistry.registerTrigger(RouteTag.EsportsLobby, this.refresh);
    }
}
