import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';

import { HooksWireup, OnDestroyCleanup } from '@frontend/sports/common/base-utils';
import { BetslipIntegrationService } from 'packages/sports/common/betslip/integration/betslip-integration.service';

import { BetslipBarComponentBindings, BetslipDigitalComponentLoaderService } from '../betslip/betslip-digital-component-loader.service';
import { ComponentProxy } from '../deferred/component-loader.service';
import { ComponentProxyDirective } from '../deferred/component-proxy.directive';
import { BottomNavActionsService } from './bottom-nav-actions.service';

@HooksWireup()
@Component({
    selector: 'ms-betslip-bar-container',
    template: ` <ng-container *ngIf="enabled">
        <ng-container *msComponentProxy="component"></ng-container>
    </ng-container>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgIf, ComponentProxyDirective],
    standalone: true,
})
export class BetslipBarContainerComponent extends OnDestroyCleanup {
    component: ComponentProxy<BetslipBarComponentBindings>;

    enabled = false;

    constructor(
        betslipLoader: BetslipDigitalComponentLoaderService,
        private betslipIntegration: BetslipIntegrationService,
        private bottomNavActionsService: BottomNavActionsService,
        private changeDetectorRef: ChangeDetectorRef,
    ) {
        super();

        this.enabled = this.betslipIntegration.betslipBarEnabled;

        if (this.enabled) {
            this.component = betslipLoader.getBetslipBarComponent();
            this.component.subscribeToOutputs({
                barClick: () => this.bottomNavActionsService.betslipToggled(),
                myBetsClick: () => this.bottomNavActionsService.myBetsToggled(),
            });
            this.changeDetectorRef.markForCheck();

            return;
        }

        this.component?.destroy();
    }
}
