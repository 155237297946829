import { Injectable, Injector } from '@angular/core';

import { BaseLoader, LoadOptions, Loader } from '@frontend/loaders';

@Injectable()
export class TabbedGridLoader extends BaseLoader implements Loader {
    constructor(injector: Injector) {
        super('tabbed-grid', injector);
    }

    async load(options: LoadOptions): Promise<void> {
        await this.loadFeature(import(/* webpackChunkName: "ms-tabbed-grid-widget" */ './tabbed-grid.feature'), options);
    }
}
