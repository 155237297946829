import { createFeatureSelector } from '@ngrx/store';

import { IRootState } from '../../store/root.state';

export interface NumpadStoreState {
    focus: boolean;
}

export const numpadInitialState: NumpadStoreState = {
    focus: false,
};

export const numpadFeatureKey = 'numpad';

export interface INumpadRootState extends IRootState {
    numpad: NumpadStoreState;
}

export const numpadStateSelector = createFeatureSelector<NumpadStoreState>(numpadFeatureKey);
