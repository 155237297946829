import { Injectable } from '@angular/core';

import { LiveHighlightsResponse } from '@cds/betting-offer/domain-specific/live';
import { MessageEnvelope, MessageType } from '@cds/push';
import { FixtureState, LiveHighlightsRequest, OfferMapping, ScoreboardMode, SortByCriteria } from '@cds/query-objects';
import { noopAction } from '@frontend/sports/common/base-utils';
import { ConnectionConfig, MediaConfig } from '@frontend/sports/common/client-config-data-access';
import { LocalStoreService, MediaQueryService, UserLoginEvent } from '@frontend/vanilla/core';
import { Actions as EffectActions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { applyPatch } from 'fast-json-patch';
import { keyBy, omit, uniq } from 'lodash-es';
import { from, of, timer } from 'rxjs';
import { concatMap, filter, ignoreElements, map, skipWhile, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

import { BettingOfferApi } from '../cds/betting-offer-api.service';
import { CdsPushService, ScoreboardSubscription } from '../cds/cds-push.service';
import { EventDetailsHelperService } from '../event-details-common/event-details-helper.service';
import { EventDetailsService } from '../event-details-common/event-details.service';
import { EventDetailsColumnType, EventMediaHelperService } from '../event-model/helpers/event-media-helper.service';
import { DetailedFixtureFactory } from '../event-model/mappers/detailed-fixture.factory';
import { EventModel } from '../event-model/model/event.model';
import { ColumnLayoutRegister } from '../layout/column-register.service';
import { ColumnSize, LayoutColumn } from '../layout/layout.model';
import { MediaApiActions } from '../media-api/actions';
import {
    EventSwitcherItem,
    MediaEventSource,
    MediaModuleContextPage,
    MediaPersistentState,
    MediaSetEvent,
    MediaSetEventInternal,
} from '../media-api/model';
import StoragePersister from '../store-persist/storage-persister';
import { TrackingVideoSource } from '../tracking/tracking-video-source';
import { UserService } from '../user/services/user.service';
import { Actions } from './actions';
import { MediaStateLoader, isMediaSavedState } from './media-state-storage';
import {
    IMediaRootState,
    MediaState,
    mediaActiveSelector,
    mediaEventInfoSelector,
    mediaExpandedStateSelector,
    mediaStateSelector,
} from './media.state';
import { UnpinInfoService } from './unpin-info.service';

export interface MediaComponentState {
    video: { eventId: number; pinned: boolean; autoplay: boolean };
    animation: { eventId: number };
    statistics: { eventId: number };
    activeTab: EventDetailsColumnType;
}

@Injectable()
export class MediaEffects implements OnInitEffects {
    private static readonly STREAMS_CACHE_INTERVAL = 2 * 60 * 1000;
    private static readonly MEDIA_PERSISTENT_STATE_KEY = 'media-state';

    private isStateRestored = false;
    private subscriptions: { [eventId: number]: { unsubscribe: () => void } } = {};

    constructor(
        private actions$: EffectActions,
        private store: Store<IMediaRootState>,
        private cdsSubscription: CdsPushService,
        private bettingOfferApi: BettingOfferApi,
        private eventDetailsService: EventDetailsService,
        private detailedFixtureFactory: DetailedFixtureFactory,
        private eventDetailsHelper: EventDetailsHelperService,
        private eventMediaHelperService: EventMediaHelperService,
        private unpinInfoService: UnpinInfoService,
        private userService: UserService,
        private localStoreService: LocalStoreService,
        private columnLayoutRegister: ColumnLayoutRegister,
        private mediaConfig: MediaConfig,
        private mediaService: MediaQueryService,
        private connection: ConnectionConfig,
        private storagePersister: StoragePersister,
        private stateLoader: MediaStateLoader,
    ) {}

    onInit$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(Actions.init),
            concatMap(() => [
                Actions.toggleMediaModule({ value: true }),
                Actions.startFetchVideoEvents(),
                Actions.startUserServiceEventsListener(),
                Actions.pinEnabledStateToggle({ value: this.userService.isAuthenticated }),
                Actions.startRestoreState(),
            ]),
        );
    });

    setEventId$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(MediaApiActions.setEventId),
            concatMap((action) => of(action).pipe(withLatestFrom(this.store.select(mediaEventInfoSelector(action.payload.eventId))))),
            switchMap(([action, eventInfo]) => {
                const eventModel$ = eventInfo
                    ? of(eventInfo.event)
                    : from(this.eventDetailsService.getEvent(action.payload.eventId.toString(), true));

                return eventModel$.pipe(
                    filter((event) => event !== undefined),
                    map((result) => ({
                        event: result!,
                        tab: action.payload.tab,
                        source: action.payload.source,
                        trackingVideoSource: action.payload.trackingVideoSource,
                    })),
                );
            }),
            map((actionPayload) => Actions.setEventInternal({ payload: this.mapMediaSetEventToSetEventInternalPayload(actionPayload) })),
        );
    });

    setEvent$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(MediaApiActions.setEvent),
            map((action) => Actions.setEventInternal({ payload: this.mapMediaSetEventToSetEventInternalPayload(action.payload) })),
        );
    });

    setEventInternal$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(Actions.setEventInternal),
            withLatestFrom(this.store.select(mediaStateSelector)),
            tap(([action, mediaState]) => this.updateSubscriptions(mediaState, action.payload.event)),
            concatMap(([action, mediaState]) => this.dispatchSetEventInternalActions(action.payload, mediaState)),
        );
    });

    unsetEvent$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(Actions.unsetEvent),
                withLatestFrom(this.store.select(mediaStateSelector)),
                tap(([action, mediaState]) => this.removeObsoleteSubscriptions(mediaState)),
                ignoreElements(),
            );
        },
        { dispatch: false },
    );

    startFetchVideoEvents$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(Actions.startFetchVideoEvents),
            switchMap(() => {
                const notActive$ = this.store.select(mediaActiveSelector).pipe(skipWhile((active) => !!active));

                return timer(0, MediaEffects.STREAMS_CACHE_INTERVAL).pipe(
                    map(() => Actions.fetchVideoEvents()),
                    takeUntil(notActive$),
                );
            }),
        );
    });

    startUserServiceEventsListener$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(Actions.startUserServiceEventsListener),
            switchMap(() => {
                const notActive$ = this.store.select(mediaActiveSelector).pipe(skipWhile((active) => active));

                return this.userService.onAuthenticationChange$.pipe(
                    map((event) => Actions.pinEnabledStateToggle({ value: event instanceof UserLoginEvent })),
                    takeUntil(notActive$),
                );
            }),
        );
    });

    fetchVideoEvents$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(Actions.fetchVideoEvents),
            switchMap(() => {
                const request: LiveHighlightsRequest = {
                    state: FixtureState.Live,
                    offerMapping: OfferMapping.None,
                    scoreboardMode: ScoreboardMode.None,
                    sortBy: SortByCriteria.Tags,
                    maxFixturesPerSport: undefined,
                } as any;

                return this.bettingOfferApi.getLiveStreams(request);
            }),
            map((streams) => Actions.setVideoSwitcherContent({ content: this.mapToEventSwitcherItem(streams) })),
        );
    });

    acceptUnpinInfoMessage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(Actions.acceptUnpinInfoMessage),
            tap(() => this.unpinInfoService.acceptUnpinInfoMessage()),
            map(() => Actions.toggleUnpinInfoMessage({ value: false })),
        );
    });

    toggleUnpinInfoMessageAfterContextSwitch$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(MediaApiActions.setMediaWidgetContext),
            filter((action) => action.payload.page === MediaModuleContextPage.Default),
            map(() => Actions.toggleUnpinInfoMessage({ value: false })),
        );
    });

    toggleUnpinInfoMessageAfterUnpinning$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(Actions.togglePin),
            filter((action) => !action.value),
            map(() => Actions.toggleUnpinInfoMessage({ value: false })),
        );
    });

    persistStateEpic$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(
                    Actions.unsetEvent,
                    Actions.setEventInternal,
                    Actions.setActiveTab,
                    Actions.toggleAutoplay,
                    Actions.togglePin,
                    Actions.restoreState,
                    Actions.resetState,
                ),
                withLatestFrom(this.store.select(mediaStateSelector)),
                tap(([action, mediaState]) => this.persistState(mediaState)),
            );
        },
        { dispatch: false },
    );

    startRestoreStateEpic$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(Actions.startRestoreState),
            switchMap(() => {
                const mediaPersistentState = this.localStoreService.get(MediaEffects.MEDIA_PERSISTENT_STATE_KEY) as MediaPersistentState;
                if (!mediaPersistentState) {
                    this.isStateRestored = true;

                    return of(null);
                }

                const eventIds = uniq([
                    mediaPersistentState.video.eventId,
                    mediaPersistentState.animation.eventId,
                    mediaPersistentState.statistics.eventId,
                ]).filter((eventId) => !!eventId);

                return from(Promise.all(eventIds.map((eventId) => this.eventDetailsService.getEvent(eventId!.toString(), true)))).pipe(
                    map((events: (EventModel | undefined)[]) => {
                        const eventsMap = keyBy(
                            events.filter((event) => !!event).map((event) => this.mapEventModelToSetEventInternalPayload(event!)),
                            'event.id',
                        );

                        return { ...mediaPersistentState, events: eventsMap };
                    }),
                );
            }),
            filter((payload) => !!payload),
            map((payload) => Actions.restoreState({ payload: payload! })),
        );
    });

    restoreState$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(Actions.restoreState),
                tap(() => {
                    this.isStateRestored = true;
                }),
                ignoreElements(),
            );
        },
        { dispatch: false },
    );

    toggleExpand$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(Actions.toggleExpand),
                withLatestFrom(this.store.select(mediaExpandedStateSelector)),
                tap(([action, expandedState]) => {
                    const isExpanded = expandedState.isExpanded;
                    const layoutSize = isExpanded
                        ? this.mediaService.isActive('gt-mw')
                            ? ColumnSize.ExtraLarge
                            : ColumnSize.Large
                        : ColumnSize.Default;
                    this.columnLayoutRegister.resizeColumn(layoutSize, LayoutColumn.Right);
                }),
                ignoreElements(),
            );
        },
        { dispatch: false },
    );

    handlePopulateFromStorage$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(Actions.populateFromStorage),
                withLatestFrom(this.store.select(mediaExpandedStateSelector)),
                tap(([action, expandedState]) => {
                    const restoreExpandState = action.state.mediaExpandState.restoreExpandState;
                    const isLocationAllowsExpand = expandedState.isLocationAllowsExpand;

                    if (isLocationAllowsExpand && restoreExpandState) {
                        this.columnLayoutRegister.resizeColumn(
                            this.mediaService.isActive('gt-mw') ? ColumnSize.ExtraLarge : ColumnSize.Large,
                            LayoutColumn.Right,
                        );
                    }
                }),
            );
        },
        { dispatch: false },
    );

    handlebreakpointChange$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(Actions.breakpointChanged),
                withLatestFrom(this.store.select(mediaExpandedStateSelector)),
                tap(([action, expandedState]) => {
                    const isMediaExpanded = expandedState.isExpanded;

                    if (isMediaExpanded) {
                        this.columnLayoutRegister.resizeColumn(
                            this.mediaService.isActive('gt-mw') ? ColumnSize.ExtraLarge : ColumnSize.Large,
                            LayoutColumn.Right,
                        );
                    } else {
                        this.columnLayoutRegister.resizeColumn(ColumnSize.Default, LayoutColumn.Right);
                    }
                }),
                ignoreElements(),
            );
        },
        { dispatch: false },
    );

    ngrxOnInitEffects(): Action {
        const state = this.storagePersister.load();
        if (isMediaSavedState(state)) {
            const mediaState = this.stateLoader.load(state.media);

            return Actions.populateFromStorage({ state: mediaState });
        }

        return noopAction;
    }

    private *dispatchSetEventInternalActions(payload: MediaSetEventInternal, mediaState: MediaState): Iterable<Action> {
        yield Actions.toggleUnpinInfoMessage({ value: this.unpinInfoService.shouldShowUnpinInfoMessage(mediaState) });

        if (
            payload.source === MediaEventSource.Grid ||
            payload.source === MediaEventSource.Scoreboard ||
            payload.source === MediaEventSource.EventDetails
        ) {
            yield Actions.toggleCollapsedState({ value: false });
        }
    }

    private mapToEventSwitcherItem(eventsModel?: LiveHighlightsResponse | undefined): EventSwitcherItem[] {
        if (!eventsModel) {
            return [];
        }

        return eventsModel.sportsOffer
            .filter((sportOffer) => !this.mediaConfig.restrictedSportIds.includes(sportOffer.sport.id))
            .map((item) => ({
                id: item.sport.id,
                name: item.sport.name.value,
                children: item.fixtures.map((event) => ({
                    id: event.id,
                    name: event.name.value,
                })),
            }));
    }

    private updateSubscriptions(mediaState: MediaState, event: EventModel): void {
        this.removeObsoleteSubscriptions(mediaState);
        this.subscribe(event);
    }

    private persistState(mediaState: MediaState): void {
        if (!this.isStateRestored) {
            return;
        }
        const persistedState: MediaPersistentState = {
            activeTab: mediaState.activeTab,
            video: {
                eventId: mediaState.video.eventId,
                pinned: mediaState.video.pinned,
            },
            animation: mediaState.animation,
            statistics: mediaState.statistics,
        };

        this.localStoreService.set(MediaEffects.MEDIA_PERSISTENT_STATE_KEY, persistedState);
    }

    private removeObsoleteSubscriptions(mediaState: MediaState): void {
        const toUnsubscribe = omit(this.subscriptions, [
            mediaState.video.eventId || 0,
            mediaState.animation.eventId || 0,
            mediaState.statistics.eventId || 0,
        ]);

        Object.keys(toUnsubscribe).forEach((eventId) => {
            if (this.subscriptions[eventId]) {
                this.subscriptions[eventId].unsubscribe();
                delete this.subscriptions[eventId];
            }
        });
    }

    private subscribe(event: EventModel): void {
        if (this.subscriptions[event.id] || !event.live) {
            return;
        }

        const scoreboardHandler = (pushData: MessageEnvelope) => {
            if (pushData.messageType === MessageType.PlayerStatsUpdate) {
                return;
            }

            applyPatch(event.fixtureScoreboard, pushData.payload.patch.operations);
            this.detailedFixtureFactory.updateScoreboard(event.fixtureScoreboard, event);
            this.updateScoreboard(event);
        };

        const scoreboardSubscription = new ScoreboardSubscription(event.offerContext, event.id, this.connection.culture, scoreboardHandler);

        this.cdsSubscription.subscribe(scoreboardSubscription);

        this.subscriptions[event.id] = { unsubscribe: () => this.cdsSubscription.unsubscribe(scoreboardSubscription) };
    }

    private mapMediaSetEventToSetEventInternalPayload(setEventPayload: MediaSetEvent): MediaSetEventInternal {
        return {
            event: setEventPayload.event,
            tab: setEventPayload.tab,
            source: setEventPayload.source,
            hasVideo: this.eventMediaHelperService.hasLiveVideo(setEventPayload.event),
            hasAnimation: this.eventMediaHelperService.hasAnimationOrSimulation(setEventPayload.event),
            hasStats: this.eventDetailsHelper.hasStats(setEventPayload.event),
            showStatsCenterButton: this.mediaConfig.showStatsCenterButton,
            trackingVideoSource: setEventPayload.trackingVideoSource,
        };
    }

    private mapEventModelToSetEventInternalPayload(eventModel: EventModel): MediaSetEventInternal {
        return {
            event: eventModel,
            tab: null,
            source: MediaEventSource.Default,
            hasVideo: this.eventMediaHelperService.hasLiveVideo(eventModel),
            hasAnimation: this.eventMediaHelperService.hasAnimationOrSimulation(eventModel),
            hasStats: this.eventDetailsHelper.hasStats(eventModel),
            showStatsCenterButton: this.mediaConfig.showStatsCenterButton,
            trackingVideoSource: TrackingVideoSource.Default,
        };
    }

    private updateScoreboard(event: EventModel): void {
        this.store.dispatch(Actions.updateScoreboard({ model: event }));
    }
}
