import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class GlobalBestOddsGuaranteeAvailabilityService {
    get isBogAvailable$(): Observable<boolean> {
        return of(true);
    }
}
