import { Injectable, Injector } from '@angular/core';

import { BaseLoader, LoadOptions, Loader } from '@frontend/loaders';

@Injectable()
export class NextToGoLoader extends BaseLoader implements Loader {
    constructor(injector: Injector) {
        super('next-to-go', injector);
    }

    async load(options: LoadOptions): Promise<void> {
        await this.loadFeature(import(/* webpackChunkName: "ms-next-to-go" */ './next-to-go.feature'), options);
    }
}
