import { Injectable } from '@angular/core';

import { EventsService, NativeEventType } from '@frontend/vanilla/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { debounceTime, filter, takeUntil, tap } from 'rxjs/operators';

import { GRID_ACTION_SCHEMA } from '../grid-base/grid.actions';
import { IRootState } from '../store/root.state';
import StateConverterService from './state-converter.service';
import StoragePersister from './storage-persister';
import StorePersistActions from './store-persist-actions';

@Injectable()
export default class StorePersistEffects {
    private static readonly SAVE_STORAGE_BUFFER_TIME = 200; // save all save state requests which come in 200 milliseconds and then update the storage.

    constructor(
        private store: Store<IRootState>,
        private actions: Actions,
        private storagePersister: StoragePersister,
        private stateConverter: StateConverterService,
        private eventsService: EventsService,
    ) {}

    handleSaveStateEffect$ = createEffect(
        () => {
            return this.actions.pipe(
                filter((action) => !action.type.startsWith(GRID_ACTION_SCHEMA)),
                debounceTime(StorePersistEffects.SAVE_STORAGE_BUFFER_TIME),
                concatLatestFrom(() => this.store),
                tap(([_, currentState]) => this.saveState(currentState)),
                takeUntil(this.eventsService.events.pipe(filter((t) => t.eventName === NativeEventType.RESET_TERMINAL))),
            );
        },
        { dispatch: false },
    );

    handleManualSaveStateEffect$ = createEffect(
        () => {
            return this.actions.pipe(
                ofType(StorePersistActions.saveState),
                concatLatestFrom(() => this.store),
                tap(([_, currentState]) => this.saveState(currentState)),
            );
        },
        { dispatch: false },
    );

    private saveState(currentState: IRootState): void {
        const previousState = this.storagePersister.load() || {};
        const convertedState = this.stateConverter.convert(currentState);
        const newState = Object.assign({}, previousState, convertedState);
        this.storagePersister.save(newState);
    }
}
