import { createReducer, on } from '@ngrx/store';

import { UiManagerActions } from './ui-manager.actions';
import { IUiState, defaultUiState } from './ui-manager.state';
import { UiStates } from './ui-states.enum';

const addState = (states: UiStates[], state: UiStates): UiStates[] => {
    return [...states, state];
};

const removeState = (states: UiStates[], index: number): UiStates[] => {
    if (index > -1) {
        const newStates = states.slice();
        newStates.splice(index, 1);

        return newStates;
    } else {
        return states;
    }
};

export const uiStateReducer = createReducer(
    defaultUiState,
    on(UiManagerActions.setState, (state, action) => {
        return {
            ...state,
            states: [action.state],
        };
    }),
    on(UiManagerActions.addState, (state, action) => {
        const uiState = action.state;
        const index = state.states.indexOf(uiState);
        if (index === -1) {
            return {
                ...state,
                states: addState(state.states, uiState),
            };
        }

        return state;
    }),
    on(UiManagerActions.removeState, (state, action) => {
        const payload = action.state;

        return {
            ...state,
            states: state.states.filter((s) => s !== payload),
        };
    }),
    on(UiManagerActions.removeMultiStates, (state, action) => {
        const payload = action.states;

        return {
            ...state,
            states: state.states.filter((s) => payload.indexOf(s) === -1),
        };
    }),
    on(UiManagerActions.toggleState, (state, action) => {
        const payload = action.state;
        const index = state.states.indexOf(payload);

        return {
            ...state,
            states: index === -1 ? addState(state.states, payload) : removeState(state.states, index),
        };
    }),
    on(UiManagerActions.populateFromStorage, (state, action) => {
        const ui = action.state;

        return {
            ...state,
            ...ui,
            states: [...state.states, ...ui.states.filter((_) => !state.states.some((_s) => _s === _))],
        };
    }),
);

export const uiStateSaver = (state: IUiState): IUiState => {
    return state;
};

export const uiStateLoader = (state: IUiState): IUiState => {
    return state;
};
