import { Inject, Injectable, OnDestroy, Optional } from '@angular/core';

import { LongRunningBackgroundWorkerService, Nullable, RepeatBackgroundWorkerService } from '@frontend/sports/common/base-utils';
import { OnAppInit } from '@frontend/vanilla/core';
import { Observable, Subscription, merge, timer } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class BackgroundWorkerManagerService implements OnAppInit, OnDestroy {
    private readonly repeatBackgroundServices: RepeatBackgroundWorkerService[];
    private readonly longRunningBackgroundServices: LongRunningBackgroundWorkerService[];
    private repeatingServicesSubscription: Nullable<Subscription> = null;

    constructor(
        @Optional() @Inject(RepeatBackgroundWorkerService) repeatBackgroundServices: RepeatBackgroundWorkerService[],
        @Optional() @Inject(LongRunningBackgroundWorkerService) longRunningBackgroundServices: LongRunningBackgroundWorkerService[],
    ) {
        this.repeatBackgroundServices = repeatBackgroundServices ?? [];
        this.longRunningBackgroundServices = longRunningBackgroundServices ?? [];
        if (!(Array.isArray(this.repeatBackgroundServices) && Array.isArray(this.longRunningBackgroundServices))) {
            throw new Error('Expecting array of services got something else!');
        }
    }

    onAppInit(): void | Promise<any> {
        this.runRepeatingServices();
        this.runLongRunningServices();
    }

    ngOnDestroy(): void {
        this.repeatingServicesSubscription?.unsubscribe();
        this.stopLongRunningServices();
    }

    private runRepeatingServices(): void {
        const services: Observable<number>[] = [];
        for (const service of this.repeatBackgroundServices) {
            services.push(this.setupRepeatTimer(service));
        }
        this.repeatingServicesSubscription = merge(...services).subscribe();
    }

    private setupRepeatTimer(service: RepeatBackgroundWorkerService): Observable<number> {
        const executionTime = service.getSchedule();
        service.init();

        return timer(executionTime.delay, executionTime.interval).pipe(
            tap((val) => {
                if (val === 0) {
                    service.init();
                }
                service.run();
            }),
            finalize(() => service.destroy()),
        );
    }

    private runLongRunningServices(): void {
        for (const service of this.longRunningBackgroundServices) {
            service.init();
            service.run();
        }
    }

    private stopLongRunningServices(): void {
        for (const service of this.longRunningBackgroundServices) {
            service.destroy();
        }
    }
}
