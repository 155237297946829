import { Injectable } from '@angular/core';

import { SportsProductMenuConfig } from '@frontend/sports/common/client-config-data-access';
import { DeviceService, DynamicLayoutService, OnAppInit } from '@frontend/vanilla/core';
import { ProductMenuService } from '@frontend/vanilla/shared/product-menu';

import { BetslipBarContainerComponent } from '../bottom-nav/betslip-bar-container.component';
import { BreadcrumbLayoutProvider } from '../breadcrumbs/breadcrumb-layout.provider';
import { CriticalErrorComponent } from '../critical-error/critical-error.component';
import { AdaptiveLayoutService } from '../layout/adaptive-layout.service';
import { ColumnLayoutService } from '../layout/column-layout.service';
import { DeviceLayoutProvider } from '../layout/device-layout.provider';
import { FixedLayout, Layout, ResponsiveLayout } from '../layout/layout.model';
import { RightColumnLayoutProvider } from '../layout/right-column-layout.provider';
import { RouteLayoutProvider } from '../layout/route-layout.provider';
import { SportsProductMenuVersion } from '../sports-product-menu/sports-product-menu.enum';
import { SubNavigationLayoutProvider } from '../sub-navigation/sub-navigation-layout.provider';
import { SportsProductMenuLoaderService } from './sports-product-menu-loader.service';

@Injectable({
    providedIn: 'root',
})
export class LayoutBootstrapService implements OnAppInit {
    private readonly productMenu = 'sports-all-layout';

    constructor(
        private dynamicLayoutService: DynamicLayoutService,
        private adaptiveLayoutService: AdaptiveLayoutService,
        private deviceLayoutProvider: DeviceLayoutProvider,
        private routeLayoutProvider: RouteLayoutProvider,
        private breadcrumbLayoutProvider: BreadcrumbLayoutProvider,
        private subNavigationLayoutProvider: SubNavigationLayoutProvider,
        private productMenuService: ProductMenuService,
        private columnBootstrap: ColumnLayoutService,
        private rightColumnLayoutProvider: RightColumnLayoutProvider,
        private deviceService: DeviceService,
        private sportsProductMenuConfig: SportsProductMenuConfig,
        private sportsProductMenuLoaderService: SportsProductMenuLoaderService,
    ) {}

    onAppInit(): void {
        this.dynamicLayoutService.addComponent('app', CriticalErrorComponent);
        this.dynamicLayoutService.addComponent('app', BetslipBarContainerComponent);

        this.columnBootstrap.initializeLayout(this.createLayout());

        this.adaptiveLayoutService.register(
            this.deviceLayoutProvider,
            this.routeLayoutProvider,
            this.breadcrumbLayoutProvider,
            this.subNavigationLayoutProvider,
            this.rightColumnLayoutProvider,
        );

        if (this.sportsProductMenuConfig.version === SportsProductMenuVersion.V2) {
            this.sportsProductMenuLoaderService.loadAndInitModule().subscribe((m) => {
                this.productMenuService.setProductMenuComponent(this.productMenu, m.sportsProductMenuComponent);
            });
        } else {
            import('../event-list-shared/sport/overview/sport-list-body.component').then((m) => {
                this.productMenuService.setProductMenuComponent(this.productMenu, m.SportListBodyComponent);
            });
        }
    }

    private createLayout(): Layout {
        if (this.deviceService.isMobilePhone) {
            return new FixedLayout(1);
        }

        return new ResponsiveLayout(3);
    }
}
