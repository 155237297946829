import { Injectable } from '@angular/core';

import { ISavedState, StateLoader, StateSaver } from '../store-persist/state-storage.model';
import { UserService } from '../user/services/user.service';
import { IUiState, defaultUiState } from './ui-manager.state';
import { uiStatesThatShouldBeRestored_Authenticated, uiStatesThatShouldBeRestored_NotAuthenticated } from './ui-states.enum';

export interface IUiSavedState extends ISavedState {
    ui: IUiState;
}

export function isUiSavedState(savedState: ISavedState | undefined): savedState is IUiSavedState {
    return !!savedState && 'ui' in savedState;
}

@Injectable({ providedIn: 'root' })
export class UiStateSaver implements StateSaver<IUiState, IUiState> {
    constructor(private user: UserService) {}

    save(state: IUiState): IUiState {
        const uiStatesThatShouldBeRestored = this.user.isAuthenticated
            ? uiStatesThatShouldBeRestored_Authenticated
            : uiStatesThatShouldBeRestored_NotAuthenticated;

        return {
            states: state.states.filter((candidateState) => uiStatesThatShouldBeRestored.some((allowedState) => allowedState === candidateState)),
        };
    }
}

@Injectable({ providedIn: 'root' })
export class UiStateLoader implements StateLoader<IUiState, IUiState> {
    constructor(private user: UserService) {}

    load(state: IUiState | undefined): IUiState {
        if (!state) {
            return { ...defaultUiState };
        }
        const uiStatesThatShouldBeRestored = this.user.isAuthenticated
            ? uiStatesThatShouldBeRestored_Authenticated
            : uiStatesThatShouldBeRestored_NotAuthenticated;
        const uiStates = state.states.filter((candidateState) =>
            uiStatesThatShouldBeRestored.some((allowedState) => allowedState === candidateState),
        );
        if (uiStates.length === 0) {
            return { ...defaultUiState };
        }

        return {
            states: uiStates,
        };
    }
}
