import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { CountItem } from '@frontend/sports/common/base-utils';
import { isArray } from 'lodash-es';
import { Observable, map } from 'rxjs';

import { SportsCacheService } from '../../client-caching/sports-cache.service';
import { CompetitionRouteService } from '../../competition-list/competition-route.service';
import { StandingsData } from '../../grid-base/grid.model';
import { PruneHelperService } from './competitions/prune-helper.service';
import { StandingsPageResponse } from './standings-page.model';
import { StandingsService } from './standings.service';
import { VirtualCompetitionService } from './virtual-competition.service';

@Injectable({ providedIn: 'root' })
export class StandingsPageResolver {
    sport?: CountItem;
    standingsData: StandingsData = {
        groups: [],
    };

    constructor(
        private pruneHelperService: PruneHelperService,
        private standingsService: StandingsService,
        private sportsCacheService: SportsCacheService,
        private competitionRouteService: CompetitionRouteService,
        private virtualCompetitionService: VirtualCompetitionService,
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<StandingsPageResponse | undefined> {
        const response: StandingsPageResponse = {
            route: {},
        };

        return this.sportsCacheService.getSport(route.params.sport).pipe(
            map((sport) => {
                response.route = this.competitionRouteService.params();

                sport = this.pruneHelperService.prune(sport, { ...response.route, virtualCompetitionGroup: undefined });
                response.sport = sport;
                response.standingsData = response.route.league
                    ? this.getStandings(sport, response.route.virtualCompetitionGroup, response.route.league, response.route.isVirtual)
                    : {
                          groups: [],
                      };
                response.virtualCompetition = this.virtualCompetitionService.getCompetition(sport, response.route);

                return response;
            }),
        );
    }

    private getStandings(
        sport: CountItem | undefined,
        selectedVirtualGroupId?: number,
        selectedVirtualCompetition?: number | number[] | undefined,
        isVirtual?: boolean | undefined,
    ): StandingsData {
        if (sport) {
            const groups = this.standingsService.getStandingsGroups({ sport, selectedVirtualGroupId });
            const league = isVirtual ? (isArray(selectedVirtualCompetition) ? undefined : selectedVirtualCompetition) : undefined;

            return {
                groups,
                virtualCompetitionId: league,
            };
        }

        return {
            groups: [],
        };
    }
}
