import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { ConnectionConfig, Sitecore } from '@frontend/sports/common/client-config-data-access';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { CriticalErrorService } from '../critical-error/critical-error.service';
import { LoggerFactory } from '../logging/logger-factory.service';

@Injectable()
export class ConnectionLostInterceptor implements HttpInterceptor {
    private asyncRetriesCount = 0;
    private maxRetriesCount: number;

    constructor(private injector: Injector) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap(
                (event) => {
                    if (event instanceof HttpResponse) {
                        this.response(event);
                    }
                },
                (error) => {
                    if (error instanceof HttpErrorResponse) {
                        this.responseError(error);
                    }
                },
            ),
        );
    }

    response(response: HttpResponse<any>): HttpResponse<any> {
        const connectionConfig = this.injector.get(ConnectionConfig);
        const criticalErrorService = this.injector.get(CriticalErrorService);
        const loggerFactory: LoggerFactory = this.injector.get(LoggerFactory);
        const logger = loggerFactory.getLogger('ConnectionLostInterceptor');
        const sitecore = this.injector.get(Sitecore);
        sitecore.whenReady.subscribe(() => (this.maxRetriesCount = parseInt(sitecore.globalMessages['CriticalErrorsRetryCount'])));

        if (response.url && response.url.indexOf(connectionConfig.cdsUrlBase) !== -1) {
            if (criticalErrorService.isCriticalErrorVisible) {
                logger.error('Recovered back from critical error on AJAX calls');
            }
            criticalErrorService.hideCriticalError();
            this.asyncRetriesCount = 0;
        }

        return response;
    }

    responseError(error: HttpErrorResponse): void {
        const connectionConfig = this.injector.get(ConnectionConfig);
        const criticalErrorService = this.injector.get(CriticalErrorService);
        const loggerFactory = this.injector.get(LoggerFactory);
        const logger = loggerFactory.getLogger('ConnectionLostInterceptor');
        const sitecore = this.injector.get(Sitecore);
        sitecore.whenReady.subscribe(() => (this.maxRetriesCount = parseInt(sitecore.globalMessages['CriticalErrorsRetryCount'])));
        if (error.url && error.url.indexOf(connectionConfig.cdsUrlBase) !== -1) {
            this.asyncRetriesCount++;
            if (this.asyncRetriesCount > this.maxRetriesCount) {
                criticalErrorService.showCriticalError();
                logger.error(error, 'Too many failed AJAX requests, showing the critical error page');
            }
            logger.error(error, 'Failed connection to external endpoin');
        }
    }
}
