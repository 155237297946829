import { runOnAppInit } from '@frontend/vanilla/core';

import { WidgetLayoutHook } from '../widget/core/widget-layout-hook';
import { HomeNavigationHighlightService } from './home-navigation-highlight.service';
import { WidgetRegistrationService } from './widget-registration.service';

export const provideHome = () => [
    runOnAppInit(WidgetRegistrationService),
    { provide: WidgetLayoutHook, useClass: HomeNavigationHighlightService, multi: true },
];
