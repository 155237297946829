import { Injectable } from '@angular/core';

import { IntroPagesConfig } from '@frontend/sports/common/client-config-data-access';
import { LocalStoreService } from '@frontend/vanilla/core';

import { CookieProvider } from '../cookie-provider/cookie-provider.service';
import { ModalDialogOptions } from '../modal/dialog/modal-dialog.model';
import { ModalDialogService } from '../modal/dialog/modal-dialog.service';
import { IntroComponentData } from './intro.models';

@Injectable({ providedIn: 'root' })
export class IntroPagesService {
    private daysToKeepCookie = 120;

    constructor(
        private introPages: IntroPagesConfig,
        private cookieProvider: CookieProvider,
        private localStore: LocalStoreService,
        private modalDialogService: ModalDialogService,
    ) {}

    async show(): Promise<void> {
        if (!this.introPages || !this.introPages.slides || !this.introPages.slides.length) {
            return;
        }

        if (this.storedPageId === this.introPages.id) {
            return;
        }

        const options: ModalDialogOptions<IntroComponentData> = {
            data: {
                contentData: this.introPages.slides,
            },
            settings: {
                cssClass: 'intro-pages',
                fit: false,
                showDialogHeader: true,
            },
        };

        this.storeIntroPageId();
        this.modalDialogService.openDialog(await import('./intro.component').then((c) => c.IntroComponent), options);
    }

    private get storedPageId(): string {
        return this.cookieProvider.getCookie(this.introPages.cookieName) || this.localStore.get(this.introPages.cookieName) || '';
    }

    private storeIntroPageId(): void {
        this.localStore.set(this.introPages.cookieName, this.introPages.id);
        this.cookieProvider.setCookie(this.introPages.cookieName, this.introPages.id, {
            expires: this.daysToKeepCookie,
        });
    }
}
