import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { PopupManager } from '../popup/popup-manager.service';
import { UserService } from '../user/services/user.service';

@Injectable()
export class SessionExpirationInterceptor implements HttpInterceptor {
    constructor(private injector: Injector) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap(
                (event) => {},
                (error) => {
                    if (error instanceof HttpErrorResponse) {
                        this.responseError(error);
                    }
                },
            ),
        );
    }

    responseError(error: HttpErrorResponse): HttpErrorResponse | undefined {
        const user = this.injector.get(UserService);
        if (!user.isAuthenticated) {
            return error;
        }

        if (error.status === 401 || error.status === 403) {
            const popupManager = this.injector.get(PopupManager);
            popupManager.setFullReloadRequired();
        }

        return undefined;
    }
}
