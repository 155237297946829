import { SportConstant } from '@frontend/sports/common/base-utils';
import { createReducer, on } from '@ngrx/store';
import { Draft, produce } from 'immer';
import { assign, pick } from 'lodash-es';

import { EventDetailsColumnType } from '../event-model/helpers/event-media-helper.service';
import { EventModel } from '../event-model/model/event.model';
import { MediaApiActions } from '../media-api/actions';
import { MediaEventSource, MediaModuleContextPage, MediaSetEventInternal, RestoreStatePayload } from '../media-api/model';
import { Actions } from './actions';
import { EventInfo, MediaState, mediaInitialState } from './media.state';

export const mediaReducer = createReducer(
    mediaInitialState,
    on(Actions.updateScoreboard, (state, action) => updateScoreboard(state, action.model)),
    on(Actions.toggleMediaModule, (state, action) =>
        produce(state, (mediaDraft) => {
            mediaDraft.active = action.value;
        }),
    ),
    on(Actions.setActiveTab, (state, action) =>
        produce(state, (mediaDraft) => {
            mediaDraft.activeTab = action.tab.tabId;
            mediaDraft.video.trackingSource = action.tab.trackingSource;
        }),
    ),
    on(Actions.togglePin, (state, action) => togglePin(state, action.value)),
    on(Actions.toggleExpand, (state, action) =>
        produce(state, (mediaDraft) => {
            mediaDraft.mediaExpandState.isExpanded = !mediaDraft.mediaExpandState.isExpanded;
            mediaDraft.mediaExpandState.restoreExpandState = action.value;
        }),
    ),
    on(Actions.setAvailableExpandLocation, (state, action) =>
        produce(state, (mediaDraft) => {
            mediaDraft.mediaExpandState.isLocationAllowsExpand = action.value;
        }),
    ),
    on(Actions.setVideoSwitcherContent, (state, action) =>
        produce(state, (mediaDraft) => {
            mediaDraft.video.dropdownEvents = action.content;
        }),
    ),
    on(MediaApiActions.setMediaWidgetContext, (state, action) =>
        produce(state, (mediaDraft) => {
            mediaDraft.context.page = action.payload.page;
            mediaDraft.context.eventId = action.payload.eventId;
        }),
    ),
    on(Actions.toggleAutoplay, (state, action) =>
        produce(state, (mediaDraft) => {
            mediaDraft.video.autoplay = action.value;
            mediaDraft.video.playing = action.value;
        }),
    ),
    on(MediaApiActions.togglePlaying, (state, action) =>
        produce(state, (mediaDraft) => {
            mediaDraft.video.playing = action.value;
        }),
    ),
    on(Actions.setEventInternal, (state, action) => setEventInternal(state, action.payload)),
    on(Actions.toggleUnpinInfoMessage, (state, action) =>
        produce(state, (mediaDraft) => {
            mediaDraft.video.unpinInfoVisible = action.value;
        }),
    ),
    on(Actions.toggleCollapsedState, (state, action) =>
        produce(state, (mediaDraft) => {
            mediaDraft.isCollapsed = action.value;
        }),
    ),
    on(Actions.pinEnabledStateToggle, (state, action) =>
        produce(state, (mediaDraft) => {
            mediaDraft.video.pinEnabled = action.value;
        }),
    ),
    on(Actions.unsetEvent, (state, action) => unsetEvent(state, action.columnType)),
    on(Actions.restoreState, (state, action) => restoreState(state, action.payload)),
    on(Actions.resetState, (state, action) => resetState(state, action.value)),
    on(Actions.populateFromStorage, (state, action) =>
        produce(state, (mediaDraft) => {
            mediaDraft.mediaExpandState.isExpanded = action.state.mediaExpandState.isExpanded;
            mediaDraft.mediaExpandState.restoreExpandState = action.state.mediaExpandState.restoreExpandState;
        }),
    ),
);

function restoreState(media: MediaState, payload: RestoreStatePayload): MediaState {
    let hasActiveTab = false;

    return produce(media, (mediaDraft) => {
        if (!!payload.video.eventId && payload.events[payload.video.eventId]) {
            mediaDraft.video.pinned = mediaDraft.video.pinEnabled && payload.video.pinned;
            mediaDraft.video.eventId = payload.video.eventId;
            hasActiveTab = payload.activeTab === EventDetailsColumnType.Video;
            addEvent(mediaDraft, payload.events[payload.video.eventId]);
        }

        if (
            !!payload.animation.eventId &&
            payload.events[payload.animation.eventId] &&
            mediaDraft.context.page !== MediaModuleContextPage.EventDetails
        ) {
            mediaDraft.animation.eventId = payload.animation.eventId;
            hasActiveTab = hasActiveTab || payload.activeTab === EventDetailsColumnType.Animation;
            addEvent(mediaDraft, payload.events[payload.animation.eventId]);
        }

        if (
            !!payload.statistics.eventId &&
            payload.events[payload.statistics.eventId] &&
            mediaDraft.context.page !== MediaModuleContextPage.EventDetails
        ) {
            mediaDraft.statistics.eventId = payload.statistics.eventId;
            hasActiveTab = hasActiveTab || payload.activeTab === EventDetailsColumnType.Stats;
            addEvent(mediaDraft, payload.events[payload.statistics.eventId]);
        }

        mediaDraft.activeTab = hasActiveTab ? payload.activeTab : getActiveTabWithFallback(mediaDraft);

        mediaDraft.eventInfos = getActiveEvents(mediaDraft);
    });
}

function updateScoreboard(media: MediaState, event: EventModel): MediaState {
    return produce(media, (mediaDraft) => {
        if (mediaDraft.eventInfos[event.id]) {
            mediaDraft.eventInfos[event.id].event.scoreboard = event.scoreboard;
        }
    });
}

function togglePin(media: MediaState, pinned: boolean): MediaState {
    return produce(media, (mediaDraft) => {
        if (!mediaDraft.video.pinEnabled) {
            return;
        }

        mediaDraft.video.pinned = pinned;
        const previousVideoEventId = mediaDraft.video.eventId;

        if (!pinned) {
            const switchToEventId =
                mediaDraft.context.eventId || mediaDraft.animation.eventId || mediaDraft.statistics.eventId || mediaDraft.video.eventId;
            mediaDraft.video.eventId = switchToEventId && mediaDraft.eventInfos[switchToEventId].hasVideo ? switchToEventId : null;

            mediaDraft.activeTab =
                mediaDraft.context.page === MediaModuleContextPage.EventDetails ? getActiveTabWithFallback(mediaDraft) : mediaDraft.activeTab;

            if (mediaDraft.activeTab !== media.activeTab || mediaDraft.video.eventId === null) {
                mediaDraft.video.playing = false;
            }

            mediaDraft.video.autoplay =
                mediaDraft.context.page === MediaModuleContextPage.EventDetails || mediaDraft.video.eventId !== previousVideoEventId
                    ? mediaDraft.video.autoplay
                    : !mediaDraft.video.eventId;
        }

        mediaDraft.eventInfos = getActiveEvents(mediaDraft);
    });
}

function setEventInternal(media: MediaState, payload: MediaSetEventInternal): MediaState {
    return produce(media, (mediaDraft) => {
        switch (payload.source) {
            case MediaEventSource.EventSwitcher:
                updateEventForEventSwitcher(mediaDraft, payload);
                break;
            case MediaEventSource.Grid:
            case MediaEventSource.Scoreboard:
                updateEventForGrid(mediaDraft, payload);
                break;
            case MediaEventSource.EventDetails:
                updateEventForEventDetails(mediaDraft, payload);
                break;
        }

        addEvent(mediaDraft, payload);
        mediaDraft.eventInfos = getActiveEvents(mediaDraft);
    });
}

function resetState(media: MediaState, resetPin?: boolean): MediaState {
    return produce(media, (mediaDraft) => {
        if (!!resetPin || !mediaDraft.video.pinned) {
            mediaDraft.video.eventId = null;
            mediaDraft.video.pinned = false;
            mediaDraft.video.unpinInfoVisible = false;
            mediaDraft.video.autoplay = false;
        }
        mediaDraft.animation.eventId = null;
        mediaDraft.statistics.eventId = null;
        mediaDraft.activeTab = EventDetailsColumnType.Video;
        mediaDraft.eventInfos = getActiveEvents(mediaDraft);
    });
}

function updateEventForEventSwitcher(mediaDraft: Draft<MediaState>, payload: MediaSetEventInternal): void {
    mediaDraft.video.pinned =
        mediaDraft.video.pinEnabled &&
        payload.hasVideo &&
        mediaDraft.context.page === MediaModuleContextPage.EventDetails &&
        payload.event.id !== mediaDraft.context.eventId;
    mediaDraft.video.eventId = payload.hasVideo ? payload.event.id : null;
    mediaDraft.video.autoplay = payload.hasVideo;
    mediaDraft.video.trackingSource = payload.trackingVideoSource;

    if (mediaDraft.context.page !== MediaModuleContextPage.EventDetails) {
        mediaDraft.animation.eventId = payload.hasAnimation ? payload.event.id : null;
        mediaDraft.statistics.eventId = payload.hasStats || hasBetradarId(payload) ? payload.event.id : null;
    }
}

function updateEventForEventDetails(mediaDraft: Draft<MediaState>, payload: MediaSetEventInternal): void {
    if (!mediaDraft.video.pinned) {
        mediaDraft.video.eventId = payload.hasVideo ? payload.event.id : null;
        mediaDraft.video.pinned = false;
        mediaDraft.video.autoplay = false;
    }

    mediaDraft.animation.eventId = payload.hasAnimation ? payload.event.id : null;
    mediaDraft.statistics.eventId = payload.hasStats || hasBetradarId(payload) ? payload.event.id : null;

    mediaDraft.activeTab = getActiveTabWithFallback(mediaDraft);
}

function updateEventForGrid(mediaDraft: Draft<MediaState>, payload: MediaSetEventInternal): void {
    if (payload.tab === EventDetailsColumnType.Video || !mediaDraft.video.pinned) {
        mediaDraft.video.eventId = payload.hasVideo ? payload.event.id : null;
        mediaDraft.video.pinned = false;
        mediaDraft.video.autoplay = payload.tab === EventDetailsColumnType.Video && payload.hasVideo;
        mediaDraft.video.trackingSource = payload.trackingVideoSource;
    }

    mediaDraft.animation.eventId = payload.hasAnimation ? payload.event.id : null;
    mediaDraft.statistics.eventId = payload.hasStats || hasBetradarId(payload) ? payload.event.id : null;
    mediaDraft.activeTab = payload.tab || EventDetailsColumnType.Video;
}

function getActiveEvents(mediaState: Draft<MediaState>): Draft<{ [eventId: string]: EventInfo }> {
    return pick(mediaState.eventInfos, [
        mediaState.video.eventId || '',
        mediaState.animation.eventId || '',
        mediaState.statistics.eventId || '',
        mediaState.context.eventId || '',
    ]);
}

function unsetEvent(media: MediaState, eventDetailsColumnType: EventDetailsColumnType): MediaState {
    return produce(media, (mediaDraft) => {
        switch (eventDetailsColumnType) {
            case EventDetailsColumnType.Video:
                mediaDraft.video.eventId = null;
                mediaDraft.video.pinned = false;
                mediaDraft.video.unpinInfoVisible = false;
                mediaDraft.video.autoplay = false;
                break;
            case EventDetailsColumnType.Animation:
                mediaDraft.animation.eventId = null;
                break;
            case EventDetailsColumnType.Stats:
                mediaDraft.statistics.eventId = null;
                break;
        }

        mediaDraft.eventInfos = getActiveEvents(mediaDraft);
    });
}

function cloneEvent(eventModel: EventModel): Draft<EventModel> {
    return assign(new EventModel(), eventModel, {
        optionSets: [],
        optionGroups: [],
        optionSetId: undefined,
    }) as any;
}

const tabsAvailabilityByEventId = [
    { isAvailable: (mediaState: Draft<MediaState>) => mediaState.video.eventId !== null, tab: EventDetailsColumnType.Video },
    { isAvailable: (mediaState: Draft<MediaState>) => mediaState.animation.eventId !== null, tab: EventDetailsColumnType.Animation },
    { isAvailable: (mediaState: Draft<MediaState>) => mediaState.statistics.eventId !== null, tab: EventDetailsColumnType.Stats },
    { isAvailable: (mediaState: Draft<MediaState>) => true, tab: EventDetailsColumnType.Video },
];

function getActiveTabWithFallback(mediaDraft: Draft<MediaState>): EventDetailsColumnType {
    return tabsAvailabilityByEventId.find((tabInfo) => tabInfo.isAvailable(mediaDraft))!.tab;
}

function addEvent(mediaDraft: Draft<MediaState>, mediaSetEventInternal: MediaSetEventInternal): void {
    mediaDraft.eventInfos[mediaSetEventInternal.event.id] = {
        hasVideo: mediaSetEventInternal.hasVideo,
        hasAnimation: mediaSetEventInternal.hasAnimation,
        hasStats: mediaSetEventInternal.hasStats,
        event: cloneEvent(mediaSetEventInternal.event),
    };
}

function hasBetradarId(payload: MediaSetEventInternal): boolean {
    return !!(payload && payload.event && payload.event.statsId && payload.showStatsCenterButton && payload.event.sport.id === SportConstant.Soccer);
}
