import { StaticProvider } from '@angular/core';

import { WidgetType } from '@frontend/sports/types/components/widget';
import { of } from 'rxjs';

import { WIDGET_SKELETON } from '../widget/core/widget-skeleton.model';
import { MarqueeWidgetSkeletonComponent } from './marquee-widget-skeleton.component';

export function provideMarqueeWidgetSkeleton(): StaticProvider {
    return {
        provide: WIDGET_SKELETON,
        useValue: {
            type: WidgetType.Marquee,
            component: MarqueeWidgetSkeletonComponent,
            config: () => {
                return of({ visible: true });
            },
        },
        multi: true,
    };
}
