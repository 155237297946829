import { inject } from '@angular/core';

import { VANILLA_LAZY_ROUTES } from '@frontend/loaders/routes';
import { RouteTag, SportConstant } from '@frontend/sports/common/base-utils';
import { HtmlNode } from '@frontend/vanilla/core';

import { gameLauncherResolver } from './banner/game-launcher.resolver';
import { CalendarBreadcrumbsResolver } from './calendar/calendar-breadcrumbs.resolver';
import { CalendarResolver } from './calendar/calendar.resolver';
import { EmptyComponent } from './common/empty.component';
import { ESportBreadcrumbsResolver } from './esports/esports-breadcrumbs.resolver';
import { SportListOverviewResolver } from './event-list-shared/sport/overview/sport-list-overview.resolver';
import { SportRedirectGuard } from './event-list-shared/sport/sport-redirect.guard';
import { SportSubNavigationResolve } from './event-list-shared/sport/sport-sub-navigation.resolve';
import { StandingsPageResolver } from './event-list-shared/sport/standings-page.resolver';
import { HomePageMarqueeComponent } from './home-page-marquee/home-page-marquee.component';
import { HomePageMarqueeResolver } from './home-page-marquee/home-page-marquee.resolver';
import { HomeBreadcrumbsResolver } from './home/home-breadcrumbs.resolver';
import { GtmService } from './main/gtm.service';
import { MultiSportsBreadcrumbsResolver } from './multi-sports/multi-sports-breadcrumbs.resolver';
import { SportRoute } from './router/multi-route.model';
import { MultiSportsHubGuard } from './router/multi-sports-hub.guard';
import { multiNameIdentifierMatcher } from './router/name-identifier.matcher';
import { NoMatchRouteGuard } from './router/no-match-route.guard';
import { NotAuthenticatedGuard } from './router/not-authenticated-guard.service';
import { OutsideSportsGuard } from './router/outside-sports.guard';
import { regexMatcher } from './router/regex.matcher';
import { use } from './router/router.resolver';
import { SeoRedirectGuard } from './seo/seo-redirect.guard';
import { SitemapRoutingToken } from './sitemap/sitemap-routing';
import { WidgetSchemaMarkupResolver } from './widget/common/widget-schema-markup.resolver';
import { WidgetResolver } from './widget/common/widget.resolver';
import { WidgetLayoutComponent } from './widget/core/widget-layout.component';

export const routes: SportRoute[] = [
    {
        path: '',
        redirectTo: '{culture}/sports',
        pathMatch: 'full',
    },
    {
        path: '{culture}',
        providers: [GtmService],
        resolve: [() => inject(GtmService).renderGTMTag()],
        children: [
            ...VANILLA_LAZY_ROUTES.map((route) => ({
                ...route,
                canActivate: [
                    () => {
                        const htmlNode = inject(HtmlNode);
                        htmlNode.setCssClass('vanilla-route', true);
                    },
                ],
                canDeactivate: [
                    () => {
                        const htmlNode = inject(HtmlNode);
                        htmlNode.setCssClass('vanilla-route', false);
                    },
                ],
            })),
            {
                path: '',
                redirectTo: 'sports',
                pathMatch: 'full',
            },
            {
                path: 'news',
                children: [
                    {
                        path: '**',
                        component: EmptyComponent,
                        canActivate: [OutsideSportsGuard],
                    },
                ],
            },
            {
                path: 'marquee',
                data: {
                    tag: RouteTag.Lobby,
                },
                component: HomePageMarqueeComponent,
                resolve: use(HomePageMarqueeResolver),
            },
            {
                path: '{esports}',
                data: {
                    headerNavigation: true,
                    tag: RouteTag.EsportsLobby,
                },
                loadComponent: () => import(/* webpackChunkName: "ms-main-component" */ './main/main.component').then((m) => m.MainComponent),
                canActivateChild: [NotAuthenticatedGuard],
                runGuardsAndResolvers: 'paramsChange',
                children: [
                    {
                        path: '',
                        component: WidgetLayoutComponent,
                        resolve: use(WidgetResolver),
                        data: {
                            tag: RouteTag.EsportsLobby,
                            breadcrumbs: ESportBreadcrumbsResolver,
                            schemaMarkup: WidgetSchemaMarkupResolver,
                            sitemap: SitemapRoutingToken.EsportsSitemapResolver,
                        },
                    },
                ],
            },
            {
                path: 'sports',
                data: {
                    headerNavigation: true,
                    tag: RouteTag.Lobby,
                    sitemap: SitemapRoutingToken.SitemapHomeResolver,
                },
                loadComponent: () => import(/* webpackChunkName: "ms-main-component" */ './main/main.component').then((m) => m.MainComponent),
                canActivateChild: [NotAuthenticatedGuard],
                runGuardsAndResolvers: 'paramsChange',
                children: [
                    {
                        path: '',
                        component: WidgetLayoutComponent,
                        resolve: use(WidgetResolver),
                        data: {
                            tag: RouteTag.Lobby,
                            breadcrumbs: HomeBreadcrumbsResolver,
                            schemaMarkup: WidgetSchemaMarkupResolver,
                        },
                    },
                    {
                        path: 'p',
                        children: [
                            {
                                path: '**',
                                component: EmptyComponent,
                                canActivate: [OutsideSportsGuard],
                            },
                        ],
                    },
                    {
                        path: 'affiliate-account-linking',
                        loadChildren: () =>
                            import(/* webpackChunkName: "ms-link-external-accounts" */ './link-external-accounts/link-external-accounts.module').then(
                                (m) => m.LinkExternalAccountsModule,
                            ),
                        data: {
                            authorized: true,
                        },
                    },
                    {
                        path: '{multiSports}',
                        canActivateChild: [MultiSportsHubGuard],
                        children: [
                            {
                                path: '',
                                redirectTo: '{multiSportsHubName}',
                                pathMatch: 'full',
                            },
                            {
                                path: '{multiSportsHubName}',
                                component: WidgetLayoutComponent,
                                resolve: use(WidgetResolver),
                                data: {
                                    tag: RouteTag.MultiSportsLobby,
                                    breadcrumbs: MultiSportsBreadcrumbsResolver,
                                    headerNavigation: true,
                                    schemaMarkup: WidgetSchemaMarkupResolver,
                                    sitemap: SitemapRoutingToken.MultiSportsHubSitemapResolver,
                                },
                            },
                        ],
                    },
                    {
                        path: '{settings}',
                        loadChildren: () => import(/* webpackChunkName: "ms-settings" */ './settings/settings.module').then((m) => m.SettingsModule),
                    },
                    {
                        path: '{favourites}',
                        loadChildren: () =>
                            import(/* webpackChunkName: "ms-favourites-dashboard" */ './favourites-dashboard/favourites-dashboard.module').then(
                                (m) => m.FavouritesDashboardModule,
                            ),
                        data: { shouldKeepSubNav: true },
                    },
                    {
                        path: '{events}',
                        loadChildren: () =>
                            import(/* webpackChunkName: "ms-event-details"*/ './event-details/event-details.module').then(
                                (m) => m.EventDetailsModule,
                            ),
                    },
                    {
                        path: '{live}',
                        loadChildren: () =>
                            import(/* webpackChunkName: "ms-event-list-live" */ './event-list-live/live.module').then((m) => m.LiveModule),
                    },
                    {
                        path: '{virtual}',
                        loadChildren: () =>
                            import(/* webpackChunkName: "ms-virtual-list" */ './virtual-list/virtual-list.module').then((m) => m.VirtualListModule),
                    },
                    {
                        path: '{myBets}',
                        loadChildren: () => import(/* webpackChunkName: "ms-my-bets" */ './my-bets').then((m) => m.MyBetsModule),
                        data: { shouldKeepSubNav: true },
                    },
                    {
                        path: '{all}',
                        data: {
                            tag: RouteTag.SportList,
                        },
                        loadComponent: () =>
                            import(/* webpackChunkName: "ms-sport-list-overview" */ './event-list/sport/overview/sport-list-overview.component').then(
                                (c) => c.SportListOverviewComponent,
                            ),
                        resolve: use(SportListOverviewResolver),
                    },
                    {
                        path: '{games}/:name/:section',
                        children: [
                            {
                                path: '',
                                loadComponent: () => import('./banner/game-launcher.component').then((m) => m.GameLauncherComponent),
                                data: { authorized: true, headerNavigation: false },
                                resolve: { model: gameLauncherResolver },
                            },
                            {
                                path: ':position/:title/:provider/:lobbyPosition',
                                loadComponent: () => import('./banner/game-launcher.component').then((m) => m.GameLauncherComponent),
                                data: { authorized: true, headerNavigation: false },
                                resolve: { model: gameLauncherResolver },
                            },
                        ],
                    },
                    {
                        matcher: regexMatcher,
                        data: {
                            matcherPattern: '^((.*)-)?(\\d+)/{fixtures}/((.*)-)?(\\d:\\d+)$',
                            matcherMode: 'full',
                        },
                        loadChildren: () => import(/* webpackChunkName: "ms-racing" */ './racing/racing.module').then((m) => m.RacingModule),
                    },
                    {
                        matcher: regexMatcher,
                        data: {
                            matcherPattern: `^((.*)-)?(${SportConstant.Golf})/{eachWay}$`,
                            matcherMode: 'full',
                        },
                        loadChildren: () => import(/* webpackChunkName: "ms-racing" */ './racing/racing.module').then((m) => m.RacingModule),
                    },
                    {
                        matcher: regexMatcher,
                        data: {
                            matcherPattern: `^((.*)-)?(${SportConstant.Horses})$`,
                        },
                        loadChildren: () => import(/* webpackChunkName: "ms-racing" */ './racing/racing.module').then((m) => m.RacingModule),
                    },
                    {
                        matcher: regexMatcher,
                        data: {
                            matcherPattern: `^((.*)-)?(${SportConstant.Greyhounds})$`,
                        },
                        loadChildren: () => import(/* webpackChunkName: "ms-racing" */ './racing/racing.module').then((m) => m.RacingModule),
                    },
                    // sport routes
                    {
                        matcher: regexMatcher,
                        canActivateChild: [SportRedirectGuard],
                        data: {
                            matcherPattern: '^((.*)-)?(\\d+)$',
                            matcherMapping: {
                                sportName: 2,
                                sport: 3,
                            },
                            subNavigation: SportSubNavigationResolve,
                            sitemap: SitemapRoutingToken.SportSitemapResolver,
                        },
                        loadChildren: () =>
                            import(/* webpackChunkName: "ms-sport-event-list" */ './event-list/event-list.module').then((m) => m.EventListModule),
                    },
                    {
                        path: '{calendar}',
                        loadComponent: () =>
                            import(/* webpackChunkName: "ms-sport-calendar" */ './calendar/calendar.component').then((m) => m.CalendarComponent),
                        resolve: use(CalendarResolver),
                        data: {
                            breadcrumbs: CalendarBreadcrumbsResolver,
                            sitemap: SitemapRoutingToken.CalendarSitemapResolver,
                        },
                    },
                    {
                        matcher: regexMatcher,
                        data: {
                            matcherPattern: '^({in30minutes}|{in60minutes}|{in180minutes}|{today}|{tomorrow}|{after2days}|{after3days})$',
                            matcherMapping: {
                                context: 1,
                            },
                            subNavigationHiddenOnSmallScreen: true,
                            breadcrumbs: CalendarBreadcrumbsResolver,
                            sitemap: SitemapRoutingToken.CalendarSitemapResolver,
                        },
                        loadComponent: () =>
                            import(/* webpackChunkName: "ms-sport-list-overview" */ './event-list/sport/overview/sport-list-overview.component').then(
                                (c) => c.SportListOverviewComponent,
                            ),
                        resolve: use(SportListOverviewResolver),
                    },
                    {
                        path: '{multiBuilder}',
                        loadChildren: () =>
                            import(/* webpackChunkName: "ms-multi-builder"*/ './multi-builder/multi-builder.module').then(
                                (m) => m.MultiBuilderModule,
                            ),
                        data: { shouldKeepSubNav: true },
                    },
                    {
                        path: '{standings}',
                        children: [
                            {
                                path: '',
                                component: EmptyComponent,
                            },
                            {
                                matcher: multiNameIdentifierMatcher,
                                data: {
                                    matcherParams: ['sport', 'region', 'league', 'group'],
                                    sitemap: SitemapRoutingToken.StandingsSitemapResolver,
                                },
                                loadComponent: () =>
                                    import(/* webpackChunkName: "ms-standing-page" */ './event-list/sport/standings-page.component').then(
                                        (c) => c.StandingsPageComponent,
                                    ),
                                resolve: use(StandingsPageResolver),
                            },
                        ],
                    },
                    {
                        path: '{bets}',
                        loadChildren: () =>
                            import(/* webpackChunkName: "ms-sport-event-list" */ './event-list/event-list.module').then((m) => m.FixtureListModule),
                    },
                ],
            },
        ],
    },
    {
        path: '**',
        component: EmptyComponent,
        canActivate: [NoMatchRouteGuard, SeoRedirectGuard],
    },
];
