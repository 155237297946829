import { Inject, Injectable } from '@angular/core';

import { BetslipConfig, PopupsConfig, Sitecore } from '@frontend/sports/common/client-config-data-access';
import { DeviceService, OnAppInit } from '@frontend/vanilla/core';
import { Store } from '@ngrx/store';
import IEpcotConfigService from 'packages/sports/common/betslip/integration/services/epcot-config.service';
import { EPCOT_CONFIG_INJECTION_TOKEN } from 'packages/sports/common/betslip/integration/sports-injection-services';
import { BetslipResultActions } from 'packages/sports/common/betslip/modules/result/actions';
import DigitalSettingsActions from 'packages/sports/common/betslip/modules/settings/digital-actions';

import { BetslipDigitalComponentLoaderService } from '../betslip/betslip-digital-component-loader.service';
import { EpcotModule } from '../common/epcot-config.service';
import { ScrollFreezeServiceTypes } from '../common/scroll-freeze.service';
import { BetfinderComponentLoaderService } from '../deferred/betfinder-component-loader.service';
import { EventSwitcherComponentLoaderService } from '../deferred/event-switcher-component-loader.service';
import { MyBetsComponentLoaderService } from '../deferred/my-bets-component-loader.service';
import { DialogAnimation } from '../modal/dialog/modal-dialog.model';
import { ExternalNativeAppService } from '../native-api/external-native-app.service';
import { PopupManager } from '../popup/popup-manager.service';

@Injectable({
    providedIn: 'root',
})
export class PopupInitializationService implements OnAppInit {
    constructor(
        private deviceService: DeviceService,
        private popupManager: PopupManager,
        private popupConfig: PopupsConfig,
        private sitecore: Sitecore,
        private externalNativeAppService: ExternalNativeAppService,
        private betslipLoader: BetslipDigitalComponentLoaderService,
        private myBetsLoader: MyBetsComponentLoaderService,
        private betfinderComponentLoaderService: BetfinderComponentLoaderService,
        private betslipConfig: BetslipConfig,
        private eventSwitcherLoader: EventSwitcherComponentLoaderService,
        private store: Store,
        @Inject(EPCOT_CONFIG_INJECTION_TOKEN) private epcotConfigService: IEpcotConfigService,
    ) {}

    async onAppInit(): Promise<void> {
        const epcotClass = this.epcotConfigService.isEnabled(EpcotModule.Betslip) ? 'epcot-enabled' : '';

        const betslipComponent = this.betslipLoader.getBetslipComponent();
        betslipComponent.update({ inColumn: false });

        await Promise.all([
            import('../bet-generator-shared/modal/bet-generator-shared-modal.component').then((c) => {
                this.popupManager.configure('bet-generator', c.BetGeneratorSharedModalComponent, {
                    settings: {
                        cssClass: 'modal-popup bet-generator-modal',
                        showPageHeader: true,
                        showDialogHeader: true,
                        title: this.sitecore.multiRandomGenerator.Title,
                        fit: false,
                        hideCloseButton: false,
                        animation: this.getAnimation('betslip'),
                        isModalDialog: true,
                    },
                });
            }),
            import('../event-details/bet-builder-v2/market-list/betbuilder-market-list.component').then((c) => {
                this.popupManager.configure('bet-builder-overlay', c.BetbuilderMarketListComponent, {
                    settings: {
                        backdropClass: 'betbuilder-overlay-backdrop',
                        showDialogHeader: true,
                        animation: DialogAnimation.SlideInFromBottom,
                    },
                });
            }),
            import(/* webpackChunkName: "ms-sport-list-modal" */ '../event-list/sport/overview/sport-list-modal.component').then((c) => {
                this.popupManager.configure('grouped-sports', c.SportListModalComponent, {
                    settings: {
                        cssClass: 'modal-popup sports',
                        showPageHeader: false,
                        showDialogHeader: true,
                        title: this.sitecore.event.AZsports,
                        hideCloseButton: false,
                        isModalDialog: true,
                    },
                });

                this.popupManager.configure('sports', c.SportListModalComponent, {
                    settings: {
                        cssClass: 'az-menu',
                        showPageHeader: false,
                        showDialogHeader: false,
                        title: this.sitecore.event.AZsports,
                        hideCloseButton: false,
                        isModalDialog: true,
                        closeOnChildPopup: true,
                        backdropClass: 'az-desktop-backdrop',
                        scrollFreezeType: ScrollFreezeServiceTypes.All,
                    },
                });
            }),
            import('../archived-bets/archived-bets-modal.component').then((c) => {
                this.popupManager.configure('archived-bets', c.ArchivedBetsModalComponent, {
                    settings: {
                        cssClass: 'modal-popup sports',
                        showDialogHeader: true,
                        title: this.sitecore.betslip.archivedBetsHelpBox.HeaderLine,
                        hideCloseButton: false,
                        isModalDialog: true,
                    },
                });
                this.popupManager.configure('transaction-bets', c.ArchivedBetsModalComponent, {
                    data: {
                        showTransactionBetsDialog: true,
                    },
                    settings: {
                        cssClass: 'modal-popup sports',
                        showDialogHeader: true,
                        title: this.sitecore.betslip.transactionBetsHelpBox.HeaderLine,
                        hideCloseButton: false,
                        isModalDialog: true,
                    },
                });
            }),
            import('../ngrx-reports/components/ngrx-reports.component').then((c) => {
                this.popupManager.configure('ngrx-reports', c.NgrxReportsComponent, {
                    settings: {
                        cssClass: 'modal-popup sports',
                        showDialogHeader: true,
                        title: this.sitecore.ngrxReports.messages.ReportsTitle,
                        hideCloseButton: false,
                        isModalDialog: true,
                    },
                });
            }),
            import('../popup/popup-proxy.component').then((c) => {
                this.popupManager.configure('betfinder', c.PopupProxyComponent, {
                    data: {
                        proxy: this.betfinderComponentLoaderService.getBetfinderComponent(),
                    },
                    settings: {
                        cssClass: 'modal-popup betfinder-modal',
                        fit: true,
                        showPageHeader: false,
                        animation: this.getAnimation('betfinder'),
                        showDialogHeader: this.epcotConfigService.isEnabled() && this.deviceService.isMobile ? false : true,
                        showBottomNav: true,
                        title: this.sitecore.betFinder.SearchTitle,
                        hideCloseButton: false,
                        isModalDialog: false,
                        closeAnimation:
                            this.epcotConfigService.isEnabled() && this.deviceService.isMobile ? DialogAnimation.SlideOutFromTop : undefined,
                    },
                });
                this.popupManager.configure('betslip', c.PopupProxyComponent, {
                    data: {
                        proxy: betslipComponent,
                    },
                    settings: {
                        cssClass: `modal-popup betslip-popup ${epcotClass}`,
                        showPageHeader: !this.externalNativeAppService.isPokerNativeApp() && !this.betslipConfig.isBalanceHeaderEnabled,
                        showDialogHeader: !this.betslipConfig.isBalanceHeaderEnabled,
                        title: this.sitecore.betslip.messages.Title,
                        hideCloseButton: false,
                        animation: this.getAnimation('betslip'),
                        isModalDialog: true,
                        epcotShowBalance: true,
                        backdropClass: 'hide-background-overlay',
                        scrollTargetQuerySelector: '.betslip-scroller',
                        closePrevious: true,
                        afterResulted: () => {
                            this.store.dispatch(BetslipResultActions.close());
                            this.store.dispatch(DigitalSettingsActions.closeSettings());
                        },
                    },
                });

                this.popupManager.configure('my-bets', c.PopupProxyComponent, {
                    data: {
                        proxy: this.myBetsLoader.getColumnViewComponent(),
                    },
                    settings: {
                        cssClass: 'modal-popup mybets-popup',
                        showPageHeader: true,
                        showDialogHeader: true,
                        fit: true,
                        title: this.sitecore.globalMessages.MyBetsTitle,
                        hideCloseButton: false,
                        animation: this.getAnimation('myBets'),
                        isModalDialog: true,
                        showBackNav: false,
                        closePrevious: false,
                        backdropClass: 'hide-background-overlay',
                        needsAuthentication: true,
                    },
                });

                this.popupManager.configure('event-switcher', c.PopupProxyComponent, {
                    data: {
                        proxy: this.eventSwitcherLoader.getEventSwitcherComponent(),
                    },
                    settings: {
                        cssClass: `modal-popup event-switcher-popup`,
                        showDialogHeader: true,
                        hideCloseButton: false,
                        animation: DialogAnimation.SlideInFromBottom,
                        scrollTargetQuerySelector: 'ms-event-switcher',
                    },
                });
            }),
        ]);
    }

    private getAnimation(popup: string): DialogAnimation {
        if (this.popupConfig.slidesFromBottom[popup]) {
            return DialogAnimation.SlideInFromBottom;
        }
        if (this.popupConfig.slidesFromRight[popup]) {
            return DialogAnimation.SlideInFromRight;
        }

        return DialogAnimation.SlideInFromBottom;
    }
}
