import { Provider, importProvidersFrom } from '@angular/core';

import { StakeConfig } from '@frontend/sports/common/client-config-data-access';
import { StoreModule } from '@ngrx/store';

import { UserService } from '../user/services/user.service';
import { NUMPAD_VALIDATION_PARAMETERS_TOKEN } from './model';
import { validationParamsFactory } from './numpad.module';
import { FieldStakeInputModeStrategy, StakeInputModeStrategy } from './stake-input-mode.strategy';
import { numpadFeatureKey } from './store/numpad.state';
import { numpadReducer } from './store/reducer';

export const provideNumpad = (): Provider => [
    { provide: StakeInputModeStrategy, useClass: FieldStakeInputModeStrategy },
    {
        provide: NUMPAD_VALIDATION_PARAMETERS_TOKEN,
        useFactory: validationParamsFactory,
        deps: [StakeConfig, UserService],
    },
    importProvidersFrom(StoreModule.forFeature(numpadFeatureKey, numpadReducer)),
];
