import { Injectable } from '@angular/core';

import { ISavedState, StateLoader, StateSaver } from '../store-persist/state-storage.model';
import { MediaState, mediaInitialState } from './media.state';

export interface IMediaStateStorage {
    isLocationAllowsExpand: boolean;
    restoreExpandState: boolean;
}

export interface IMediaSavedState extends ISavedState {
    media: IMediaStateStorage;
}

export function isMediaSavedState(savedState: ISavedState | undefined): savedState is IMediaSavedState {
    return !!savedState && 'media' in savedState;
}

@Injectable({
    providedIn: 'root',
})
export class MediaStateSaver implements StateSaver<MediaState, IMediaStateStorage> {
    save(state: MediaState): IMediaStateStorage {
        return {
            isLocationAllowsExpand: state.mediaExpandState.isLocationAllowsExpand,
            restoreExpandState: state.mediaExpandState.restoreExpandState,
        };
    }
}

@Injectable({
    providedIn: 'root',
})
export class MediaStateLoader implements StateLoader<IMediaStateStorage, MediaState> {
    load(state: IMediaStateStorage | undefined): MediaState {
        if (!state) {
            return { ...mediaInitialState };
        }

        return {
            ...mediaInitialState,
            mediaExpandState: {
                ...mediaInitialState.mediaExpandState,
                ...state,
            },
        };
    }
}
