import { Injectable } from '@angular/core';

import { DispatcherService } from '@frontend/sports/common/dispatcher-utils';
import { DeviceService, HtmlNode, OnAppInit, WindowRef } from '@frontend/vanilla/core';
import { Subscription } from 'rxjs';

import { ScrollContainerService } from '../common/scroll-container.service';

@Injectable({
    providedIn: 'root',
})
export class OrientaionChangeService implements OnAppInit {
    orientationSubscription: Subscription;

    constructor(
        private windowRef: WindowRef,
        private dispatcher: DispatcherService,
        private deviceService: DeviceService,
        private htmlNode: HtmlNode,
        private scrollContainer: ScrollContainerService,
    ) {}

    onAppInit(): void | Promise<any> {
        // to be remobed
        this.htmlNode.setCssClass('isTablet', this.deviceService.isTablet);
        this.orientationSubscription = this.deviceService.orientation.subscribe((orienation) => this.orientationHandler(orienation));
    }

    private get window(): Window {
        return this.windowRef.nativeWindow as Window;
    }

    private orientationHandler = (orientation: string): void => {
        let isLandscape = this.deviceService.currentOrientation === 'landscape';

        if (this.deviceService.isTablet && !this.deviceService.isiOS) {
            isLandscape = !(this.window.screen.availHeight > this.window.screen.availWidth);
        }

        this.htmlNode.setCssClass('landscape', isLandscape);

        if (isLandscape) {
            this.removeVirtualKeyboard();
        }

        this.window.setTimeout(() => {
            this.dispatcher.dispatch('ORIENTATION_CHANGED', { isLandscape });
        });
    };

    private removeVirtualKeyboard(): void {
        const inputs = this.window.document.getElementsByTagName('input');
        if (this.deviceService.isTablet) {
            if (inputs.length > 0) {
                inputs[0].blur();
            }

            this.scrollContainer.scrollTo(0, 0);
        }
    }
}
