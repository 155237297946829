import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { WindowRef } from '@frontend/vanilla/core';

import { PopupManager } from '../popup/popup-manager.service';

@Injectable({ providedIn: 'root' })
export class NotAuthenticatedGuard {
    constructor(
        private popupManager: PopupManager,
        private windowRef: WindowRef,
    ) {}

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.popupManager.fullReloadRequired) {
            // eslint-disable-next-line no-self-assign
            this.windowRef.nativeWindow.location.href = this.windowRef.nativeWindow.location.href;
        }

        return true;
    }
}
