import { Injectable } from '@angular/core';

import { GTMConfig } from '@frontend/sports/common/client-config-data-access';
import { TagManagerService, TimerService, WindowRef } from '@frontend/vanilla/core';
import { firstValueFrom } from 'rxjs';

import { RunAfterLcpService } from '../perf-cdk/run-after-lcp.service';
import { TrackingService } from '../tracking/tracking.service';

@Injectable()
export class GtmService {
    constructor(
        private tagManagerService: TagManagerService,
        private timerService: TimerService,
        private trackingService: TrackingService,
        private gtmConfig: GTMConfig,
        private lcpService: RunAfterLcpService,
        private windowReference: WindowRef,
    ) {}

    renderGTMTag(): void {
        if (this.gtmConfig?.enabled) {
            if (this.windowReference.nativeWindow.partytown) {
                this.loadGTM();
            } else {
                this.lcpService.runAfterLcp(() => {
                    this.timerService.scheduleIdleCallback(() => this.loadGTM(), this.gtmConfig.delay);
                });
            }
        }
    }

    private async loadGTM() {
        await Promise.all([firstValueFrom(this.tagManagerService.whenReady), firstValueFrom(this.trackingService.whenReady)]);

        this.tagManagerService.loadPartyTownGTM();

        this.trackingService.track('Vanilla_Portal_App_Loaded');
    }
}
