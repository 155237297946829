import { AsyncPipe, NgIf, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, inject } from '@angular/core';

import { MarqueeTile } from '@frontend/sports/types/components/content';
import { MarqueePayload } from '@frontend/sports/types/components/widget/types';
import { Observable, combineLatest, startWith } from 'rxjs';

import { WidgetSkeletonData, WidgetSkeletonModel } from '../widget/core/widget-skeleton.model';
import { MarqueeResponseMapperService } from './marquee-response-mapper.service';
import { MarqueeWidgetDataService } from './marquee-widget-data.service';

@Component({
    selector: 'ms-marquee-widget-skeleton',
    template: `
        <ng-container *ngIf="data$ | async as data">
            <ng-container *ngIf="data.showSkeleton">
                <ng-container *ngIf="data.tiles[0] as tile">
                    <div class="marquee-card marquee-card-with-img" *ngIf="tile.imageUrl as img; else withoutImage">
                        <img class="marquee-background-image" [ngSrc]="img" priority width="280" height="140" />
                    </div>
                    <ng-template #withoutImage>
                        <div class="marquee-card marquee-card-without-img"></div>
                    </ng-template>
                </ng-container>
            </ng-container>
        </ng-container>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [AsyncPipe, NgIf, NgOptimizedImage],
    standalone: true,
})
export class MarqueeWidgetSkeletonComponent implements WidgetSkeletonModel {
    private readonly marqueeDataService = inject(MarqueeWidgetDataService);
    readonly data = inject(WidgetSkeletonData<MarqueePayload>);
    private readonly responseMapper = inject(MarqueeResponseMapperService);
    private mappedTiles = this.responseMapper.mapFixtures(this.data.widget.payload?.fixtures, this.data.widget.payload?.tiles);

    readonly showSkeleton$ = this.data.widgetsBeforeLoaded$;

    readonly data$ = combineLatest({
        tiles: (
            this.marqueeDataService.combineWithPromotions(this.data.widget.payload, this.mappedTiles) as unknown as Observable<MarqueeTile[]>
        ).pipe(startWith(this.mappedTiles)),
        showSkeleton: this.showSkeleton$,
    });

    @HostBinding('class')
    get widgetClass(): string {
        return `widget-skeleton--marquee`;
    }
}
