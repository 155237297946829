import { Injectable } from '@angular/core';
import { HammerGestureConfig } from '@angular/platform-browser';

import { TouchMouseInput } from 'hammerjs';

// https://stackoverflow.com/questions/41017202/vertical-scroll-is-not-working-with-hammerjs-and-angular2
@Injectable()
export class HammerGestureConfigProvider extends HammerGestureConfig {
    override overrides = {
        pinch: { enable: false },
        rotate: { enable: false },
        swipe: {
            direction: Hammer.DIRECTION_HORIZONTAL,
        },
    };

    // https://github.com/hammerjs/hammer.js/issues/1240
    override options = {
        inputClass: TouchMouseInput,
    };
}
