import { EnvironmentProviders, importProvidersFrom } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { StateStorageModule } from '../store-persist/state-storage.module';
import { UiManagerEffects } from './ui-manager.epic';
import { uiStateReducer } from './ui-manager.reducer';
import { uiFeatureKey } from './ui-manager.state';
import { UiStateSaver } from './ui-state-storage';

export function provideUiManager(): EnvironmentProviders {
    return importProvidersFrom(
        StoreModule.forFeature(uiFeatureKey, uiStateReducer),
        EffectsModule.forFeature([UiManagerEffects]),
        StateStorageModule.forFeature(uiFeatureKey, UiStateSaver),
    );
}
