import { Injectable } from '@angular/core';

import { LocalStoreService } from '@frontend/vanilla/core';

import { MediaModuleContextPage } from '../media-api/model';
import { MediaState } from './media.state';

@Injectable()
export class UnpinInfoService {
    private static readonly mediaModuleUnpinInfoAcceptedKey = 'MediaModuleUnpinInfoAccepted';

    constructor(private localStoreService: LocalStoreService) {}

    shouldShowUnpinInfoMessage(mediaState: MediaState): boolean {
        return (
            mediaState.context.page === MediaModuleContextPage.EventDetails &&
            mediaState.video.pinned &&
            mediaState.video.eventId !== null &&
            mediaState.context.eventId !== null &&
            mediaState.video.eventId !== mediaState.context.eventId &&
            this.localStoreService.get(UnpinInfoService.mediaModuleUnpinInfoAcceptedKey) !== true
        );
    }

    acceptUnpinInfoMessage(): void {
        this.localStoreService.set(UnpinInfoService.mediaModuleUnpinInfoAcceptedKey, true);
    }
}
