import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { CountItem, SportConstant, getAllParams, isAntepost } from '@frontend/sports/common/base-utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SportsCacheService } from '../../client-caching/sports-cache.service';
import { RedirectHelperService } from '../../navigation-core/redirect-helper.service';
import { SportUrlParam } from '../../navigation-core/url-helper.service';

@Injectable({ providedIn: 'root' })
export class SportRedirectGuard {
    constructor(
        private cache: SportsCacheService,
        private redirectHelper: RedirectHelperService,
    ) {}

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
        if (route.firstChild) {
            return true;
        }

        const { sport, context } = getAllParams(route);
        const fallback = route.queryParams.fallback;

        return this.cache.getSport(Number(sport)).pipe(
            map((result) => {
                if (result) {
                    if (result.id === SportConstant.Golf) {
                        if (isAntepost(result)) {
                            this.redirectHelper.goToSport(result, SportUrlParam.Antepost);

                            return false;
                        }
                    }

                    if (!context && fallback) {
                        const [region, league]: CountItem[] = this.getFallback(result);

                        if (region) {
                            this.redirectHelper.goToSport(result, region, league);
                        } else {
                            this.redirectHelper.goToSport(result, SportUrlParam.Betting, SportUrlParam.Competitions);
                        }

                        return false;
                    }
                }

                return true;
            }),
        );
    }

    private getFallback(sport: CountItem): CountItem[] {
        const items = [];

        if (sport.children.length === 1) {
            items.push(sport.children[0]);

            if (sport.children[0].children.length === 1) {
                items.push(sport.children[0].children[0]);
            }
        }

        return items;
    }
}
