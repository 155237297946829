import { Injectable } from '@angular/core';

import { WidgetConfig } from '@frontend/sports/common/client-config-data-access';
import { WidgetPage } from '@frontend/sports/types/components/widget';

import { WidgetRefreshTrigger } from '../widget/core/widget-refresh.service';

@Injectable({ providedIn: 'root' })
export class EsportsRefreshTrigger extends WidgetRefreshTrigger {
    constructor(config: WidgetConfig) {
        super(config);
    }

    getPage(): WidgetPage {
        return WidgetPage.ESportsLobby;
    }
}
