import { Injectable } from '@angular/core';

import { RacingConfig } from '@frontend/sports/common/client-config-data-access';
import { BogVersion } from '@frontend/sports/types/configuration/client-configuration';

import { BestOddsGuaranteedService } from './best-odds-guaranteed.service';
import { BogTokenProviderService } from './bog-token-provider.service';
import { ClassicBestOddsGuaranteedService } from './classic-best-odds-guaranteed.service';
import { FixtureBestOddsGuaranteedTokenValidatorService } from './fixture-best-odds-guaranteed-token-validator.service';
import { TokenBasedBestOddsGuaranteedService } from './token-based-best-odds-guaranteed.service';

@Injectable({ providedIn: 'root' })
export class BestOddsGuaranteedFactoryService {
    constructor(
        private racingConfig: RacingConfig,
        private tokenProvider: BogTokenProviderService,
        private tokenValidator: FixtureBestOddsGuaranteedTokenValidatorService,
    ) {}

    create(): BestOddsGuaranteedService {
        switch (this.racingConfig.bogVersion) {
            case BogVersion.TokenBased:
                return new TokenBasedBestOddsGuaranteedService(this.tokenProvider, this.tokenValidator);
            default:
                return new ClassicBestOddsGuaranteedService(this.racingConfig);
        }
    }
}
