import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SportsApiVersionConfig } from '@frontend/sports/common/client-config-data-access';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ApiVersioningInterceptor implements HttpInterceptor {
    constructor(private sportsApiVersionConfig: SportsApiVersionConfig) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.isSportsApiRequest(req)) {
            return next.handle(req);
        }

        const versionedRequest = req.clone({
            headers: req.headers.set(this.sportsApiVersionConfig.sportsApiVersionHeaderName, this.sportsApiVersionConfig.sportsApiVersion),
        });

        return next.handle(versionedRequest).pipe(
            catchError((response) => {
                if (response instanceof HttpResponseBase) {
                    this.processHttpResponse(response);
                }

                return throwError(response);
            }),
        );
    }

    processHttpResponse(response: HttpResponseBase): HttpResponseBase {
        if (this.isCachedResponse(response)) {
            return response;
        }

        const newApiVersion = response?.headers?.get(this.sportsApiVersionConfig.sportsApiVersionHeaderName);
        if (newApiVersion && newApiVersion !== this.sportsApiVersionConfig.sportsApiVersion) {
            window.location.reload();
        }

        return response;
    }

    private isCachedResponse(response: HttpResponseBase): boolean {
        const cacheControlHeader = response.headers?.get('cache-control');

        return !!cacheControlHeader && cacheControlHeader !== 'no-cache';
    }

    private isSportsApiRequest(req: HttpRequest<any>): boolean {
        return req.url.includes('/sports/api/');
    }
}
