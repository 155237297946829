import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { CountItem } from '@frontend/sports/common/base-utils';
import { Observable } from 'rxjs';

import { CalendarService } from '../../../calendar/calendar.service';
import { EventListService } from '../services/event-list.service';

@Injectable({ providedIn: 'root' })
export class SportListOverviewResolver {
    constructor(
        private service: EventListService,
        private calendar: CalendarService,
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CountItem[]> {
        // needed for betstation - there we have in-30-minutes
        const context = (route.params.context || '').replace(/-/gi, '');

        const interval = this.calendar.getTimeInterval(context) || {};

        return this.service.getSportList(interval.from, interval.to);
    }
}
