import { Injectable } from '@angular/core';

import { Sitecore } from '@frontend/sports/common/client-config-data-access';
import { NativeAppService, OnAppInit } from '@frontend/vanilla/core';

import { LoggerFactory } from '../logging/logger-factory.service';
import { SportsRemoteLogger } from '../logging/sports-remote-logger.service';
import { RedirectHelperService } from '../navigation-core/redirect-helper.service';
import { NativeEventType } from './model';

@Injectable({
    providedIn: 'root',
})
export class NativeEventSubscription implements OnAppInit {
    private readonly logger: SportsRemoteLogger;

    constructor(
        private nativeAppService: NativeAppService,
        private redirecHelper: RedirectHelperService,
        loggerFactory: LoggerFactory,
        private sitecore: Sitecore,
    ) {
        this.logger = loggerFactory.getLogger('NativeEventSubscription');
    }

    onAppInit(): void {
        if (!this.nativeAppService.isNativeWrapper) {
            return;
        }

        this.nativeAppService.eventsFromNative.subscribe((event) => {
            try {
                switch (event.eventName.toLowerCase()) {
                    case NativeEventType.LiveAlertScreenNavigation.toLowerCase():
                        return this.redirecHelper.goToEvent({ id: this.getEventId(event.parameters), name: '' });
                    case NativeEventType.BetScreenNavigation.toLowerCase():
                    case NativeEventType.MyBetsNavigation.toLowerCase():
                        return this.redirecHelper.goToMyBets(undefined, event.parameters);
                    case NativeEventType.OpenFreeBets.toLowerCase():
                        return this.redirecHelper.goToPage(this.sitecore.crmTokens.links.promotions.url);
                }
            } catch (error) {
                this.logger.error(error, 'Error occured while subscribing to native events.');
            }
        });
    }

    /**
     * @ngdoc function
     * @name getEventId
     * @methodOf NativeEventSubscription
     * @description Fetch event id from CCB event parameters (me : mainEventId, e : eventId, le : liveEventId, t : scoreId)
     * @private
     */
    private getEventId(parameters: any): any {
        if (parameters.t === '1' || parameters.t === '2') {
            return parameters.me;
        }

        if (parameters.le !== undefined) {
            return parameters.le;
        }

        return parameters.e;
    }
}
