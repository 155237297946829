import { Injectable } from '@angular/core';

import { WidgetType } from '@frontend/sports/types/components/widget';
import { OnAppInit } from '@frontend/vanilla/core';

import { WidgetRegistryService } from '../../widget/core/widget-registry.service';
import { CompetitionHeaderWidgetComponent } from './competition-header-widget.component';
import { CompetitionTeamsWidgetComponent } from './competition-teams-widget.component';

@Injectable({ providedIn: 'root' })
export class WidgetRegistrationService implements OnAppInit {
    constructor(private widgetRegistry: WidgetRegistryService) {}

    onAppInit(): void {
        this.widgetRegistry.register(WidgetType.CompetitionHeader, CompetitionHeaderWidgetComponent);
        this.widgetRegistry.register(WidgetType.CompetitionTeams, CompetitionTeamsWidgetComponent);
    }
}
