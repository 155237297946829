import { Injectable, Injector } from '@angular/core';

import { BaseLoader, LoadOptions, Loader } from '@frontend/loaders';
import { EpcotConfig } from '@frontend/sports/common/client-config-data-access';

@Injectable({ providedIn: 'root' })
export class HighlightsMarqueeLoader extends BaseLoader implements Loader {
    private readonly styleSheet = 'epcot-marquee';
    constructor(
        injector: Injector,
        private epcotConfig: EpcotConfig,
    ) {
        super('highlights-marquee', injector);
    }

    async load(options: LoadOptions): Promise<void> {
        if (this.epcotConfig.isEnabled) {
            options.styles = options.styles ?? [];
            options.styles = [...options.styles, { id: this.styleSheet, type: 'theme' }];
        }
        await this.loadFeature(import(/* webpackChunkName: "ms-highlights-marquee" */ './highlights-marquee.feature'), options);
    }
}
