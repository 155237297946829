import { Component } from '@angular/core';

import { HooksWireup, OnDestroyCleanup } from '@frontend/sports/common/base-utils';
import { Sitecore } from '@frontend/sports/common/client-config-data-access';
import { takeUntil } from 'rxjs/operators';

import { RedirectHelperService } from '../navigation-core/redirect-helper.service';
import { CriticalErrorService } from './critical-error.service';

@HooksWireup()
@Component({
    selector: 'ms-critical-error',
    templateUrl: 'critical-error.html',
    standalone: true,
})
export class CriticalErrorComponent extends OnDestroyCleanup {
    showCriticalError: boolean;
    criticalError: { header: string; text: string };

    constructor(
        private redirectHelper: RedirectHelperService,
        private criticalErrorService: CriticalErrorService,
        sitecore: Sitecore,
    ) {
        super();
        this.criticalErrorService.criticalError$.pipe(takeUntil(this.destroyed$)).subscribe((showError) => (this.showCriticalError = showError));

        const criticalErrorHeader = sitecore.globalMessages.CriticalErrorHeader || 'Service Connection Lost';
        const criticalErrorText = sitecore.globalMessages.CriticalErrorText || 'Retry';

        this.criticalError = {
            header: criticalErrorHeader,
            text: criticalErrorText,
        };
    }

    reloadPage(): void {
        this.redirectHelper.reloadPage();
    }
}
