import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { WidgetLayoutResponse, WidgetPage } from '@frontend/sports/types/components/widget';
import { Observable } from 'rxjs';

import { ApiService } from '../http/api.service';
import { WidgetContextService } from '../widget/common/widget-context.service';

@Injectable({ providedIn: 'root' })
export class HomePageMarqueeResolver {
    constructor(
        private context: WidgetContextService,
        private apiService: ApiService,
    ) {}

    resolve(route: ActivatedRouteSnapshot): Observable<WidgetLayoutResponse> {
        const context = this.context.getContext({ snapshot: route });
        context.marqueesOnly = true;

        if (!context.page) {
            context.page = WidgetPage.HomeLobby;
        }

        return this.apiService.get<WidgetLayoutResponse>(`/widget`, context);
    }
}
