import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { NavigationService, UrlService } from '@frontend/vanilla/core';

import { UrlHelperService } from '../navigation-core/url-helper.service';

@Injectable({ providedIn: 'root' })
export class OutsideSportsGuard {
    constructor(
        private urlService: UrlService,
        private navigationService: NavigationService,
        private urlHelper: UrlHelperService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const parsedUrl = this.urlService.parse(state.url);
        const absUrl = parsedUrl.absUrl();

        if (window.location.href === absUrl) {
            this.navigationService.goTo(this.urlHelper.getHomeUrl(), { replace: true });

            return false;
        }

        this.navigationService.goTo(parsedUrl, { forceReload: true });

        return false;
    }
}
