import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Sitecore } from '@frontend/sports/common/client-config-data-access';

import { BreadcrumbModel } from '../breadcrumbs/breadcrumb.models';
import { IBreadcrumbsResolve } from '../breadcrumbs/breadcrumbs.resolver';
import { UrlHelperService } from '../navigation-core/url-helper.service';
import { CalendarService } from './calendar.service';

@Injectable({ providedIn: 'root' })
export class CalendarBreadcrumbsResolver implements IBreadcrumbsResolve {
    constructor(
        private sitecore: Sitecore,
        private urlHelper: UrlHelperService,
        private calendar: CalendarService,
        private router: Router,
    ) {}

    resolve(): BreadcrumbModel[] {
        const crumbs: BreadcrumbModel[] = [];
        const append = (icon: string, title: string, url: string) => crumbs.push({ icon, title, url });

        const contextUri = this.router.url.split('/').pop();
        const context = contextUri ? decodeURI(contextUri) : undefined;

        const interval = this.calendar.getInterval(context);

        if (interval) {
            append('sports-calendar', this.sitecore.globalMessages.Calendar, this.urlHelper.getCalendarUrl());
            append('sports-soon', interval.title, this.urlHelper.getUrl(`/${interval.name}`));
        }

        return crumbs;
    }
}
