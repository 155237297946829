import { Injectable, Injector } from '@angular/core';

import { BaseLoader, LoadOptions, Loader } from '@frontend/loaders';

@Injectable()
export class WidgetComposableLoader extends BaseLoader implements Loader {
    constructor(injector: Injector) {
        super('widget-composable', injector);
    }

    async load(options: LoadOptions): Promise<void> {
        await this.loadFeature(import(/* webpackChunkName: "ms-widget-composable" */ './composable-widget.feature'), options);
    }
}
