import { Injectable } from '@angular/core';

import { AccaBoostConfig } from '@frontend/sports/common/client-config-data-access';
import { BetslipIntegrationService } from 'packages/sports/common/betslip/integration/betslip-integration.service';
import { Observable, noop, timer } from 'rxjs';
import { concatMapTo, filter, first, map, repeat } from 'rxjs/operators';

import { AccaBoostRefreshTrigger } from './acca-boost-token-provider.service';

@Injectable()
export class AccaBoostRefreshTriggerService implements AccaBoostRefreshTrigger {
    constructor(
        private betslipIntegrationService: BetslipIntegrationService,
        private config: AccaBoostConfig,
    ) {}

    getTrigger$(): Observable<void> {
        const whenBetslipGetsEmpty$ = this.betslipIntegrationService.betslipPicksCount$().pipe(
            filter((pickCount) => pickCount === 0),
            first(),
            map(noop),
        );
        const pollingInterval$ = timer(this.config.pollingInterval);

        // emit after polling interval and only when betslip gets empty
        return pollingInterval$.pipe(concatMapTo(whenBetslipGetsEmpty$), repeat());
    }
}
