import { Injectable } from '@angular/core';

import { Widget, WidgetContext } from '@frontend/sports/types/components/widget';
import { Observable } from 'rxjs';

import { WidgetRefreshProcessor } from '../core/widget-refresh.service';
import { WidgetContextService } from './widget-context.service';

@Injectable()
export class WidgetContextRefreshProcessor extends WidgetRefreshProcessor {
    constructor(private context: WidgetContextService) {
        super();
    }

    refresh(contexts: Partial<WidgetContext>[]): Observable<Widget<unknown>[]> {
        return this.context.getWidgetBatch(contexts);
    }
}
