import { Injectable, Injector } from '@angular/core';
import {
    ActivatedRoute,
    ActivatedRouteSnapshot,
    ChildrenOutletContexts,
    OutletContext,
    PRIMARY_OUTLET,
    ResolveData,
    ResolveFn,
    Router,
} from '@angular/router';

import { OnAppInit } from '@frontend/vanilla/core';
import { keys } from 'lodash-es';
import { BehaviorSubject, EMPTY, Observable, from, isObservable, of } from 'rxjs';
import { debounceTime, mergeMap, takeLast, tap } from 'rxjs/operators';

import { getToken } from '../router/router.exports';
import { FreshDataType } from './fresh-data-provider.service';
import { FreshDataService } from './fresh-data.service';

@Injectable({ providedIn: 'root' })
export class RouteRefreshService implements OnAppInit {
    constructor(
        private refreshService: FreshDataService,
        private router: Router,
        private context: ChildrenOutletContexts,
        private injector: Injector,
    ) {}

    onAppInit(): void {
        this.refreshService.reloadNeeded.pipe(debounceTime(500)).subscribe((type) => this.refresh(type));
    }

    private async refresh(type: FreshDataType): Promise<void> {
        let outlet: ChildrenOutletContexts | undefined = this.context;
        let outletContext: OutletContext | undefined;

        while (outlet) {
            const current: OutletContext | null = outlet.getContext(PRIMARY_OUTLET);

            if (current) {
                outletContext = current;
            }

            outlet = current?.children;
        }

        if (outletContext?.route) {
            this.resolve(outletContext.route);
        }
    }

    private resolve(route: ActivatedRoute): void {
        if (!route.snapshot?.routeConfig?.resolve) {
            return;
        }

        this.load(route.snapshot.routeConfig.resolve, route.snapshot).subscribe((data) => {
            const subject = route.data as BehaviorSubject<any>;

            subject.next(data);
        });
    }

    private load(resolve: ResolveData, snapshot: ActivatedRouteSnapshot): Observable<any> {
        const data = {};

        return from(keys(resolve)).pipe(
            mergeMap((key) => this.loadKey(key, resolve, snapshot).pipe(tap((result) => (data[key] = result)))),
            takeLast(1),
            mergeMap(() => {
                if (keys(data).length === keys.length) {
                    return of(data);
                }

                return EMPTY;
            }),
        );
    }

    private loadKey(key: string, resolve: ResolveData, snapshot: ActivatedRouteSnapshot): Observable<any> {
        const result = getToken<{
            resolve: ResolveFn<any>;
        }>(resolve[key], snapshot, this.injector).resolve(snapshot, this.router.routerState.snapshot);

        if (isObservable(result)) {
            return result;
        }

        if (result instanceof Promise) {
            return from(result);
        }

        return of(result);
    }
}
