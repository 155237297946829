import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CriticalErrorService {
    private criticalError = new BehaviorSubject<boolean>(false);

    readonly criticalError$ = this.criticalError.asObservable();

    showCriticalError(): void {
        this.criticalError.next(true);
    }

    hideCriticalError(): void {
        this.criticalError.next(false);
    }

    get isCriticalErrorVisible(): boolean {
        return this.criticalError.value;
    }
}
