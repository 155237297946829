import { Provider } from '@angular/core';

import { runOnAppInit } from '@frontend/vanilla/core';

import { WidgetLayoutHook } from '../widget/core/widget-layout-hook';
import { SportLiveButtonService } from './sport/overview/sport-live-button.service';
import { SportNavigationHighlightService } from './sport/overview/sport-navigation-highlight.service';
import { WidgetRegistrationService } from './sport/widget-registration.service';

export function provideEventList(): Provider[] {
    return [
        runOnAppInit(WidgetRegistrationService),
        { provide: WidgetLayoutHook, useClass: SportNavigationHighlightService, multi: true },
        { provide: WidgetLayoutHook, useClass: SportLiveButtonService, multi: true },
    ];
}
