import { Injector } from '@angular/core';

export let APP_INJECTOR: Injector;

/**
 * Helper to set the exported {@link AppInjector}, needed as ES6 modules export
 * immutable bindings (see http://2ality.com/2015/07/es6-module-exports.html) for
 * which trying to make changes after using `import {AppInjector}` would throw:
 * "TS2539: Cannot assign to 'AppInjector' because it is not a variable" .
 */
export function setAppInjector(injector: Injector): void {
    if (!APP_INJECTOR) {
        APP_INJECTOR = injector;
    }
}
