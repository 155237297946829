import { Injectable } from '@angular/core';

import { StylesService } from '@frontend/vanilla/core';
import { Observable, forkJoin, from } from 'rxjs';
import { map } from 'rxjs/operators';

import ModuleLoaderService from '../deferred/module-loader.service';
import { SportsProductMenuModule } from '../sports-product-menu/sports-product-menu.module';

@Injectable({ providedIn: 'root' })
export class SportsProductMenuLoaderService {
    constructor(
        private moduleLoader: ModuleLoaderService,
        private stylesService: StylesService,
    ) {}

    loadAndInitModule(): Observable<SportsProductMenuModule> {
        const moduleLoader = this.moduleLoader.loadModule<SportsProductMenuModule>('SportsProductMenuModule', () =>
            import(/* webpackChunkName: "ms-sports-product-menu" */ '../sports-product-menu/sports-product-menu.module').then(
                (m) => m.SportsProductMenuModule,
            ),
        );
        const stylesLoader = from(this.stylesService.load('sports-product-menu'));

        return forkJoin([moduleLoader, stylesLoader]).pipe(map(([ref, _]) => ref.instance));
    }
}
