import { Injectable } from '@angular/core';

import { OddsAcceptanceMode } from '@bpos';
import { HooksWireup, OnDestroyCleanup } from '@frontend/sports/common/base-utils';
import { DispatcherService } from '@frontend/sports/common/dispatcher-utils';
import { OnAppInit, UserLoginEvent, UserLogoutEvent } from '@frontend/vanilla/core';
import { Action, Store } from '@ngrx/store';
import { ExternalBetslipActions } from 'packages/sports/common/betslip/core/external-betslip-actions';
import { takeUntil } from 'rxjs/operators';

import UserActions from '../actions';
import { IConfigSettings, IUserBettingSettings, UserEvents } from '../model/user.models';
import { UserService } from './user.service';

@HooksWireup()
@Injectable({ providedIn: 'root' })
export class UserServiceWatcher extends OnDestroyCleanup implements OnAppInit {
    constructor(
        private userService: UserService,
        private dispatcher: DispatcherService,
        private store: Store,
    ) {
        super();
    }

    onAppInit(): void {
        this.dispatch(
            UserActions.initState({
                state: {
                    isAuthenticated: this.userService.isAuthenticated,
                    bettingSettings: {} as IUserBettingSettings,
                    configSettings: {} as IConfigSettings,
                    walletLoaded: false,
                    oddsFormat: this.userService.oddsFormat,
                },
            }),
        );
        this.userService.events.pipe(takeUntil(this.destroyed$)).subscribe(async (action) => {
            if (action instanceof UserLoginEvent) {
                this.dispatch(UserActions.userLoggedIn());
                this.dispatchUpdateState();
            } else if (action instanceof UserLogoutEvent) {
                this.dispatch(UserActions.userLoggedOut());
            }
        });

        this.dispatcher
            .on<UserService>(UserEvents.ClaimsChanged)
            .pipe(takeUntil(this.destroyed$))
            .subscribe((user) => {
                this.dispatchUpdateState();
            });

        this.dispatcher
            .on<UserService>(UserEvents.M2UserClaimsChanged)
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                this.dispatchUpdateState();
            });

        this.dispatcher
            .on(UserEvents.UserSettingsChanged)
            .pipe(takeUntil(this.destroyed$))
            .subscribe((settings) => {
                this.dispatchUpdateState();
            });
    }

    private dispatch(action: Action): void {
        this.store.dispatch(action);
    }

    private dispatchUpdateState(): void {
        this.dispatch(
            UserActions.updateState({
                settings: this.userService.bettingSettings,
                config: this.userService.configSettings,
                oddsFormat: this.userService.oddsFormat,
            }),
        );
        this.dispatch(
            ExternalBetslipActions.requestNotificationSettingUpdate({
                oddsAcceptance: this.userService.bettingSettings.oddsAcceptanceMode
                    ? <OddsAcceptanceMode>this.userService.bettingSettings.oddsAcceptanceMode
                    : undefined,
                emailNotify: this.userService.bettingSettings.betslipEmailNotification,
                appNotify: this.userService.bettingSettings.betslipAppNotification,
            }),
        );
    }
}
