import { Injectable } from '@angular/core';

import { Sitecore, SportsUserSettings } from '@frontend/sports/common/client-config-data-access';
import { OnAppInit } from '@frontend/vanilla/core';
import { firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LazySportsConfigLoaderService implements OnAppInit {
    constructor(
        private sportsUserSettings: SportsUserSettings,
        public sitecore: Sitecore,
    ) {}

    async onAppInit(): Promise<any> {
        await Promise.all([firstValueFrom(this.sportsUserSettings.whenReady), firstValueFrom(this.sitecore.whenReady)]);
    }
}
