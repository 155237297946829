import { Injectable } from '@angular/core';

import { getSourceEventId, toDictionary } from '@frontend/sports/common/base-utils';
import { RacingConfig } from '@frontend/sports/common/client-config-data-access';
import { BogVersion } from '@frontend/sports/types/configuration/client-configuration';
import { isEqual } from 'lodash-es';
import { BestOddsGuaranteedToken } from 'packages/sports/common/betslip/modules/reward-tokens/reward-tokens.model';
import { EMPTY, Observable, Subject, merge, of, timer } from 'rxjs';
import { catchError, distinctUntilChanged, map, shareReplay, switchMap } from 'rxjs/operators';

import { ApiService } from '../../http/api.service';
import { LoggerFactory } from '../../logging/logger-factory.service';
import { SportsRemoteLogger } from '../../logging/sports-remote-logger.service';
import { PayoutType, RewardTokenType } from '../../tokens-base/token-base.models';
import { BogToken, BogTokensResponse } from '../models/models';
import { GlobalBestOddsGuaranteeAvailabilityService } from './global-best-odds-guarantee-availability.service';

@Injectable({ providedIn: 'root' })
export class BogTokenProviderService {
    private readonly logger: SportsRemoteLogger;

    private readonly refreshSubject = new Subject<void>();
    readonly tokens$: Observable<BestOddsGuaranteedToken[]> = this.isTokenBasedBogEnabled() ? this.poolTokens() : EMPTY;

    constructor(
        private racingConfig: RacingConfig,
        private apiService: ApiService,
        private bogAvailabilityService: GlobalBestOddsGuaranteeAvailabilityService,
        private loggerFactory: LoggerFactory,
    ) {
        this.logger = this.loggerFactory.getLogger('BogTokenProviderService');
    }

    refresh(): void {
        this.refreshSubject.next();
    }

    private getBogTokens(): Observable<BogTokensResponse> {
        return this.apiService.get<BogTokensResponse>('crmOffers/GetBogTokens').pipe(
            catchError((error) => {
                this.logger.error(error, 'failed to get bog tokens');

                return [];
            }),
        );
    }

    private poolTokens(): Observable<BestOddsGuaranteedToken[]> {
        return merge(
            of([]),
            this.bogAvailabilityService.isBogAvailable$.pipe(
                switchMap((isBogAvailable) => {
                    if (!isBogAvailable) {
                        return of([]);
                    }

                    return merge(this.refreshSubject.asObservable(), timer(0, this.racingConfig.refreshInterval)).pipe(
                        switchMap(() => this.getBogTokens()),
                        map((response) => response.bogTokens.map(this.mapToRewardToken)),
                    );
                }),
            ),
        ).pipe(
            distinctUntilChanged((prev, current) => isEqual(prev, current)),
            shareReplay(1),
        );
    }

    private mapToRewardToken(token: BogToken): BestOddsGuaranteedToken {
        return {
            id: token.id.toString(),
            cmsItemId: '',
            startDate: new Date(token.schedule.fromUtc),
            expiryDate: new Date(token.schedule.toUtc),
            rewardTokenType: RewardTokenType.BestOddsGuaranteed,
            userTokenId: token.id.toString(),
            tokenType: '',
            maximumStake: token.stakeCap?.value,
            payoutType: PayoutType.Cash,
            filter: {
                sports: [],
                meetings: [],
                competitions: [],
                fixtures: [],
                marketTemplates: [],
                marketParameters: [],
                markets: [],
                options: [],
                slipTypes: token.slipTypes,
                schedule: {
                    daysOfTheWeek: token.schedule.daysOfWeek,
                    timesOfTheDay: token.schedule.timesOfDay.map((timeOfDay) => ({ ...timeOfDay })),
                },
                sportsFilter: toDictionary(
                    token.sports,
                    (sportConfig) => sportConfig.id,
                    (sportConfig) => ({
                        competitions: sportConfig.competitionIds.map((id) => +getSourceEventId(id)),
                        regions: sportConfig.regionIds,
                        meetings: sportConfig.meetingIds.map((id) => +getSourceEventId(id)),
                    }),
                ),
            },
        };
    }

    private isTokenBasedBogEnabled() {
        return this.racingConfig.isBOGEnabled && this.racingConfig.bogVersion === BogVersion.TokenBased;
    }
}
