interface IBackgroundWorkerSchedule {
    delay: number; // a delay in milliseconds until start run method for the first time
    interval: number; // interval in milliseconds until the next run method execution.
}

export abstract class RepeatBackgroundWorkerService {
    abstract getSchedule(): IBackgroundWorkerSchedule;

    abstract run(): void;

    abstract init(): void;
    abstract destroy(): void;
}

export abstract class LongRunningBackgroundWorkerService {
    abstract init(): void;
    abstract run(): void;
    abstract destroy(): void;
}
