import { Location } from '@angular/common';
import { Injectable } from '@angular/core';

import { HeadlineModel } from '@frontend/sports/types/components/intro-pages';
import { UrlService } from '@frontend/vanilla/core';
import { firstValueFrom } from 'rxjs';

import { ApiService } from '../http/api.service';
import { ModalDialogService } from '../modal/dialog/modal-dialog.service';

interface InfoPagesResponse {
    pages: HeadlineModel[];
}

@Injectable({ providedIn: 'root' })
export class InfoPagesService {
    constructor(
        private modalDialogService: ModalDialogService,
        private apiService: ApiService,
        private urlService: UrlService,
        private locationService: Location,
    ) {}

    async show(): Promise<void> {
        const response = await firstValueFrom(this.apiService.get<InfoPagesResponse>('infoPages'));
        if (!response || !response.pages || !response.pages.length) {
            this.clearInfoQueryParams();

            return;
        }

        const diag = this.modalDialogService.openDialog(await import('./intro.component').then((c) => c.IntroComponent), {
            data: {
                contentData: response.pages,
            },
            settings: {
                fit: false,
                isModalDialog: false,
                cssClass: 'intro-layer',
                showDialogHeader: true,
            },
        });

        diag.result.finally(() => this.clearInfoQueryParams());
    }

    isOnInfoPage(): boolean {
        return this.urlService.current().search.has('info');
    }

    private clearInfoQueryParams(): void {
        if (this.urlService.current().search.has('info') && !this.urlService.current().search.has('popup')) {
            const url = this.urlService.current();
            url.search.delete('info');
            this.locationService.replaceState(url.path(), url.search.toString());
        }
    }
}
