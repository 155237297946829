import { Injectable } from '@angular/core';

import { RouteTag } from '@frontend/sports/common/base-utils';
import { OnAppInit } from '@frontend/vanilla/core';

import { WidgetRefreshService } from '../widget/core/widget-refresh.service';
import { HomeRefreshTrigger } from './home-refresh-trigger';

@Injectable({ providedIn: 'root' })
export class WidgetRegistrationService implements OnAppInit {
    constructor(
        private refreshRegistry: WidgetRefreshService,
        private refresh: HomeRefreshTrigger,
    ) {}

    onAppInit(): void {
        this.refreshRegistry.registerTrigger(RouteTag.Lobby, this.refresh);
    }
}
