import { createAction } from '@ngrx/store';

export default class StorePersistActions {
    private static readonly SAVE_STATE = `@sports/SAVE_STATE`;

    /**
     * Trigger manual saving state.
     */
    static saveState = createAction(StorePersistActions.SAVE_STATE);
}
