import { NgZone, enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter, withPreloading, withRouterConfig } from '@angular/router';

import { provideLoaders } from '@frontend/loaders';
import { AppComponent, bootloader, provideVanillaCore } from '@frontend/vanilla/core';
import { setAutoFreeze } from 'immer';

import { routes } from './app-routing.module';
import { AppModule } from './app.module';
import { QuicklinkPreloader } from './quicklink/quicklink-preloader.service';

if (process.env.NODE_ENV === 'production') {
    setAutoFreeze(false);
    enableProdMode();
}

const disableAnimations = !('animate' in document.documentElement); //[INC1293113] disabling animations, when they're are not supported by OS. For example iOS <13

bootloader()
    .then(() =>
        bootstrapApplication(AppComponent, {
            providers: [
                {
                    provide: NgZone,
                    useValue: new NgZone({ shouldCoalesceEventChangeDetection: true }),
                },
                provideAnimationsAsync(disableAnimations ? 'noop' : 'animations'),
                provideRouter(routes, withPreloading(QuicklinkPreloader), withRouterConfig({ urlUpdateStrategy: 'eager' })),
                provideVanillaCore(),
                provideLoaders(),
                importProvidersFrom(AppModule),
            ],
        }),
    )
    .catch((err: any) => console.error(err));
