import { Injectable } from '@angular/core';
import { ActivationEnd } from '@angular/router';

import { HooksWireup, OnDestroyCleanup, RouteTag } from '@frontend/sports/common/base-utils';
import { HtmlNode, OnAppInit, UserLoginEvent, UserService } from '@frontend/vanilla/core';
import { AccountMenuConfig } from '@frontend/vanilla/shared/account-menu';
import { combineLatest } from 'rxjs';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';

import { RouterEventsService } from '../navigation-core/router-events.service';
import { AccountMenuVersion } from '../user/model/early-payout-acceptance';

@HooksWireup()
@Injectable({
    providedIn: 'root',
})
export class SettingsService extends OnDestroyCleanup implements OnAppInit {
    constructor(
        private accountMenuConfig: AccountMenuConfig,
        private htmlNode: HtmlNode,
        private routeService: RouterEventsService,
        private userService: UserService,
    ) {
        super();
    }

    onAppInit(): void {
        const routeNavigation$ = this.routeService.currentActivationEnd.pipe(filter((route) => route?.snapshot.data.tag));
        const vanillaAccountVersion$ = this.accountMenuConfig.whenReady.pipe(map(() => this.accountMenuConfig.account.version));

        const accountVersion$ = this.userService.isAuthenticated
            ? vanillaAccountVersion$
            : this.userService.events.pipe(
                  filter((event) => event instanceof UserLoginEvent),
                  take(1),
                  switchMap(() => vanillaAccountVersion$),
              );

        combineLatest([accountVersion$, routeNavigation$])
            .pipe(takeUntil(this.destroyed$))
            .subscribe(([accountVersion, route]) => {
                this.toggleCardLayoutStyle(accountVersion, this.isCardLayoutRoute(route));
            });
    }

    private isCardLayoutRoute(navRoute: ActivationEnd | undefined): boolean {
        return (
            navRoute?.snapshot.data.tag === RouteTag.Settings ||
            navRoute?.snapshot.data.tag === RouteTag.EarlyPayoutSettings ||
            navRoute?.snapshot.data.tag === RouteTag.GeneralBetslipSettings
        );
    }

    private toggleCardLayoutStyle(accountVersion: number, add: boolean) {
        if (accountVersion === AccountMenuVersion.V3) this.htmlNode.setCssClass('navigation-layout-page-card', add);
    }
}
