import { Injectable } from '@angular/core';

import { PrettyUrlsConfig } from '@frontend/sports/common/client-config-data-access';
import { RouteProcessor } from '@frontend/vanilla/core';
import { kebabCase, keys, template } from 'lodash-es';

import { SportRoute } from './multi-route.model';

@Injectable()
export class PrettyUrlRouteProcessor implements RouteProcessor {
    constructor(private routeConfig: PrettyUrlsConfig) {}

    /* Keep for future testing
	test(routeConfig: Route[]): void {
		const log = (route: Route, segements: string[]) => {
			const data = route.data || {};
			const dataSegments = route.path === '' ? segements : [...segements, data.originalPath || 'missing'];

			if (route.children && route.children.length) {
				route.children.forEach(child => {
					log(child, dataSegments);
				});
			} else {
				console.log(dataSegments.join('/'));
			}
		};

		routeConfig.forEach(route => log(route, []));
	}
	 */

    process(route: SportRoute): SportRoute | null {
        route.data = route.data || {};

        if (route.path !== undefined) {
            this.tryUpdateOriginalPath(route, this.stripToken(route.path));
            route.path = this.interpolate(route.path);
        }

        if (route.redirectTo) {
            route.redirectTo = this.interpolate(route.redirectTo);
        }

        if (route.matcher) {
            const includeName = ['region', 'league', 'meeting', 'race'];
            const normalizeName = (param?: string) => kebabCase((param || '').replace('Id', ''));
            const toName = (param?: string) => (includeName.indexOf(normalizeName(param)) === -1 ? `:${param}` : `${normalizeName(param)}-:${param}`);

            if (route.data.matcherParam) {
                this.tryUpdateOriginalPath(route, toName(route.data.matcherParam));
            }

            if (route.data.matcherPattern) {
                const useMapping = (segement: string) => segement.indexOf('(') !== -1;
                const mapping = keys(route.data.matcherMapping).filter((x) => x.indexOf('Name') === -1);

                this.tryUpdateOriginalPath(
                    route,
                    this.stripJoin('/', this.stripOptional(route.data.matcherPattern), (segment) =>
                        useMapping(segment) ? toName(mapping.shift()) : this.stripToken(segment),
                    ),
                );

                route.data.matcherPattern = this.interpolate(route.data.matcherPattern);
            }
        }

        return route;
    }

    private tryUpdateOriginalPath(route: SportRoute, value: string): void {
        route.data = route.data || {};
        if (!route.data.originalPath || this.isWritable(route.data, 'originalPath')) {
            route.data.originalPath = value;
        }
    }

    private isWritable<T extends object>(obj: T, key: keyof T): boolean {
        const desc = Object.getOwnPropertyDescriptor(obj, key) || {};

        return Boolean(desc.writable);
    }

    private interpolate(path: string): string {
        return template(path, { interpolate: /{([^}]+?)}/g })({
            ...this.routeConfig.translations,
            formCoupon: 'form-coupon',
        });
    }

    private stripToken(path: string): string {
        if (path === '**') {
            return path;
        }

        return this.stripJoin('/', path, (segement) => {
            return this.stripJoin(':', segement, kebabCase);
        });
    }

    private stripOptional(path: string): string {
        return path.replace(/(\([^\/]*\)\?|\^|\$)/g, '');
    }

    private stripJoin(token: string, text: string, callback: (node: string) => string): string {
        return text.split(token).map(callback).join(token);
    }
}
