import { Injectable } from '@angular/core';

import { RouteTag } from '@frontend/sports/common/base-utils';
import { MediaQueryService } from '@frontend/vanilla/core';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, skip } from 'rxjs/operators';

import { FreshDataProvider, FreshDataType } from '../data-refresh/fresh-data-provider.service';
import { RouterEventsService } from '../navigation-core/router-events.service';
import { LARGE_LAYOUT_BREAKPOINT, MEDIUM_LAYOUT_BREAKPOINT } from '../widget/core/widget.constants';

@Injectable()
export class LayoutRefreshProvider extends FreshDataProvider {
    private reloadNeededStream = new Subject<FreshDataType>();

    // as we are using differnet breakpoints for the responsive column, we need different breakpoints for widget pages
    private readonly widgetBreackpoints = [LARGE_LAYOUT_BREAKPOINT, MEDIUM_LAYOUT_BREAKPOINT];
    private readonly baseBreakpoints = ['gt-md', 'gt-xs'];

    private widgetPage = false;

    constructor(
        private media: MediaQueryService,
        router: RouterEventsService,
    ) {
        super();

        // TODO: REFACTOR MODULA DEPENDENCY
        router.currentActivationEnd.subscribe((event) => {
            this.widgetPage =
                [RouteTag.Lobby, RouteTag.SportLobby, RouteTag.Competition, RouteTag.MultiSportsLobby].indexOf(event?.snapshot.data.tag) > -1;
        });

        this.media
            .observe()
            .pipe(map(this.activeBreakpoint), distinctUntilChanged(), skip(1))
            .subscribe(() => {
                this.reloadNeededStream.next(FreshDataType.Data);
            });
    }

    isRefreshNeeded(): Observable<FreshDataType> {
        return this.reloadNeededStream.asObservable();
    }

    private activeBreakpoint = () => {
        return this.widgetPage ? this.findActiveBreakpoint(this.widgetBreackpoints) : this.findActiveBreakpoint(this.baseBreakpoints);
    };

    private findActiveBreakpoint = (breakpoints: string[]) => breakpoints.find((breakpoint) => this.media.isActive(breakpoint)) || '';
}
