import { Injectable } from '@angular/core';

import { WidgetPage } from '@frontend/sports/types/components/widget';

import { SportsCacheService } from '../../../client-caching/sports-cache.service';
import { LiveNowLinkRegisterService } from '../../../live-now-item/live-now-link-register.service';
import { LiveUrlParam, UrlHelperService } from '../../../navigation-core/url-helper.service';
import { WidgetContextService } from '../../../widget/common/widget-context.service';
import { WidgetLayoutHook } from '../../../widget/core/widget-layout-hook';

@Injectable()
export class SportLiveButtonService extends WidgetLayoutHook {
    constructor(
        private liveButton: LiveNowLinkRegisterService,
        private context: WidgetContextService,
        private cache: SportsCacheService,
        private urlHelper: UrlHelperService,
    ) {
        super(WidgetPage.SportLobby);
    }

    onResolve(): void {
        const { sportId } = this.context.getContext();

        this.cache.getSportList().subscribe((sports) => {
            const sport = sports.find((s) => s.id === sportId);
            this.liveButton.addLiveButton(sport, sport?.isEsport ? this.urlHelper.getLiveUrl(LiveUrlParam.Esports) : undefined);
        });
    }

    onDestroy(): void {
        this.liveButton.removeLiveButton();
    }
}
