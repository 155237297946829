import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AdaptiveLayoutFlag, AdaptiveLayoutProvider } from '../layout/adaptive-layout.service';
import { Breadcrumbs, BreadcrumbsService } from './breadcrumbs.service';

@Injectable({ providedIn: 'root' })
export class BreadcrumbLayoutProvider implements AdaptiveLayoutProvider {
    constructor(private breadcrumbService: BreadcrumbsService) {}

    asObservable(): Observable<AdaptiveLayoutFlag> {
        return this.breadcrumbService.breadcrumbs$.pipe(map((value) => this.getState(value)));
    }

    private getState(value: Breadcrumbs): AdaptiveLayoutFlag {
        return {
            breadcrumbNavigation: value.visible,
        };
    }
}
