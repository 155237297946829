import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';

import { isDefined } from '@frontend/sports/common/base-utils';
import { PrettyUrlsConfig } from '@frontend/sports/common/client-config-data-access';
import { PageViewDataProvider, TrackingData } from '@frontend/vanilla/core';
import { forIn, kebabCase, keys } from 'lodash-es';
import { Observable, of } from 'rxjs';

@Injectable()
export class PrettyUrlPageViewDataProvider implements PageViewDataProvider {
    constructor(
        private router: Router,
        private urlConfig: PrettyUrlsConfig,
    ) {}

    getData(): Observable<TrackingData> {
        return of({ 'page.name': this.getPageName() });
    }

    private getPageName(): string {
        const separator = '/';

        let node = this.router.routerState.snapshot.root;
        let segments: string[] = [];
        let params: Params = {};
        let path = '';

        while (node) {
            segments.push(node.data.originalPath);
            params = { ...node.params };

            node = node.children[0];
        }

        segments = segments
            .filter(isDefined)
            .filter((current) => current !== '')
            .filter((current, index) => index > 0);
        path = segments.join(separator);

        // fix for context parameter for sport pages and tab parameter for my bets
        this.setToken(params, 'context');
        this.setToken(params, 'tab');

        forIn(params, (value, key) => (path = path.replace(`:${key}`, value)));

        return path;
    }

    private setToken(params: Params, key: string): void {
        if (!params[key]) {
            return;
        }

        const translations = this.urlConfig.translations;

        const param = keys(translations).find((token) => translations[token] === params[key]);

        if (param) {
            params[key] = kebabCase(param);
        }
    }
}
