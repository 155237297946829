import { Injectable } from '@angular/core';

import { HooksWireup, OnDestroyCleanup } from '@frontend/sports/common/base-utils';
import { DispatcherService } from '@frontend/sports/common/dispatcher-utils';
import { BottomNavService, OnAppInit } from '@frontend/vanilla/core';
import { BetslipBarVisibilityService } from 'packages/sports/common/betslip/modules/betslip-bar/services/betslip-bar-visibility.service';
import { QuickBetDrawerVisibilityService } from 'packages/sports/common/betslip/modules/quick-bet/services/quick-bet-drawer-visibility.service';
import { combineLatest } from 'rxjs';
import { distinctUntilKeyChanged, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';

import { DispatcherEvent } from '../event-details-common/event-details-common.models';
import { AdaptiveLayoutService } from '../layout/adaptive-layout.service';

@HooksWireup()
@Injectable({ providedIn: 'root' })
export class ToggleBottomNavService extends OnDestroyCleanup implements OnAppInit {
    constructor(
        private adaptiveLayout: AdaptiveLayoutService,
        private bottomNavService: BottomNavService,
        private betslipBarVisibility: BetslipBarVisibilityService,
        private quickBetDrawerVisibilityService: QuickBetDrawerVisibilityService,
        private dispatcher: DispatcherService,
    ) {
        super();
    }

    onAppInit(): void {
        const layoutBottomNavVisibility = this.adaptiveLayout.stateChange$.pipe(distinctUntilKeyChanged('bottomNavigation'));
        const vanillaNav = this.bottomNavService.whenReady.pipe(switchMap(() => this.bottomNavService.isEnabled()));

        combineLatest([vanillaNav, layoutBottomNavVisibility])
            .pipe(takeUntil(this.destroyed$))
            .subscribe(([vanillaNavEnabled, state]) => {
                if (!vanillaNavEnabled) {
                    return;
                }

                if (state.bottomNavigation) {
                    this.bottomNavService.show();
                    this.betslipBarVisibility.showComponent();
                    this.quickBetDrawerVisibilityService.showDrawer();
                } else {
                    this.bottomNavService.hide();
                    this.betslipBarVisibility.hideComponent();
                    this.quickBetDrawerVisibilityService.hideDrawer();
                }
            });

        this.dispatcher
            .on<boolean>(DispatcherEvent.EventDetails.Expanded)
            .pipe(withLatestFrom(vanillaNav, layoutBottomNavVisibility))
            .subscribe(([expanded, enabled, state]) => {
                if (!enabled || !state.bottomNavigation) {
                    return;
                }

                if (expanded) {
                    this.bottomNavService.hide();
                    this.betslipBarVisibility.hideComponent();
                    this.quickBetDrawerVisibilityService.hideDrawer();
                } else {
                    this.bottomNavService.show();
                    this.betslipBarVisibility.showComponent();
                    this.quickBetDrawerVisibilityService.showDrawer();
                }
            });
    }
}
