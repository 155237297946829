import { EnvironmentProviders, Provider, importProvidersFrom } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { StateStorageModule } from '../store-persist/state-storage.module';
import { MediaStateSaver } from './media-state-storage';
import { MediaEffects } from './media.epic';
import { mediaReducer } from './media.reducer';
import { mediaFeatureKey } from './media.state';
import { UnpinInfoService } from './unpin-info.service';

export function MediaProviders(): (Provider | EnvironmentProviders)[] {
    return [
        importProvidersFrom(
            StoreModule.forFeature(mediaFeatureKey, mediaReducer),
            EffectsModule.forFeature([MediaEffects]),
            StateStorageModule.forFeature(mediaFeatureKey, MediaStateSaver),
        ),
        UnpinInfoService,
    ];
}
