import { Inject, Injectable } from '@angular/core';

import { EarlyPayoutConfig } from '@frontend/sports/common/client-config-data-access';
import { DispatcherService } from '@frontend/sports/common/dispatcher-utils';

import { CdsPushService } from '../../cds/cds-push.service';
import { LoggerFactory } from '../../logging/logger-factory.service';
import { UserService } from '../../user/services/user.service';
import { earlyPayoutServiceEditBetToken, earlyPayoutServiceMyBetsToken } from './early-payout-injection-tokens';
import { EarlyPayoutSubscriptionMapperService } from './early-payout-subscription-mapper.service';
import { EarlyPayoutSubscriptionService } from './early-payout-subscription.service';
import { EarlyPayoutService } from './early-payout.service';
import { EarlyPayoutHelpers } from './my-bets-earlypayout-helpers';

export class EarlyPayoutSubscriptionServiceFactory {
    constructor(
        private epConfig: EarlyPayoutConfig,
        private cdsPush: CdsPushService,
        private earlyPayoutService: EarlyPayoutService,
        private epHelpers: EarlyPayoutHelpers,
        private dispatcher: DispatcherService,
        private userService: UserService,
        private earlyPayoutSubscriptionMapperService: EarlyPayoutSubscriptionMapperService,
        private loggerFactory: LoggerFactory,
    ) {}

    create(): EarlyPayoutSubscriptionService {
        return new EarlyPayoutSubscriptionService(
            this.epConfig,
            this.cdsPush,
            this.earlyPayoutService,
            this.epHelpers,
            this.dispatcher,
            this.userService,
            this.earlyPayoutSubscriptionMapperService,
            this.loggerFactory,
        );
    }
}

@Injectable({ providedIn: 'root' })
export class EarlyPayoutSubscriptionServiceMyBetsFactory extends EarlyPayoutSubscriptionServiceFactory {
    constructor(
        epConfig: EarlyPayoutConfig,
        cdsPush: CdsPushService,
        @Inject(earlyPayoutServiceMyBetsToken) earlyPayoutService: EarlyPayoutService,
        epHelpers: EarlyPayoutHelpers,
        dispatcher: DispatcherService,
        userService: UserService,
        earlyPayoutSubscriptionMapperService: EarlyPayoutSubscriptionMapperService,
        loggerFactory: LoggerFactory,
    ) {
        super(epConfig, cdsPush, earlyPayoutService, epHelpers, dispatcher, userService, earlyPayoutSubscriptionMapperService, loggerFactory);
    }
}

@Injectable({ providedIn: 'root' })
export class EarlyPayoutSubscriptionServiceEditBetFactory extends EarlyPayoutSubscriptionServiceFactory {
    constructor(
        epConfig: EarlyPayoutConfig,
        cdsPush: CdsPushService,
        @Inject(earlyPayoutServiceEditBetToken) earlyPayoutService: EarlyPayoutService,
        epHelpers: EarlyPayoutHelpers,
        dispatcher: DispatcherService,
        userService: UserService,
        earlyPayoutSubscriptionMapperService: EarlyPayoutSubscriptionMapperService,
        loggerFactory: LoggerFactory,
    ) {
        super(epConfig, cdsPush, earlyPayoutService, epHelpers, dispatcher, userService, earlyPayoutSubscriptionMapperService, loggerFactory);
    }
}
