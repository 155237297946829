import { Injectable } from '@angular/core';

import { Fixture } from '@cds/betting-offer';
import { OnAppInit, WindowRef } from '@frontend/vanilla/core';
import { Store } from '@ngrx/store';
import { ExternalBetslipActions } from 'packages/sports/common/betslip/core/external-betslip-actions';

@Injectable({ providedIn: 'root' })
export class WebBridgeService implements OnAppInit {
    constructor(
        private windowRef: WindowRef,
        private store: Store,
    ) {}

    onAppInit(): void {
        this.windowRef.nativeWindow['webApp'] = {
            addPick: this.addPick,

            removePick: this.removePick,
        };
    }

    private addPick = (fixture: Fixture, option: number) => this.store.dispatch(ExternalBetslipActions.addWebAppPick({ fixture, option }));

    private removePick = (fixture: Fixture, option: number) => this.store.dispatch(ExternalBetslipActions.removeWebAppPick({ fixture, option }));
}
