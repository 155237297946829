import { Injectable } from '@angular/core';

import { WidgetPage } from '@frontend/sports/types/components/widget';

import { NavigationRouterService } from '../../../navigation/navigation-router.service';
import { WidgetContextService } from '../../../widget/common/widget-context.service';
import { WidgetLayoutHook } from '../../../widget/core/widget-layout-hook';

@Injectable()
export class SportNavigationHighlightService extends WidgetLayoutHook {
    constructor(
        private navigationRouter: NavigationRouterService,
        private context: WidgetContextService,
    ) {
        super(WidgetPage.SportLobby);
    }

    onResolve(): void {
        const { sportId } = this.context.getContext();

        if (sportId) {
            this.navigationRouter.setNavigation(sportId.toString());
        }
    }

    onDestroy(): void {
        this.navigationRouter.resetNavigation();
    }
}
