import { Injectable } from '@angular/core';

import { WidgetPage } from '@frontend/sports/types/components/widget';

import { SportsCacheService } from '../client-caching/sports-cache.service';
import { LiveNowLinkRegisterService } from '../live-now-item/live-now-link-register.service';
import { LiveUrlParam, UrlHelperService } from '../navigation-core/url-helper.service';
import { WidgetLayoutHook } from '../widget/core/widget-layout-hook';

@Injectable()
export class ESportLiveButtonService extends WidgetLayoutHook {
    constructor(
        private liveButton: LiveNowLinkRegisterService,
        private cache: SportsCacheService,
        private urlHelper: UrlHelperService,
    ) {
        super(WidgetPage.ESportsLobby);
    }

    onResolve(): void {
        this.cache.getSportList().subscribe((sports) => {
            this.liveButton.addLiveButtonWithMultipleSports(
                sports.filter((sport) => sport.isEsport),
                this.urlHelper.getLiveUrl(LiveUrlParam.Esports),
            );
        });
    }

    onDestroy(): void {
        this.liveButton.removeLiveButton();
    }
}
