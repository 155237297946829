import { Injectable, Type } from '@angular/core';

import { StylesService } from '@frontend/vanilla/core';

import { BetfinderModule } from '../betfinder/betfinder.module';
import { LoggerFactory } from '../logging/logger-factory.service';
import { ComponentLoaderService, ComponentProxy } from './component-loader.service';
import ModuleLoaderService from './module-loader.service';

@Injectable({ providedIn: 'root' })
export class BetfinderComponentLoaderService extends ComponentLoaderService<BetfinderModule> {
    moduleName = 'BetfinderModule';

    constructor(moduleLoader: ModuleLoaderService, loggerFactory: LoggerFactory, stylesService: StylesService) {
        super(moduleLoader, loggerFactory, stylesService);
    }

    getBetfinderComponent(): ComponentProxy {
        return this.getComponentProxy((module) => module.betfinderComponent, 'betfinder');
    }

    getModule(): Promise<Type<BetfinderModule>> {
        return import(/* webpackChunkName: "ms-betfinder" */ '../betfinder/betfinder.module').then((m) => m.BetfinderModule);
    }
}
