import { Provider } from '@angular/core';

import { runOnAppInit } from '@frontend/vanilla/core';

import { WidgetLayoutHook } from '../widget/core/widget-layout-hook';
import { ESportLiveButtonService } from './esports-live-button.service';
import { EsportsRefreshRegistrationService } from './esports-refresh-registration.service';

export default function provideEsports(): Provider[] {
    return [
        runOnAppInit(EsportsRefreshRegistrationService),
        {
            provide: WidgetLayoutHook,
            useClass: ESportLiveButtonService,
            multi: true,
        },
    ];
}
