import { Injectable } from '@angular/core';

import { OnAppInit, WindowRef } from '@frontend/vanilla/core';

import { CriticalErrorService } from '../critical-error/critical-error.service';

@Injectable({
    providedIn: 'root',
})
export class CriticalErrorBootstrapService implements OnAppInit {
    constructor(
        private criticalErrorService: CriticalErrorService,
        private windowRef: WindowRef,
    ) {}

    onAppInit(): void {
        this.windowRef.nativeWindow.addEventListener('offline', () => setTimeout(() => this.criticalErrorService.showCriticalError(), 0), false);
        this.windowRef.nativeWindow.addEventListener('online', () => setTimeout(() => this.criticalErrorService.hideCriticalError(), 0), false);
    }
}
