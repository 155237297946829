import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ClassProvider } from '@angular/core';

import { ApiVersioningInterceptor } from './api-versioning-interceptor';
import { ConnectionLostInterceptor } from './connection-lost.interceptor';
import { DuplicateBetplacementInterceptor } from './duplicate-betplacement.interceptor';
import { SessionExpirationInterceptor } from './session-expiration.interceptor';

export function provideSportsHttpInterceptors(): ClassProvider[] {
    return [
        { provide: HTTP_INTERCEPTORS, useClass: ConnectionLostInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: SessionExpirationInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: DuplicateBetplacementInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ApiVersioningInterceptor, multi: true },
    ];
}
