import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, ActivationEnd } from '@angular/router';

import { OnAppInit } from '@frontend/vanilla/core';
import { filter, take } from 'rxjs/operators';

import { RouterEventsService } from '../navigation-core/router-events.service';
import { getToken } from '../router/router.exports';
import { SchemaMarkupType } from './schema-markup-enum';
import { ISchemaMarkupResolver } from './schema-markup.resolver';
import { SchemaMarkupService } from './schema-markup.service';

@Injectable({ providedIn: 'root' })
export class SchemaMarkupBoostrapService implements OnAppInit {
    constructor(
        private injector: Injector,
        private routerEventsService: RouterEventsService,
        private schemaMarkupService: SchemaMarkupService,
    ) {}

    onAppInit(): void {
        this.routerEventsService.currentActivationEnd.subscribe((event) => this.activationEnded(event));
    }

    private activationEnded(event: ActivationEnd | undefined): void {
        if (!event) {
            return;
        }

        const resolverType = this.findFirstSubnavigationResolver(event.snapshot);
        this.schemaMarkupService.removeSchemaMarkupScriptFromBody(SchemaMarkupType.SportsEvent);
        if (!resolverType) {
            return;
        }

        const resolver = getToken<ISchemaMarkupResolver<any>>(resolverType, event.snapshot, this.injector);

        if (!resolver) {
            return;
        }

        const model = event.snapshot.data['model'];
        const events = resolver.resolve(model);
        this.schemaMarkupService.cleanEvents();

        events
            .pipe(
                take(1),
                filter((evnts) => evnts.length > 0),
            )
            .subscribe((evnts) => this.schemaMarkupService.addEventSchemaMarkup(evnts));
    }

    private findFirstSubnavigationResolver(snapshot: ActivatedRouteSnapshot): () => void {
        let crtSnapshot: ActivatedRouteSnapshot | null = snapshot;

        while (crtSnapshot && (!crtSnapshot.data || !crtSnapshot.data['schemaMarkup'])) {
            crtSnapshot = crtSnapshot.parent;
        }

        return crtSnapshot && crtSnapshot.data['schemaMarkup'];
    }
}
