import { Injectable, Injector } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class RootInjector {
    static injector: Injector;

    constructor(injector: Injector) {
        RootInjector.injector = injector;
    }
}
