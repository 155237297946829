import { Injectable, inject } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';

import { EMPTY, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { PrefetchRegistry } from './prefetch-registry.service';

@Injectable({ providedIn: 'root' })
export class QuicklinkPreloader extends PreloadingStrategy {
    #loading = new Set<Route>();
    #registry = inject(PrefetchRegistry);

    preload(route: Route, load: () => Observable<void>): any {
        if (this.#loading.has(route)) {
            // Don't preload the same route twice
            return EMPTY;
        }
        const conn = typeof navigator !== 'undefined' ? (navigator as any).connection : undefined;
        if (conn) {
            // Don't preload if the user is on 2G. or if Save-Data is enabled.
            if ((conn.effectiveType || '').includes('2g') || conn.saveData) return EMPTY;
        }
        // Prevent from preloading
        if (route.data && route.data['preload'] === false) {
            return EMPTY;
        }

        if (this.#registry.shouldPrefetch(route)) {
            this.#loading.add(route);
            return load().pipe(
                tap({
                    complete: () => {
                        this.#registry.addPreResolvers(route);
                    },
                }),
            );
        }

        return EMPTY;
    }
}
