import { Component, HostBinding, OnInit } from '@angular/core';

import { Sitecore } from '@frontend/sports/common/client-config-data-access';

import { ActiveModal } from '../modal/common/modal-ref';
import { SettingsUrlParam, UrlHelperService } from '../navigation-core/url-helper.service';

@Component({
    standalone: true,
    selector: 'ms-modal-pop-up-info',
    templateUrl: './modal-pop-up-info.component.html',
    styleUrls: ['./modal-pop-up-info.component.scss'],
})
export class ModalPopUpComponent implements OnInit {
    @HostBinding('class') className = 'betting-settings-update-popup';
    settingsURL: string;

    constructor(
        private activeModal: ActiveModal,
        private urlHelper: UrlHelperService,
        public sitecore: Sitecore,
    ) {}

    ngOnInit(): void {
        this.settingsURL = this.urlHelper.getSettingsUrl(SettingsUrlParam.Betslip);
    }

    close(): void {
        this.activeModal.close();
    }
}
