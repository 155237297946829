import { Injector, inject } from '@angular/core';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { NativeAppService, NativeEventType } from '@frontend/vanilla/core';

import { LoaderStylesService } from './loader-styles.service';
import { LoadOptions } from './loaders.client-config';
import { ModuleService } from './module.service';

/**
 * Offers basic lazy feature loading.
 *
 * @deprecated
 */
export abstract class BaseLoader {
    id: string;

    private moduleService = inject(ModuleService);
    private nativeApp = inject(NativeAppService);
    private loadStylesService = inject(LoaderStylesService);

    protected constructor(id: string, private injector: Injector) {
        this.id = id;
    }

    /* try to improve this function once there is better support for dynamic imports. would be nice that featureImport parameter is removed and import is done based on id parameter value. In year 2020 with webpack 4 this was not possible :) */
    async loadFeature(featureImport: any, options: LoadOptions) {
        await this.loadStylesService.loadStyles(options.styles);

        const feature = await featureImport;
        const featureInjector = Injector.create({
            providers: feature.provide(),
            parent: this.injector,
        });

        this.moduleService.runBootstrappers(featureInjector);
        this.moduleService.runDslProviders(featureInjector);
        if (options.eventContext) {
            this.moduleService.runEventProcessors(featureInjector, options.eventContext);
        }

        this.nativeApp.sendToNative({
            eventName: NativeEventType.FEATURE_LOADED,
            parameters: {
                featureId: this.id,
            },
        });
    }
}
