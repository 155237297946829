import { Injectable } from '@angular/core';

import { WidgetPage } from '@frontend/sports/types/components/widget';

import { NavigationRouterService } from '../navigation/navigation-router.service';
import { WidgetLayoutHook } from '../widget/core/widget-layout-hook';

@Injectable()
export class HomeNavigationHighlightService extends WidgetLayoutHook {
    constructor(private navigationRouter: NavigationRouterService) {
        super(WidgetPage.HomeLobby);
    }

    onResolve(): void {
        this.navigationRouter.setNavigation('home');
    }

    onDestroy(): void {
        this.navigationRouter.resetNavigation();
    }
}
