import { Injectable } from '@angular/core';

import { MultiSportsHubConfig } from '@frontend/sports/common/client-config-data-access';
import { Observable, map } from 'rxjs';

import { RedirectHelperService } from '../navigation-core/redirect-helper.service';

@Injectable({ providedIn: 'root' })
export class MultiSportsHubGuard {
    constructor(
        private multiSportsConfig: MultiSportsHubConfig,
        public redirectHelperService: RedirectHelperService,
    ) {}

    canActivateChild(): Observable<boolean> {
        return this.multiSportsConfig.whenReady.pipe(
            map(() => {
                if (!this.multiSportsConfig.isEnabled) {
                    this.redirectHelperService.goHome();
                }
                return this.multiSportsConfig.isEnabled;
            }),
        );
    }
}
