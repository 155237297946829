import { Injectable } from '@angular/core';

import { Sitecore } from '@frontend/sports/common/client-config-data-access';

import { BreadcrumbResult, IBreadcrumbsResolve } from '../breadcrumbs/breadcrumbs.resolver';
import { UrlHelperService } from '../navigation-core/url-helper.service';
import { NavigationRouterService } from '../navigation/navigation-router.service';

@Injectable({ providedIn: 'root' })
export class MultiSportsBreadcrumbsResolver implements IBreadcrumbsResolve {
    constructor(
        private sitecore: Sitecore,
        private urlHelper: UrlHelperService,
        private navigationRouter: NavigationRouterService,
    ) {}

    resolve(): BreadcrumbResult {
        const title = this.sitecore.seo;
        this.navigationRouter.setNavigation('multisportshub');
        return [
            {
                icon: 'sports-26',
                title: title.MultiSportsBreadcrumbTitle,
                url: this.urlHelper.getMultiSportsHubUrl(),
            },
        ];
    }
}
