import { Injectable } from '@angular/core';

import { BestOddsGuaranteedToken } from 'packages/sports/common/betslip/modules/reward-tokens/reward-tokens.model';
import { Observable, map } from 'rxjs';

import { BestOddsFixtureLikeInput } from '../models/models';
import { BestOddsGuaranteedService } from './best-odds-guaranteed.service';
import { BogTokenProviderService } from './bog-token-provider.service';
import { FixtureBestOddsGuaranteedTokenValidatorService } from './fixture-best-odds-guaranteed-token-validator.service';

@Injectable()
export class TokenBasedBestOddsGuaranteedService extends BestOddsGuaranteedService {
    constructor(
        private tokenProvider: BogTokenProviderService,
        private tokenValidator: FixtureBestOddsGuaranteedTokenValidatorService,
    ) {
        super();
    }

    isFixtureEligible(fixture: BestOddsFixtureLikeInput): Observable<boolean> {
        return this.tokenProvider.tokens$.pipe(
            map((tokens: BestOddsGuaranteedToken[]) => {
                return !!tokens.length && tokens.some((token) => this.tokenValidator.validate(token, fixture));
            }),
        );
    }
}
