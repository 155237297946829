import { EnvironmentProviders, importProvidersFrom } from '@angular/core';

import { StoreModule } from '@ngrx/store';

import { userReducer } from './reducer';
import { userFeatureKey } from './state';

export function provideUser(): EnvironmentProviders {
    return importProvidersFrom(StoreModule.forFeature(userFeatureKey, userReducer));
}
