import { Injectable, Injector } from '@angular/core';

import { BaseLoader, LoadOptions, Loader } from '@frontend/loaders';

@Injectable()
export class ShowCaseLoader extends BaseLoader implements Loader {
    constructor(injector: Injector) {
        super('showcase', injector);
    }

    async load(options: LoadOptions): Promise<void> {
        await this.loadFeature(import(/* webpackChunkName: "ms-showcase" */ './showcase.feature'), options);
    }
}
