import { AsyncPipe, NgIf } from '@angular/common';
import { Component, HostBinding, inject } from '@angular/core';

import { HooksWireup } from '@frontend/sports/common/base-utils';
import { PromoBannerPayload } from '@frontend/sports/types/components/widget/types';
import { CRM_CONFIG_INJECTION_TOKEN } from 'packages/sports/common/betslip/integration/sports-injection-services';
import { RewardTokensDataService } from 'packages/sports/common/betslip/modules/reward-tokens/services/reward-tokens-data.service';
import { getNonAccaTokens } from 'packages/sports/common/betslip/modules/reward-tokens/services/reward-tokens.utils';
import { filter, map, take, tap } from 'rxjs';

import { BetslipDigitalComponentLoaderService } from '../betslip/betslip-digital-component-loader.service';
import { ComponentProxyDirective } from '../deferred/component-proxy.directive';
import { WidgetComponent } from '../widget/core/widget-registry.service';

@HooksWireup()
@Component({
    selector: 'ms-reward-tokens-container',
    templateUrl: './reward-tokens-container.component.html',
    imports: [NgIf, ComponentProxyDirective, AsyncPipe],
    standalone: true,
})
export class RewardTokensContainerComponent extends WidgetComponent<PromoBannerPayload> {
    private readonly rewardTokensDataService = inject(RewardTokensDataService);
    private readonly betslipLoader = inject(BetslipDigitalComponentLoaderService);
    private readonly tokensConfig = inject(CRM_CONFIG_INJECTION_TOKEN);
    private proxyComponentLoaded: boolean;

    @HostBinding('class') className = 'widget--proxied reward-tokens-banner';

    @HostBinding('style.--component-loader-height')
    get bannerComponentSize() {
        return !this.tokensConfig.promotions.isRegulatoryRestricted && this.tokensConfig.tokens.isExpandableRewardBannerEnabled ? '44px' : null;
    }

    @HostBinding('style.--component-loader-padding')
    get bannerComponentPadding() {
        return this.bannerComponentSize ? '0' : null;
    }

    bannerComponent$ = this.rewardTokensDataService.tokens$.pipe(
        map((data) => getNonAccaTokens(data)),
        tap((tokens) => {
            this.hasData = tokens?.length > 0;
            this.setWidgetLoaded();
            this.proxyComponentLoaded = true;
        }),
        filter((tokens) => tokens?.length > 0),
        map(() => this.betslipLoader.getRewardTokensComponent()),
        // complete when the proxy was created once
        take(1),
    );

    protected override onData(payload: PromoBannerPayload): void {
        if (!payload.isUserAuthenticated) {
            this.hasData = false;
            this.setWidgetLoaded();
        } else if (this.proxyComponentLoaded) {
            this.setWidgetLoaded();
        }
    }
}
