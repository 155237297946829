import { Provider } from '@angular/core';

import { registerLoader, registerLoaderFn } from '@frontend/loaders';

import { BannerWidgetLoader } from '../banner/banner-widget.loader';
import { BetColumnLoader } from '../bet-column/bet-column.loader';
import { BetGeneratorLoader } from '../bet-generator-shared/bet-generator.loader';
import { TimeFiltersWidgetLoader } from '../calendar/time-filters-widget.loader';
import { TopItemsLoader } from '../competition-list/top-items.loader';
import { CrmPromotionWidgetLoader } from '../crm-promotion-widget/crm-promotion-widget.loader';
import { DefaultStakeTeaserLoader } from '../default-stake-teaser/default-stake-teaser.loader';
import { StandingsWidgetLoader } from '../event-list-shared/sport/standings-widget.loader';
import { FavouritesWidgetLoader } from '../favourites-widget/favourites-widget.loader';
import { GeoLocationAssetLoader } from '../geo-location/geo-location.loader';
import { HiddenMarketLoader } from '../hidden-market/hidden-market.loader';
import { HighlightsMarqueeLoader } from '../highlights-marquee/highlights-marquee.loader';
import { MainComponentLoader } from '../main/main.loader';
import { MediaLoader } from '../media/media.loader';
import { MinigamesLoader } from '../minigames/minigames.loader';
import { NextToGoLoader } from '../next-to-go/next-to-go.loader';
import { OutrightsGridLoader } from '../outrights-grid/outrights-grid.loader';
import { PanicButtonLoader } from '../panic-button/panic-button.loader';
import { PopularMultiBetsLoader } from '../popular-bets/popular-multi-bets-widget/popular-multi-bets.loader';
import { PriceBoostLoader } from '../priceboost/price-boost.loader';
import { QuickLinkLoader } from '../quick-links/quicklink.loader';
import { ShowCaseLoader } from '../showcase/showcase.loader';
import { SportTreeLoader } from '../sport-tree/sport-tree.loader';
import { StoryContentLoader } from '../story-content/story-content.loader';
import { TabbedGridLoader } from '../tabbed-grid/tabbed-grid.loader';
import { TeaserLoader } from '../teaser/teaser.loader';
import { TopEventsLoader } from '../top-events/top-events.loader';
import { WidgetComposableLoader } from '../widget/composable/widget-composable.loader';

export const provideLazyFeatures = (): Provider[] => [
    registerLoader(QuickLinkLoader),
    registerLoader(HighlightsMarqueeLoader),
    registerLoader(NextToGoLoader),
    registerLoader(TabbedGridLoader),
    registerLoader(TopEventsLoader),
    registerLoader(OutrightsGridLoader),
    registerLoader(ShowCaseLoader),
    registerLoader(WidgetComposableLoader),
    registerLoader(PriceBoostLoader),
    registerLoaderFn(SportTreeLoader),
    registerLoaderFn(GeoLocationAssetLoader),
    registerLoaderFn(MediaLoader),
    registerLoaderFn(MinigamesLoader),
    registerLoaderFn(BannerWidgetLoader),
    registerLoaderFn(TeaserLoader),
    registerLoaderFn(PopularMultiBetsLoader),
    registerLoaderFn(MainComponentLoader),
    registerLoaderFn(PanicButtonLoader),
    registerLoaderFn(HiddenMarketLoader),
    registerLoaderFn(BetColumnLoader),
    registerLoaderFn(BetGeneratorLoader),
    registerLoaderFn(CrmPromotionWidgetLoader),
    registerLoaderFn(FavouritesWidgetLoader),
    registerLoaderFn(DefaultStakeTeaserLoader),
    registerLoaderFn(StoryContentLoader),
    registerLoaderFn(StandingsWidgetLoader),
    registerLoaderFn(TimeFiltersWidgetLoader),
    registerLoaderFn(TopItemsLoader),
];
