import { on } from '@ngrx/store';
import { createImmutableReducer } from 'packages/sports/common/betslip/base/store/helpers';

import NumpadActions from './actions';
import { numpadInitialState } from './numpad.state';

export const numpadReducer = createImmutableReducer(
    numpadInitialState,
    on(NumpadActions.numpadFocus, (state, action) => {
        state.focus = action.numpadFocus;

        return state;
    }),
);
