import {
    earlyPayoutServiceEditBetToken,
    earlyPayoutServiceMyBetsToken,
    earlyPayoutSubmitServiceEditBetToken,
    earlyPayoutSubmitServiceMyBetsToken,
    earlyPayoutSubscriptionServiceEditBetToken,
    earlyPayoutSubscriptionServiceMyBetsToken,
} from './early-payout/early-payout-injection-tokens';
import { EarlyPayoutServiceFactory } from './early-payout/early-payout-service.factory';
import {
    EarlyPayoutSubmitService,
    EarlyPayoutSubmitServiceEditBetFactory,
    EarlyPayoutSubmitServiceMyBetsFactory,
} from './early-payout/early-payout-submit.service';
import { EarlyPayoutSubscriptionService } from './early-payout/early-payout-subscription.service';
import {
    EarlyPayoutSubscriptionServiceEditBetFactory,
    EarlyPayoutSubscriptionServiceMyBetsFactory,
} from './early-payout/early-payout-subscription.service.factory';
import { EarlyPayoutService } from './early-payout/early-payout.service';
import { EarlyPayoutSubscriptionSource } from './models/early-payout-subscription-source';

export const provideMyBetsBase = () => [
    {
        provide: earlyPayoutServiceMyBetsToken,
        useFactory: earlyPayoutMyBetsFactory,
        deps: [EarlyPayoutServiceFactory],
    },
    {
        provide: earlyPayoutServiceEditBetToken,
        useFactory: earlyPayoutEditBetFactory,
        deps: [EarlyPayoutServiceFactory],
    },
    {
        provide: earlyPayoutSubscriptionServiceMyBetsToken,
        useFactory: earlyPayoutSubscriptionMyBetsFactory,
        deps: [EarlyPayoutSubscriptionServiceMyBetsFactory],
    },
    {
        provide: earlyPayoutSubscriptionServiceEditBetToken,
        useFactory: earlyPayoutSubscriptionEditBetFactory,
        deps: [EarlyPayoutSubscriptionServiceEditBetFactory],
    },
    {
        provide: earlyPayoutSubmitServiceMyBetsToken,
        useFactory: earlyPayoutSubmitMyBetsFactory,
        deps: [EarlyPayoutSubmitServiceMyBetsFactory],
    },
    {
        provide: earlyPayoutSubmitServiceEditBetToken,
        useFactory: earlyPayoutSubmitEditBetFactory,
        deps: [EarlyPayoutSubmitServiceEditBetFactory],
    },
];

function earlyPayoutMyBetsFactory(factory: EarlyPayoutServiceFactory): EarlyPayoutService {
    return factory.create(EarlyPayoutSubscriptionSource.MyBets);
}

function earlyPayoutEditBetFactory(factory: EarlyPayoutServiceFactory): EarlyPayoutService {
    return factory.create(EarlyPayoutSubscriptionSource.EditBet);
}

function earlyPayoutSubscriptionMyBetsFactory(factory: EarlyPayoutSubscriptionServiceMyBetsFactory): EarlyPayoutSubscriptionService {
    return factory.create();
}

function earlyPayoutSubscriptionEditBetFactory(factory: EarlyPayoutSubscriptionServiceEditBetFactory): EarlyPayoutSubscriptionService {
    return factory.create();
}

function earlyPayoutSubmitMyBetsFactory(factory: EarlyPayoutSubmitServiceMyBetsFactory): EarlyPayoutSubmitService {
    return factory.create();
}

function earlyPayoutSubmitEditBetFactory(factory: EarlyPayoutSubmitServiceEditBetFactory): EarlyPayoutSubmitService {
    return factory.create();
}
