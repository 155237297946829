import { createReducer, on } from '@ngrx/store';

import UserActions from './actions';
import { defaultUserState } from './state';

export const userReducer = createReducer(
    defaultUserState,
    on(UserActions.initState, (state, action) => {
        return {
            isAuthenticated: action.state.isAuthenticated,
            bettingSettings: action.state.bettingSettings,
            configSettings: action.state.configSettings,
            walletLoaded: action.state.walletLoaded,
            oddsFormat: action.state.oddsFormat,
        };
    }),
    on(UserActions.userLoggedIn, (state) => {
        return {
            ...state,
            isAuthenticated: true,
        };
    }),
    on(UserActions.userLoggedOut, (state) => {
        return {
            ...state,
            isAuthenticated: false,
        };
    }),
    on(UserActions.updateState, (state, action) => {
        return {
            ...state,
            bettingSettings: action.settings,
            configSettings: action.config,
            oddsFormat: action.oddsFormat,
        };
    }),
    on(UserActions.setWalletLoaded, (state, action) => {
        return { ...state, walletLoaded: action.walletLoaded };
    }),
);
