import { Injectable } from '@angular/core';

import { AnimationControl, AnimationControlService, OnAppInit } from '@frontend/vanilla/core';

@Injectable({
    providedIn: 'root',
})
export class AnimationDisabledService implements OnAppInit {
    constructor(private animationControlService: AnimationControlService) {}

    onAppInit(): void {
        this.animationControlService.addCondition((element) => {
            if (element.id === 'main-menu') {
                return AnimationControl.Disable;
            }

            return;
        });
    }
}
