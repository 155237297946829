import { Injectable } from '@angular/core';

import { Utils } from '@frontend/sports/common/base-utils';

import { TimerService } from '../../common/timer.service';
import { ApiService } from '../../http/api.service';
import { EPSDetailedLogger } from '../../logging/eps-detailed-logger.service';
import { LoggerFactory } from '../../logging/logger-factory.service';
import { RedirectHelperService } from '../../navigation-core/redirect-helper.service';
import { TrackingService } from '../../tracking/tracking.service';
import { EarlyPayoutSubscriptionSource } from '../models/early-payout-subscription-source';
import { EarlyPayoutSubscriptionMapperService } from './early-payout-subscription-mapper.service';
import { EarlyPayoutService } from './early-payout.service';
import { EarlyPayoutHelpers } from './my-bets-earlypayout-helpers';

@Injectable({ providedIn: 'root' })
export class EarlyPayoutServiceFactory {
    constructor(
        private apiService: ApiService,
        private loggerFactory: LoggerFactory,
        private tracker: TrackingService,
        private utils: Utils,
        private redirectService: RedirectHelperService,
        private timerService: TimerService,
        private epsLogger: EPSDetailedLogger,
        private epsMapper: EarlyPayoutSubscriptionMapperService,
        private earlyPayoutHelpers: EarlyPayoutHelpers,
    ) {}

    create(source: EarlyPayoutSubscriptionSource): EarlyPayoutService {
        return new EarlyPayoutService(
            this.apiService,
            this.loggerFactory,
            this.tracker,
            this.utils,
            this.redirectService,
            source,
            this.timerService,
            this.epsLogger,
            this.epsMapper,
            this.earlyPayoutHelpers,
        );
    }
}
