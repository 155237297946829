import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ActivationEnd } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RouterEventsService } from '../navigation-core/router-events.service';
import { AdaptiveLayoutFlag, AdaptiveLayoutProvider } from './adaptive-layout.service';

@Injectable({ providedIn: 'root' })
export class RouteLayoutProvider implements AdaptiveLayoutProvider {
    constructor(private router: RouterEventsService) {}

    asObservable(): Observable<AdaptiveLayoutFlag> {
        return this.router.currentActivationEnd.pipe(map((value) => this.getState(value)));
    }

    private getState(value: ActivationEnd | undefined): AdaptiveLayoutFlag {
        const state: AdaptiveLayoutFlag = {};

        if (!value || !value.snapshot.routeConfig) {
            return state;
        }

        state.headerNavigation = this.getValue(value, 'headerNavigation');
        state.bottomNavigation = this.getValue(value, 'bottomNavigation', true);
        state.subNavigationHiddenOnSmallScreen = this.getValue(value, 'subNavigationHiddenOnSmallScreen');
        state.topNavigationHiddenOnSmallScreen = this.getValue(value, 'topNavigationHiddenOnSmallScreen');

        state.htmlClass = this.getValue(value, 'htmlClass');
        state.fixedContent = this.getValue(value, 'fixedContent');

        return state;
    }

    private getValue(event: ActivationEnd | undefined, key: keyof AdaptiveLayoutFlag, fallback?: any): any {
        if (!event || !event.snapshot.routeConfig) {
            return fallback;
        }

        let parent: ActivatedRouteSnapshot | null = event.snapshot;

        while (parent) {
            if (parent.routeConfig && parent.routeConfig.data && key in parent.routeConfig.data) {
                return parent.routeConfig.data[key];
            }

            parent = parent.parent;
        }

        return fallback;
    }
}
