import { Injectable, Injector } from '@angular/core';

import { BaseLoader, LoadOptions, Loader } from '@frontend/loaders';
import { EpcotConfig } from '@frontend/sports/common/client-config-data-access';

@Injectable()
export class OutrightsGridLoader extends BaseLoader implements Loader {
    private readonly styleSheet = 'epcot-outrights-grid';
    constructor(
        injector: Injector,
        private epcotConfig: EpcotConfig,
    ) {
        super('outrights-grid', injector);
    }

    async load(options: LoadOptions): Promise<void> {
        if (this.epcotConfig.isEnabled) {
            options.styles = options.styles ?? [];
            options.styles = [...options.styles, { id: this.styleSheet, type: 'theme' }];
        }
        await this.loadFeature(import(/* webpackChunkName: "ms-outrights-widget" */ './outrights-grid.feature'), options);
    }
}
