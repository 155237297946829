import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AdaptiveLayoutFlag, AdaptiveLayoutProvider } from './adaptive-layout.service';
import { ColumnLayoutService } from './column-layout.service';

@Injectable({ providedIn: 'root' })
export class RightColumnLayoutProvider implements AdaptiveLayoutProvider {
    constructor(private columnLayoutService: ColumnLayoutService) {}

    asObservable(): Observable<AdaptiveLayoutFlag> {
        return this.columnLayoutService.rightColumnSizeChanged.pipe(map((column) => ({ rightColumnSize: column.size })));
    }
}
