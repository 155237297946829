import { Location } from '@angular/common';
import { Injectable } from '@angular/core';

import { AccaBoostConfig, LayoutNavigationConfig, MyBetsConfig } from '@frontend/sports/common/client-config-data-access';
import { OpenBetsSummary } from '@frontend/sports/types/models/my-bets';
import { BottomNavService, MenuAction, MenuActionsService, NavigationService } from '@frontend/vanilla/core';
import { BetslipIntegrationService } from 'packages/sports/common/betslip/integration/betslip-integration.service';
import { combineLatest, switchMap } from 'rxjs';

import { EpcotConfigService } from '../common/epcot-config.service';
import { DialogAnimation } from '../modal/dialog/modal-dialog.model';
import { MyBetsSummaryService } from '../my-bets-base/my-bets-summary.service';
import { RedirectHelperService } from '../navigation-core/redirect-helper.service';
import { ModelPopupTypes, UrlHelperService } from '../navigation-core/url-helper.service';
import { PopupManager } from '../popup/popup-manager.service';
import { trackingConstants } from '../tracking/tracking.models';
import { TrackingService } from '../tracking/tracking.service';
import { UserService } from '../user/services/user.service';

@Injectable({
    providedIn: 'root',
})
export class BottomNavActionsService {
    private static readonly betslipBadgeClass: string = 'badge-primary';
    private static readonly extraIconBadgeClass: string = 'badge-extra-bubble-icon';
    private static readonly myBetsBadgeClass: string = 'badge-primary theme-spot-filled';

    private openBetsCount: number;
    private hasLiveOpenBets = false;

    constructor(
        private vanillaNavigation: NavigationService,
        private popupManager: PopupManager,
        private trackingService: TrackingService,
        private redirectHelper: RedirectHelperService,
        private location: Location,
        private myBetsSummary: MyBetsSummaryService,
        private userService: UserService,
        private urlHelper: UrlHelperService,
        private betslipIntegrationService: BetslipIntegrationService,
        private bottomNavService: BottomNavService,
        private menuActionsService: MenuActionsService,
        private accaBoostConfig: AccaBoostConfig,
        private layoutNavigationConfig: LayoutNavigationConfig,
        private epcotConfigService: EpcotConfigService,
        private mybetsConfig: MyBetsConfig,
    ) {}

    betslipToggled(): void {
        if (this.popupManager.isOpen()) {
            this.trackingService.track(trackingConstants.EVENT_PAGE_VIEW, { [trackingConstants.PAGE_NAME]: 'M2_slip_action_ClosePopup' });
        }
        this.popupManager.open('betslip');
    }

    myBetsToggled(): void {
        const myBetsUrl = this.urlHelper.getMyBetsUrl();
        if (this.location.path() === myBetsUrl) {
            this.redirectHelper.goBack();
            this.myBetsTracking(false);
        } else {
            if (this.userService.isAuthenticated) {
                if (this.epcotConfigService.isShowMyBetsPopup) {
                    this.popupManager.updateDialogAnimation(ModelPopupTypes.MyBets, DialogAnimation.SlideInFromBottom);
                    this.popupManager.open(ModelPopupTypes.MyBets);
                } else {
                    this.vanillaNavigation.goTo(myBetsUrl);
                }
            } else {
                const returnUrl = this.epcotConfigService.isShowMyBetsPopup ? this.urlHelper.getMyBetPopupUrl() : myBetsUrl;
                this.menuActionsService.invoke(MenuAction.GOTO_LOGIN, '', [undefined, undefined, { returnUrl }]);
            }
            this.myBetsTracking(true);
        }
    }

    private myBetsTracking(isOpen: boolean): void {
        if (!isOpen) {
            return;
        }

        let pageName: string;
        if (this.hasLiveOpenBets) {
            pageName = 'HIconON_Live';
        } else if (this.openBetsCount > 0) {
            pageName = 'HIcon_Open';
        } else {
            pageName = 'HIcon_Settled';
        }
        this.trackingService.update({ [trackingConstants.PAGE_REFERRING_ACTION]: pageName });
    }

    init(): void {
        if (!this.mybetsConfig.isOpenSummaryLoadingRestricted) {
            this.myBetsSummary.summaryUpdate.subscribe((stats: OpenBetsSummary) => {
                this.hasLiveOpenBets = stats.liveBetsCount > 0;
                this.openBetsCount = stats.openBetsCount;

                let betsCount: number | string = 0;
                let badgeClass = BottomNavActionsService.myBetsBadgeClass;
                if (this.mybetsConfig.isShowOpenBetsCount) {
                    if (stats.openBetsCount > 99) {
                        betsCount = '99+';
                        badgeClass = badgeClass.replace('theme-spot-filled', 'open-bets bets-99-plus');
                    } else {
                        betsCount = stats.openBetsCount;
                        badgeClass = badgeClass.replace('theme-spot-filled', 'open-bets');
                    }
                } else betsCount = stats.liveBetsCount;
                const myBetsItem = this.layoutNavigationConfig?.bottomNavItemsMapping['mybets'] || 'mybets';

                this.bottomNavService.setItemCounter(myBetsItem, betsCount || null, badgeClass);
            });
        }

        this.betslipIntegrationService
            .betslipInitialized$()
            .pipe(
                switchMap(() =>
                    combineLatest([this.betslipIntegrationService.betslipPicksCount$(), this.betslipIntegrationService.isAccaBoostToken$()]),
                ),
            )
            .subscribe(([count, isAccaBoost]) => this.setBetslipCounter(count, isAccaBoost));
    }

    private setBetslipCounter(count: number, isAccaBoost: boolean): void {
        this.bottomNavService.setItemCounter(
            'betslip',
            count || null,
            isAccaBoost ? this.accaBoostBadgeClass : BottomNavActionsService.betslipBadgeClass,
        );
    }

    menuToggled(): void {
        this.vanillaNavigation.goTo('/menu');
    }

    private get accaBoostBadgeClass(): string {
        return `${BottomNavActionsService.betslipBadgeClass} ${BottomNavActionsService.extraIconBadgeClass} ${this.accaBoostConfig.icon}`;
    }
}
