import { BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';

import { DeviceService, MediaQueryService } from '@frontend/vanilla/core';
import { Observable, merge } from 'rxjs';
import { map } from 'rxjs/operators';

import { AdaptiveLayoutFlag, AdaptiveLayoutProvider } from './adaptive-layout.service';

@Injectable({ providedIn: 'root' })
export class DeviceLayoutProvider implements AdaptiveLayoutProvider {
    private columns = new Map<string, number>();

    constructor(
        private mediaService: MediaQueryService,
        private deviceService: DeviceService,
    ) {
        this.columns.set('gt-md', 3);
        this.columns.set('md', 2);
        this.columns.set('mw', 3); // TODO remove this line after vanilla 9.3 release and verify if in resolution 1280px the columns are equal to 3
        this.columns.set('sm', 2);
        this.columns.set('xs', 1);
    }

    asObservable(): Observable<AdaptiveLayoutFlag> {
        return merge(this.mediaService.observe(), this.deviceService.orientation).pipe(map((value) => this.getState(value)));
    }

    private getState(value: string | BreakpointState): AdaptiveLayoutFlag {
        const state: AdaptiveLayoutFlag = {};

        state.headerSubNavigation = !this.mediaService.isActive('gt-md');
        state.shouldHideSubNavigation = this.deviceService.isMobilePhone;
        state.stickyHeader = !this.deviceService.isMobilePhone || !this.mediaService.isActive('xs-height');
        state.cardContent = !this.deviceService.isMobilePhone && !this.mediaService.isActive('xs');

        // we show multiple columns only when height minimum is meet and widht minimum is meet
        if (state.cardContent) {
            this.columns.forEach((element, key) => {
                if (this.mediaService.isActive(key)) {
                    state.columns = this.columns.get(key)!;
                }
            });
        } else {
            state.columns = 1;
        }

        return state;
    }
}
