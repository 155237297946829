export const VANILLA_LOADERS_MAP: { [key: string]: () => Promise<any> } = {
    'abuser-information': () => import('@frontend/vanilla/features/abuser-information'),
    'account-menu': () => import('@frontend/vanilla/features/account-menu'),
    'activity-popup': () => import('@frontend/vanilla/features/activity-popup'),
    'affordability': () => import('@frontend/vanilla/features/affordability'),
    'balance-breakdown': () => import('@frontend/vanilla/features/balance-breakdown'),
    'balance-properties': () => import('@frontend/vanilla/features/balance-properties'),
    'balance-refresh': () => import('@frontend/vanilla/features/balance-refresh'),
    'balance-transfer': () => import('@frontend/vanilla/features/balance-transfer'),
    'barcode-scanner': () => import('@frontend/vanilla/features/barcode-scanner'),
    'betstation-hardware-fault': () => import('@frontend/vanilla/features/betstation-hardware-fault'),
    'betting-status': () => import('@frontend/vanilla/features/betting-status'),
    'bonus-award': () => import('@frontend/vanilla/features/bonus-award'),
    'bonus-balance': () => import('@frontend/vanilla/features/bonus-balance'),
    'bottom-nav': () => import('@frontend/vanilla/features/bottom-nav'),
    'bottom-sheet': () => import('@frontend/vanilla/features/bottom-sheet'),
    'cashier': () => import('@frontend/vanilla/features/cashier'),
    'clock': () => import('@frontend/vanilla/features/clock'),
    'connected-accounts': () => import('@frontend/vanilla/features/connected-accounts'),
    'content': () => import('@frontend/vanilla/features/content'),
    'content-messages': () => import('@frontend/vanilla/features/content-messages'),
    'cookie-consent': () => import('@frontend/vanilla/features/cookie-consent'),
    'css-overrides': () => import('@frontend/vanilla/features/css-overrides'),
    'curfew-status': () => import('@frontend/vanilla/features/curfew-status'),
    'debounce-buttons': () => import('@frontend/vanilla/features/debounce-buttons'),
    'deposit-limit-exceeded': () => import('@frontend/vanilla/features/deposit-limit-exceeded'),
    'deposit-limits': () => import('@frontend/vanilla/features/deposit-limits'),
    'deposit-prompt': () => import('@frontend/vanilla/features/deposit-prompt'),
    'dialog': () => import('@frontend/vanilla/features/dialog'),
    'domain-specific-actions': () => import('@frontend/vanilla/features/domain-specific-actions'),
    'dropdown-header': () => import('@frontend/vanilla/features/dropdown-header'),
    'flags': () => import('@frontend/vanilla/features/flags'),
    'footer': () => import('@frontend/vanilla/features/footer'),
    'gamification': () => import('@frontend/vanilla/features/gamification'),
    'geolocation': () => import('@frontend/vanilla/features/geolocation'),
    'header': () => import('@frontend/vanilla/features/header'),
    'header-bar': () => import('@frontend/vanilla/features/header-bar'),
    'hint': () => import('@frontend/vanilla/features/hint'),
    'html': () => import('@frontend/vanilla/features/html'),
    'idle': () => import('@frontend/vanilla/features/idle'),
    'icons': () => import('@frontend/vanilla/features/icons'),
    'inactive': () => import('@frontend/vanilla/features/inactive'),
    'inactivity-screen': () => import('@frontend/vanilla/features/inactivity-screen'),
    'inbox': () => import('@frontend/vanilla/features/inbox'),
    'kyc': () => import('@frontend/vanilla/features/kyc'),
    'launch-darkly': () => import('@frontend/vanilla/features/launch-darkly'),
    'label-switcher': () => import('@frontend/vanilla/features/label-switcher'),
    'language-switcher': () => import('@frontend/vanilla/features/language-switcher'),
    'last-session-info': () => import('@frontend/vanilla/features/last-session-info'),
    'license-info': () => import('@frontend/vanilla/features/license-info'),
    'live-person': () => import('@frontend/vanilla/features/live-person'),
    'login': () => import('@frontend/vanilla/features/login'),
    'login-duration': () => import('@frontend/vanilla/features/login-duration'),
    'login-new': () => import('@frontend/vanilla/features/login-new'),
    'logout': () => import('@frontend/vanilla/features/logout'),
    'loss-limits': () => import('@frontend/vanilla/features/loss-limits'),
    'loyality-profile': () => import('@frontend/vanilla/features/loyality-profile'),
    'meta-tags': () => import('@frontend/vanilla/features/meta-tags'),
    'moh-details': () => import('@frontend/vanilla/features/moh-details'),
    'native-app': () => import('@frontend/vanilla/features/native-app'),
    'navigation-layout': () => import('@frontend/vanilla/features/navigation-layout'),
    'offer-button': () => import('@frontend/vanilla/features/offer-button'),
    'offer-dsl': () => import('@frontend/vanilla/features/offer-dsl'),
    'offers': () => import('@frontend/vanilla/features/offers'),
    'offline': () => import('@frontend/vanilla/features/offline'),
    'offline-page': () => import('@frontend/vanilla/features/offline-page'),
    'overlay': () => import('@frontend/vanilla/features/overlay'),
    'overlay-messages': () => import('@frontend/vanilla/features/overlay-messages'),
    'play-break': () => import('@frontend/vanilla/features/play-break'),
    'player-active-wager': () => import('@frontend/vanilla/features/player-active-wager'),
    'jackpot-winner': () => import('@frontend/vanilla/features/jackpot-winner'),
    'player-gaming-declaration': () => import('@frontend/vanilla/features/player-gaming-declaration'),
    'player-limits': () => import('@frontend/vanilla/features/player-limits'),
    'prerender': () => import('@frontend/vanilla/features/prerender'),
    'product-menu': () => import('@frontend/vanilla/features/product-menu'),
    'product-switch-cool-off': () => import('@frontend/vanilla/features/product-switch-cool-off'),
    're-captcha': () => import('@frontend/vanilla/features/recaptcha'),
    'redirect-message': () => import('@frontend/vanilla/features/redirect-message'),
    'remember-me-logout-prompt': () => import('@frontend/vanilla/features/remember-me-logout-prompt'),
    'rtms-events': () => import('@frontend/vanilla/features/rtms-events'),
    'rtms-layer': () => import('@frontend/vanilla/features/rtms-layer'),
    'rtms-overlay': () => import('@frontend/vanilla/features/rtms-overlay'),
    'screen-time': () => import('@frontend/vanilla/features/screen-time'),
    'self-exclusion': () => import('@frontend/vanilla/features/self-exclusion'),
    'session-fund-summary': () => import('@frontend/vanilla/features/session-fund-summary'),
    'session-info': () => import('@frontend/vanilla/features/session-info'),
    'session-lifetime-check': () => import('@frontend/vanilla/features/session-lifetime-check'),
    'session-limits': () => import('@frontend/vanilla/features/session-limits'),
    'session-limits-logout-popup': () => import('@frontend/vanilla/features/session-limits-logout-popup'),
    'single-sign-on': () => import('@frontend/vanilla/features/single-sign-on'),
    'smart-banner': () => import('@frontend/vanilla/features/smart-banner'),
    'sof-status-details': () => import('@frontend/vanilla/features/sof-status-details'),
    'third-party-tracker': () => import('@frontend/vanilla/features/third-party-tracker'),
    'top-messages': () => import('@frontend/vanilla/features/top-messages'),
    'tourney-token-balance': () => import('@frontend/vanilla/features/tourney-token-balance'),
    'tracking': () => import('@frontend/vanilla/features/tracking'),
    'untested-browser': () => import('@frontend/vanilla/features/untested-browser'),
    'user-documents': () => import('@frontend/vanilla/features/user-documents'),
    'user-flags': () => import('@frontend/vanilla/features/user-flags'),
    'user-scrub': () => import('@frontend/vanilla/features/user-scrub'),
    'user-summary': () => import('@frontend/vanilla/features/user-summary'),
    'value-ticket': () => import('@frontend/vanilla/features/value-ticket'),
    'zendesk': () => import('@frontend/vanilla/features/zendesk'),
};
