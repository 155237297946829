import { Provider, importProvidersFrom } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';

import { StateSaver, _STATE_STORAGE_PERSIST_SERVICES } from './state-storage.model';
import StorePersistEffects from './store-persist.effects';

export const provideRootStateStorage = (): Provider => [
    importProvidersFrom(EffectsModule.forFeature([StorePersistEffects])),
    {
        provide: _STATE_STORAGE_PERSIST_SERVICES,
        useValue: new Map<string, StateSaver<any, any>>(),
    },
];
